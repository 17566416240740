// Footer.js

import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Modal,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Facebook,
  LinkedIn,
  Twitter,
  YouTube,
  Instagram,
} from "@mui/icons-material";
import AppleIcon from "@mui/icons-material/Apple";
import AndroidIcon from "@mui/icons-material/Android";
import { useTranslation } from "react-i18next";
import LanguageIcon from "@mui/icons-material/Language";
import CloseIcon from "@mui/icons-material/Close";

const Footer = () => {
  const isMobile = window.innerWidth <= 600;
  const { t, i18n } = useTranslation();
  const [openLangModal, setOpenLangModal] = useState(false);

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
  };

  const languageButton = (
    <IconButton
      onClick={() => setOpenLangModal(true)}
      color="inherit"
      sx={{ mb: 2 }}
      aria-label="change language"
    >
      <LanguageIcon />
      <Typography variant="caption" sx={{ ml: 1 }}>
        {i18n.language.toUpperCase()}
      </Typography>
    </IconButton>
  );

  const languageDialog = (
    <Dialog
      open={openLangModal}
      onClose={() => setOpenLangModal(false)}
      aria-labelledby="language-dialog-title"
      PaperProps={{
        sx: {
          width: "600px",
          height: "200px",
          padding: "30px",
        },
      }}
    >
      <DialogTitle
        id="language-dialog-title"
        sx={{
          m: 0,
          p: 2,
          paddingRight: 6,
          marginBottom: 4, // Make room for close button
        }}
      >
        {t("selectLanguage")}
        <IconButton
          onClick={() => setOpenLangModal(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "grey.500",
          }}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ p: 2 }}>
        <Box sx={{ display: "flex", flexDirection: "row", gap: 6 }}>
          <Button
            onClick={() => handleLanguageChange("en")}
            fullWidth
            sx={{
              color: "black",
              backgroundColor: "white",
              border: i18n.language === "en" ? "1px solid black" : "none",
              "&:hover": {
                backgroundColor: "#f5f5f5",
                borderColor: "black",
              },
              height: "80px",
              borderRadius: "24px",
              fontSize: "16px",
            }}
          >
            English
          </Button>
          <Button
            onClick={() => handleLanguageChange("ko")}
            fullWidth
            sx={{
              color: "black",
              borderRadius: "24px",
              height: "80px",
              backgroundColor: "white",
              border: i18n.language === "ko" ? "1px solid black" : "none",
              "&:hover": {
                backgroundColor: "#f5f5f5",
                borderColor: "black",
              },
              fontSize: "16px",
            }}
          >
            한국어
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );

  return (
    <Box
      component="footer"
      sx={{
        py: 4,
        px: 2,
        mt: "2rem",
        backgroundColor: "#1f1f1f",
        color: "white",
        paddingLeft: "4rem",
        paddingRight: "4rem",
        paddingTop: "4rem",
      }}
    >
      {languageDialog}
      <Grid container spacing={3} justifyContent="center">
        {isMobile ? (
          // Mobile View with Accordions in Vertical Stack
          <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <Accordion
              sx={{
                backgroundColor: "#1f1f1f",
                color: "white",
                boxShadow: "none",
                "&:before": { display: "none" }, // Remove the default divider line
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
              >
                <Typography variant="body1" fontWeight="bold">
                  {t("general")}
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  fontSize: "12px",
                  display: "flex",
                  gap: "2px",
                  flexDirection: "column",
                }}
              >
                <Link
                  to="/feedback"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  {t("feedback")}
                </Link>
              </AccordionDetails>
            </Accordion>

            <Accordion
              sx={{
                backgroundColor: "#1f1f1f",
                color: "white",
                boxShadow: "none",
                "&:before": { display: "none" },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
              >
                <Typography variant="body1" fontWeight="bold">
                  {t("support")}
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  fontSize: "12px",
                  display: "flex",
                  gap: "2px",
                  flexDirection: "column",
                }}
              >
                <Link
                  to="/safety"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  {t("trustSafetySecurity")}
                </Link>
                <br />
                <Link
                  to="/help"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  {t("helpSupport")}
                </Link>
              </AccordionDetails>
            </Accordion>

            <Accordion
              sx={{
                backgroundColor: "#1f1f1f",
                color: "white",
                boxShadow: "none",
                "&:before": { display: "none" },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
              >
                <Typography variant="body1" fontWeight="bold">
                  {t("terms")}
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  fontSize: "12px",
                  display: "flex",
                  gap: "2px",
                  flexDirection: "column",
                }}
              >
                <Link
                  to="/terms"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  {t("termsOfService")}
                </Link>
                <br />
                <Link
                  to="/privacy"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  {t("privacyPolicy")}
                </Link>
                <br />
                <Link
                  to="/cookies"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  {t("cookieSettings")}
                </Link>
              </AccordionDetails>
            </Accordion>

            {/* <Accordion
              sx={{
                backgroundColor: "#1f1f1f",
                color: "white",
                boxShadow: "none",
                "&:before": { display: "none" },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
              >
                <Typography variant="body1" fontWeight="bold">
                  More
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  fontSize: "12px",
                  display: "flex",
                  gap: "2px",
                  flexDirection: "column",
                }}
              >
                <Link
                  to="/accessibility"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  Accessibility
                </Link>
                <br />
                <Link
                  to="/desktop"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  Desktop App
                </Link>
                <br />
                <Link
                  to="/enterprise"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  Enterprise Solutions
                </Link>
              </AccordionDetails>
            </Accordion> */}

            <Box textAlign="center" mt={3}>
              {languageButton}
              <Typography variant="body1" fontWeight="bold" mb={1}>
                {t("followUs")}
              </Typography>
              <Box display="flex" justifyContent="center">
                {/* <IconButton href="https://facebook.com" color="inherit">
                  <Facebook />
                </IconButton>
                <IconButton href="https://linkedin.com" color="inherit">
                  <LinkedIn />
                </IconButton>
                <IconButton href="https://twitter.com" color="inherit">
                  <Twitter />
                </IconButton>
                <IconButton href="https://youtube.com" color="inherit">
                  <YouTube />
                </IconButton> */}
                <IconButton
                  href="https://www.instagram.com/togatherhere/#"
                  color="inherit"
                >
                  <Instagram />
                </IconButton>
              </Box>
            </Box>
          </Box>
        ) : (
          // Desktop View with Grid
          <>
            <Grid
              item
              xs={12}
              sm={2}
              sx={{
                fontSize: "14px",
                display: "flex",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              <Typography variant="body1" fontWeight="bold">
                {t("general")}
              </Typography>
              {/* <Link
                to="/about"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                About Us
              </Link> */}
              <Link
                to="/feedback"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                {t("feedback")}
              </Link>
            </Grid>

            <Grid
              item
              xs={12}
              sm={2}
              sx={{
                fontSize: "14px",
                display: "flex",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              <Typography variant="body1" fontWeight="bold">
                {t("support")}
              </Typography>
              <Link
                to="/safety"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                {t("trustSafetySecurity")}
              </Link>
              <Link
                to="/help"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                {t("helpSupport")}
              </Link>
            </Grid>

            <Grid
              item
              xs={12}
              sm={2}
              sx={{
                fontSize: "14px",
                display: "flex",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              <Typography variant="body1" fontWeight="bold">
                {t("terms")}
              </Typography>
              <Link
                to="/terms"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                {t("termsOfService")}
              </Link>
              <Link
                to="/privacy"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                {t("privacyPolicy")}
              </Link>
              <Link
                to="/cookies"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                {t("cookieSettings")}
              </Link>
            </Grid>

            {/* <Grid
              item
              xs={12}
              sm={2}
              sx={{
                fontSize: "14px",
                display: "flex",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              <Typography variant="body1" fontWeight="bold">
                More
              </Typography>
              <Link
                to="/accessibility"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                Accessibility
              </Link>
            </Grid> */}

            <Grid item xs={12} sm={2} container justifyContent="center">
              <Typography variant="body1" fontWeight="bold" mb={1}>
                {t("followUs")}
              </Typography>
              <Box display="flex" justifyContent="center">
                {/* <IconButton href="https://facebook.com" color="inherit">
                  <Facebook />
                </IconButton>
                <IconButton href="https://linkedin.com" color="inherit">
                  <LinkedIn />
                </IconButton>
                <IconButton href="https://twitter.com" color="inherit">
                  <Twitter />
                </IconButton>
                <IconButton href="https://youtube.com" color="inherit">
                  <YouTube />
                </IconButton> */}
                <IconButton
                  href="https://www.instagram.com/togatherhere/#"
                  color="inherit"
                >
                  <Instagram />
                </IconButton>
              </Box>
            </Grid>
            <Grid item xs={12} sm={2} container justifyContent="center">
              {languageButton}
            </Grid>
          </>
        )}
      </Grid>

      <Box mt={10} textAlign="center">
        <Typography variant="body2" color="inherit">
          © {new Date().getFullYear()} Togather - {t("allRightsReserved")}
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
