import React from "react";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import Divider from "@mui/material/Divider";

const Cookies = () => {
  const { i18n } = useTranslation();
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box sx={{ flexGrow: 1, bgcolor: "#ffffff" }}>
      {i18n.language === "ko" ? (
        <div className="misc-text-container">
          <div
            style={{
              fontSize: "28px",
              fontWeight: "500",
            }}
          >
            쿠키
          </div>
          <div style={{ fontSize: "10px", color: "#808080" }}>
            2024년 12월 23일 시행
          </div>
          <Divider />
          <div style={{ fontSize: "12px", lineHeight: 2 }}>
            <section>
              <h2>쿠키란 무엇인가요?</h2>
              <p>
                쿠키(Cookies)는 사용자가 방문하는 웹사이트에서 사용자의 기기에
                저장되는 작은 텍스트 파일입니다. 이를 통해 웹사이트는 사용자의
                선호 언어, 로그인 정보 등 방문 관련 정보를 기억할 수 있습니다.
                Togather (“<strong>당사</strong>,” “<strong>우리</strong>,” 또는
                “<strong>우리의</strong>”)는 사용자 경험을 향상하고, 플랫폼을
                더욱 편리하게 탐색하며, 맞춤형 콘텐츠를 제공하기 위해 쿠키를
                사용합니다.
              </p>
            </section>

            <section>
              <h2>쿠키 사용 방식</h2>
              <p>
                당사는 사용자가 사이트와 어떻게 상호 작용하는지 파악하고, 특정
                플랫폼 기능을 활성화하며, 전반적인 성능을 개선하기 위해 쿠키를
                활용합니다. 일부 쿠키는 플랫폼의 기본 작동에 필수적이며, 다른
                쿠키는 사용 패턴을 분석하여 서비스 품질을 높이는 데 도움이
                됩니다.
              </p>
              <p>
                또한 제3자 서비스 제공업체(예: 분석 도구 또는 광고 파트너)도
                사용자의 기기에 쿠키를 설정할 수 있음을 유의하시기 바랍니다.
                당사는 이러한 제3자 쿠키를 직접 제어하지 않으므로, 해당 업체들의
                개인정보 처리방침 및 쿠키 정책을 확인하시는 것을 권장합니다.
              </p>
            </section>

            <section>
              <h2>사용하는 쿠키 유형</h2>
              <ul>
                <li>
                  <strong>필수 쿠키(Essential Cookies):</strong> 플랫폼의 핵심
                  기능, 예를 들어 페이지 이동이나 보안 영역 접근 등을 위해
                  반드시 필요한 쿠키입니다. 이러한 쿠키가 없으면 플랫폼 일부
                  기능이 정상적으로 작동하지 않을 수 있습니다.
                </li>
                <li>
                  <strong>
                    성능 &amp; 분석 쿠키(Performance &amp; Analytics Cookies):
                  </strong>{" "}
                  사용자가 Togather를 어떻게 이용하는지 측정 및 분석함으로써,
                  플랫폼 성능을 최적화하고 사용자 경험을 개선하기 위한
                  쿠키입니다.
                </li>
                <li>
                  <strong>기능성 쿠키(Functionality Cookies):</strong> 언어나
                  지역 설정 등 사용자가 선호하는 사항을 기억하여, 보다 향상되고
                  개인화된 기능을 제공하기 위한 쿠키입니다.
                </li>
                <li>
                  <strong>광고 쿠키(Advertising Cookies):</strong> 사용자의
                  관심사에 기반하여 적절한 광고를 보여주고, 광고 캠페인의 효과를
                  측정하기 위해 사용될 수 있는 쿠키입니다. 이 쿠키는 사용자의
                  브라우징 습관을 추적하여 맞춤형 광고를 노출합니다. (EU/영국 등
                  일부 지역의 경우, 특정 광고 쿠키 사용에 대해 사전 동의가
                  필요할 수 있습니다.)
                </li>
              </ul>
            </section>

            <section>
              <h2>쿠키 관리 방법</h2>
              <p>
                브라우저 설정을 통해 쿠키를 차단하거나 삭제하는 등 직접 제어할
                수 있습니다. 다만, 필수 쿠키를 차단하면 Togather의 일부 기능이나
                서비스 이용이 제한될 수 있습니다.
              </p>
              <p>
                쿠키를 제어하는 방법이나 브라우저 설정을 변경하는 방법에 대한
                자세한 내용은 사용하시는 브라우저의 도움말 문서를 참고하시기
                바랍니다. 또한, 쿠키의 관리 및 삭제 방법에 대한 자세한 가이드를
                얻으시려면{" "}
                <a
                  href="https://www.allaboutcookies.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  All About Cookies
                </a>{" "}
                웹사이트를 방문해 보시기 바랍니다.
              </p>
              <p>
                쿠키 사용에 대해 사전 동의가 필요한 지역(예: EU/영국)에서는,
                쿠키 배너나 설정 도구가 표시되어 쿠키 수락 여부를 선택하실 수
                있습니다.
              </p>
            </section>

            <section>
              <h2>본 쿠키 정책의 변경</h2>
              <p>
                당사는 기술적 변화나 법적 요구 사항을 반영하기 위해, 본 쿠키
                정책을 주기적으로 업데이트할 수 있습니다. 변경 사항이 있을 경우
                본 페이지에 게시되며, 쿠키 사용 방식에 대한 최신 정보를
                숙지하시려면 정기적으로 확인해 주시기 바랍니다.
              </p>
              <p>
                본 쿠키 정책은 당사의{" "}
                <a href="/privacy" target="_blank" rel="noopener noreferrer">
                  개인정보 처리방침
                </a>
                의 일부분입니다. Togather를 계속 사용하심으로써, 귀하는 본 쿠키
                정책과 개인정보 처리방침에 명시된 조건에 동의하시는 것으로
                간주됩니다.
              </p>
            </section>

            <section>
              <h2>문의하기</h2>
              <p>
                본 쿠키 정책 또는 당사의 쿠키 사용에 관해 궁금하신 사항이
                있으시면 아래로 문의해 주시기 바랍니다:
              </p>
              <p>
                <strong>이메일:</strong>{" "}
                <a href="mailto:info@createtogather.com">
                  info@createtogather.com
                </a>
              </p>
            </section>
          </div>
        </div>
      ) : (
        <div className="misc-text-container">
          <div
            style={{
              fontSize: "28px",
              fontWeight: "500",
            }}
          >
            Cookies
          </div>
          <div style={{ fontSize: "10px", color: "#808080" }}>
            Effective December 23, 2024
          </div>
          <Divider />
          <div style={{ fontSize: "12px", lineHeight: 2 }}>
            <section>
              <h2>What Are Cookies?</h2>
              <p>
                Cookies are small text files stored on your device by websites
                you visit. They help websites remember information such as your
                preferred language, login details, and other settings. Togather
                (“<strong>we</strong>,” “<strong>us</strong>,” or “
                <strong>our</strong>”) uses cookies to enhance and personalize
                your experience, making it easier to navigate our platform and
                access tailored content.
              </p>
            </section>

            <section>
              <h2>How We Use Cookies</h2>
              <p>
                We use cookies to help us understand how you interact with our
                site, enable certain platform functions, and improve overall
                performance. Some cookies are essential to the basic operation
                of the platform, while others allow us to analyze usage patterns
                and enhance the quality of our services.
              </p>
              <p>
                Please note that third-party service providers (e.g., analytics
                or advertising partners) may also place cookies on your device.
                We do not control these third-party cookies and recommend
                reviewing their privacy and cookie policies for more
                information.
              </p>
            </section>

            <section>
              <h2>Types of Cookies We Use</h2>
              <ul>
                <li>
                  <strong>Essential Cookies:</strong> Necessary for our
                  platform’s core functionalities, such as page navigation and
                  secure access. Without these cookies, certain features may not
                  function.
                </li>
                <li>
                  <strong>Performance &amp; Analytics Cookies:</strong> Allow us
                  to measure and analyze how you use Togather, so we can
                  optimize performance and improve user experience.
                </li>
                <li>
                  <strong>Functionality Cookies:</strong> Remember your choices
                  (e.g., language or region) to offer enhanced, more
                  personalized features.
                </li>
                <li>
                  <strong>Advertising Cookies:</strong> Used to show you
                  relevant ads and measure their effectiveness. These cookies
                  may track your browsing habits to present ads based on your
                  interests. Depending on your region (e.g., EU/UK), you may be
                  asked to provide consent for the use of certain advertising
                  cookies.
                </li>
              </ul>
            </section>

            <section>
              <h2>Managing Cookies</h2>
              <p>
                You can control or manage cookies through your browser settings,
                including blocking or deleting them. However, blocking essential
                cookies may affect the functionality of Togather, limiting
                certain features or services.
              </p>
              <p>
                To learn more about controlling cookies or changing your browser
                settings, refer to your browser’s support documentation. You may
                also visit{" "}
                <a
                  href="https://www.allaboutcookies.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  All About Cookies
                </a>{" "}
                for detailed guidance on how to manage or delete cookies.
              </p>
              <p>
                If you are located in a jurisdiction where consent is required
                for the use of certain cookies, a cookie banner or preference
                tool may appear on our site to let you choose which cookies to
                accept or reject.
              </p>
            </section>

            <section>
              <h2>Changes to This Cookies Policy</h2>
              <p>
                We may update this Cookies Policy periodically to reflect
                changes in technology or legal requirements. Any updates will be
                posted on this page, and we encourage you to review it from time
                to time to stay informed about how we use cookies.
              </p>
              <p>
                This Cookies Policy is part of our broader{" "}
                <a href="/privacy" target="_blank" rel="noopener noreferrer">
                  Privacy Policy
                </a>
                . By continuing to use Togather, you acknowledge and agree to
                the terms outlined here and in our Privacy Policy.
              </p>
            </section>

            <section>
              <h2>Contact Us</h2>
              <p>
                If you have any questions about this Cookies Policy or our use
                of cookies, please contact us at:
              </p>
              <p>
                <strong>Email:</strong>{" "}
                <a href="mailto:info@createtogather.com">
                  info@createtogather.com
                </a>
              </p>
            </section>
          </div>
        </div>
      )}
    </Box>
  );
};

export default Cookies;
