// src/i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { getLocation } from "./ipService";

export const initI18n = async () => {
  // First check if there's a language preference in localStorage
  const storedLang = localStorage.getItem("i18nextLng");

  // If no stored preference, try to detect from IP
  if (!storedLang) {
    try {
      const location = await getLocation();
      const countryCode = location?.country?.toLowerCase();
      // Set Korean for users in Korea
      if (countryCode === "kr") {
        localStorage.setItem("i18nextLng", "ko");
      } else {
        localStorage.setItem("i18nextLng", "en");
      }
    } catch (error) {
      console.error("Error detecting location:", error);
      // Default to English if location detection fails
      localStorage.setItem("i18nextLng", "en");
    }
  }

  await i18n
    .use(HttpApi)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      supportedLngs: ["en", "ko"],
      fallbackLng: "en",
      debug: false,
      backend: {
        loadPath: "/locales/{{lng}}/translation.json",
      },
      detection: {
        order: [
          "localStorage", // Check localStorage first
          "querystring",
          "cookie",
          "navigator",
          "htmlTag",
          "path",
          "subdomain",
        ],
        caches: ["localStorage", "cookie"],
      },
      react: {
        useSuspense: false,
      },
    });
};

export default i18n;
