import React from "react";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import Divider from "@mui/material/Divider";

const HelpAndSupport = () => {
  const { i18n } = useTranslation();
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box sx={{ flexGrow: 1, bgcolor: "#ffffff" }}>
      {i18n.language === "ko" ? (
        <div className="misc-text-container">
          <div
            style={{
              fontSize: "28px",
              fontWeight: "500",
            }}
          >
            도움말 &amp; 지원
          </div>
          <div style={{ fontSize: "10px", color: "#808080" }}>
            2024년 12월 23일 시행
          </div>
          <Divider sx={{ marginBottom: "1rem" }} />
          <div style={{ fontSize: "12px", lineHeight: "2" }}>
            <p>
              Togather의 도움말 &amp; 지원 센터에 오신 것을 환영합니다. 저희는
              플랫폼을 더욱 원활하고 보람 있게 이용하실 수 있도록 지원하는 것을
              목표로 합니다. 전담 지원 팀이 언제든지 대기하고 있으니, 서비스를
              최대한 활용하실 수 있도록 궁금한 점이나 기술 문제 또는 가이드가
              필요한 사항이 있으시면 언제든 문의해 주십시오.
            </p>
            <p>
              도움을 받는 방법은 간단합니다.{" "}
              <a href="mailto:info@createtogather.com">
                info@createtogather.com
              </a>
              으로 이메일을 보내주세요. 기술적인 어려움이 있거나, 특정 기능에
              대한 설명이 필요하거나, 플랫폼 활용도를 높일 수 있는 방법을 알고
              싶으시다면, 숙련된 팀이 신속하고 명확한 해결책을 제시해
              드리겠습니다.
            </p>
            <p>
              저희는 회원님들의 소중한 시간을 존중하며, 문의 사항을 신속하게
              해결하기 위해 최선을 다합니다. 문의하실 때, 문제가 되는 오류
              메시지나 도움을 필요로 하는 기능 등의 구체적인 정보를 포함해
              주시면 해결 과정을 크게 단축할 수 있습니다. 스크린샷을 첨부해
              주시는 것도 많은 도움이 됩니다. 일반적으로 업무일 기준 24시간
              이내(월–금, 공휴일 제외)에 답변해 드리며, 문제가 완전히 해결될
              때까지 끝까지 함께하겠습니다.
            </p>
          </div>
        </div>
      ) : (
        <div className="misc-text-container">
          <div
            style={{
              fontSize: "28px",
              fontWeight: "500",
            }}
          >
            Help &amp; Support
          </div>
          <div style={{ fontSize: "10px", color: "#808080" }}>
            Effective December 23, 2024
          </div>
          <Divider sx={{ marginBottom: "1rem" }} />
          <div style={{ fontSize: "12px", lineHeight: "2" }}>
            <p>
              Welcome to Togather's Help &amp; Support Center. Our goal is to
              ensure your experience with our platform is smooth, rewarding, and
              hassle-free. Our dedicated support team is here to assist you with
              any questions, technical issues, or guidance you may need to make
              the most of our services.
            </p>
            <p>
              Getting help is simple—just send us an email at{" "}
              <a href="mailto:info@createtogather.com">
                info@createtogather.com
              </a>
              . Whether you're encountering technical difficulties, need
              clarification on specific features, or want to learn more about
              our platform’s capabilities, our knowledgeable team will respond
              promptly with clear, actionable solutions.
            </p>
            <p>
              We understand your time is valuable, and we strive to address all
              inquiries efficiently. When contacting us, please include any
              relevant details about the issue, such as error messages or which
              features you need help with. Attaching screenshots (if applicable)
              can greatly assist us in diagnosing and resolving your concern.
              Our typical response time is within 24 business hours
              (Monday–Friday, excluding holidays), and we're committed to
              working with you until your issue is fully resolved.
            </p>
          </div>
        </div>
      )}
    </Box>
  );
};

export default HelpAndSupport;
