import React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { useNavigate } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ReactPlayer from "react-player";
import { supabase } from "./SupabaseClient";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import GroupsIcon from "@mui/icons-material/Groups";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import { useTranslation } from "react-i18next";
import WorkIcon from "@mui/icons-material/Work";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import FolderIcon from "@mui/icons-material/Folder";

function stringAvatar(name) {
  const parts = name.split(" ");
  const firstInitial = parts[0][0];
  const secondInitial = parts.length > 1 ? parts[1][0] : ""; // Check if there is a second part, use it if so, or default to an empty string

  return {
    sx: {
      bgcolor: "#fff",
      color: "#000",
    },
    children: `${firstInitial} ${secondInitial}`.trim(), // Trim to remove any trailing space if there's no second initial
  };
}

const fadeInUpAnimation = {
  "@keyframes fadeInUp": {
    "0%": {
      opacity: 0,
      transform: "translateY(20px)",
    },
    "100%": {
      opacity: 1,
      transform: "translateY(0)",
    },
  },
};

const MyProjects = () => {
  const navigate = useNavigate();
  const colors = ["#333333", "#F28EAC", "#79B4FA", "#9F7FE3"];
  const [myProjects, setMyProjects] = useState([]);
  const [currentUser, setCurrentUser] = useState(
    JSON.parse(localStorage.getItem("user"))
  );
  const [inProgress, setInProgress] = useState(true);
  const [open, setOpen] = useState(false);
  const [projectName, setProjectName] = useState("");
  const [projectToDelete, setProjectToDelete] = useState("");
  const [activeCategory, setActiveCategory] = useState("in progress");
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();
  const [pendingProjects, setPendingProjects] = useState([]);
  const [completedProjects, setCompletedProjects] = useState([]);
  const [activeProjects, setActiveProjects] = useState([]);
  const [applications, setApplications] = useState([]);

  let lastColorIndex = -1;

  // Function to get the next color index
  const getNextColorIndex = () => {
    let nextIndex;
    do {
      nextIndex = Math.floor(Math.random() * colors.length);
    } while (nextIndex === lastColorIndex);
    lastColorIndex = nextIndex;
    return nextIndex;
  };

  const calculateDuration = (duration, durationType) => {
    const durations = {
      Hours: {
        en: "Hour",
        ko: "시간",
      },
      Days: {
        en: "Day",
        ko: "일",
      },
      Weeks: {
        en: "Week",
        ko: "주",
      },
      Months: {
        en: "Month",
        ko: "개월",
      },
    };

    const lang = i18n.language;
    const durationText = durations[durationType][lang];

    if (lang === "ko") {
      return `${duration}${durationText}`;
    }

    return duration > 1
      ? `${duration} ${durationText}s`
      : `${duration} ${durationText}`;
  };

  const navigateToProjectPage = (id) => {
    navigate(`/project/${id}`);
  };

  const navigateToEditProjectPage = (id) => {
    navigate(`/editproject/${id}`);
  };

  const getApplications = async () => {
    const { data, error } = await supabase
      .from("Applications")
      .select(
        `
        *,
        Projects:project_id (*),
        ProjectRoles:role (name) 
      `
      )
      .eq("user", currentUser.sub);

    if (data) {
      setApplications(data);
      return data;
    }
  };

  const handleClose = () => {
    setOpen(false);
    setProjectName("");
    setProjectToDelete("");
  };

  const handleOpen = (projectName, projectId) => {
    setOpen(true);
    setProjectName(projectName);
    setProjectToDelete(projectId);
  };

  const navigateToProjectManagementPage = (id) => {
    navigate("/project/management/" + id);
  };

  const deleteProject = async () => {
    try {
      const response = await fetch(
        `https://6rv1ygx9qe.execute-api.us-east-1.amazonaws.com/prod/projects?id=${projectToDelete}`,
        {
          headers: {
            Authorization: `${localStorage.getItem("accessToken")}`,
          },
          method: "DELETE",
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      // Remove the deleted project from the projects array
      setMyProjects((projects) =>
        projects.filter((project) => project.id !== projectToDelete)
      );

      handleClose();
    } catch (error) {
      console.error("Delete project failed:", error);
    }
  };

  const getMyProjects = async (filter) => {
    setInProgress(true);
    let query = supabase
      .from("Projects")
      .select(
        `
  *,
  Users (first_name, last_name, profile_picture),
  ProjectRoles (*)
`
      )
      .eq("owner", currentUser.sub);

    if (filter) {
      query = query.eq("project_type", filter);
    }

    const { data, error } = await query;
    if (data) {
      setInProgress(false);
      setMyProjects(data);
    }
  };

  const getProjectsByFilter = async (filter) => {
    setMyProjects([]);
    setActiveCategory(filter);
    if (filter === "proposals") {
      setMyProjects(pendingProjects);
    } else if (filter === "in progress") {
      setMyProjects(activeProjects);
    } else if (filter === "completed") {
      setMyProjects(completedProjects);
    } else if (filter === "favorites") {
      getMyFavorites();
    }
  };

  const getProjectsWithProposals = async () => {
    if (currentUser.sub === "1910a5d2-ab7d-4fed-b85e-025cbb5f526c") {
      // Admin query - get all projects
      const { data, error } = await supabase
        .from("Projects")
        .select(
          `
        *,
        Users (first_name, last_name, profile_picture),
        ProjectRoles (*)
      `
        )
        .eq("type", "paid")
        .eq("proposal_stage", "pending")
        .order("created_at", { ascending: false });

      if (data) {
        setInProgress(false);
        setPendingProjects(data);
        return data;
      }
    } else {
      // Regular user query - get owned projects and projects where user has a role
      const ownedProjects = await supabase
        .from("Projects")
        .select(
          `
        *,
        Users (first_name, last_name, profile_picture),
        ProjectRoles (*)
      `
        )
        .eq("type", "paid")
        .eq("proposal_stage", "pending")
        .eq("owner", currentUser.sub)
        .order("created_at", { ascending: false });

      const projectRoles = await supabase
        .from("ProjectRoles")
        .select(
          `
        project_id,
        Projects (
          *,
          Users (first_name, last_name, profile_picture),
          ProjectRoles (*)
        )
      `
        )
        .eq("user", currentUser.sub);

      const projectsWithRoles =
        projectRoles.data
          ?.map((role) => role.Projects)
          .filter(
            (project) =>
              project.type === "paid" && project.proposal_stage === "pending"
          ) || [];

      const combinedProjects = [
        ...(ownedProjects.data || []),
        ...projectsWithRoles,
      ];

      // Remove duplicates based on project id
      const uniqueProjects = Array.from(
        new Map(combinedProjects.map((item) => [item.id, item])).values()
      );
      setPendingProjects(uniqueProjects);

      setInProgress(false);
      return uniqueProjects;
    }
  };

  const getProjectsInProgress = async () => {
    if (currentUser.sub === "1910a5d2-ab7d-4fed-b85e-025cbb5f526c") {
      const { data, error } = await supabase
        .from("Projects")
        .select(
          `
        *,
        Users (first_name, last_name, profile_picture),
        ProjectRoles (*)
      `
        )
        .eq("status", "pending")
        .eq("proposal_stage", "complete")
        .order("created_at", { ascending: false });

      if (data) {
        setActiveProjects(data);
        return data;
      }
      return [];
    } else {
      // Regular user query - get owned projects and projects where user has a role
      const ownedProjects = await supabase
        .from("Projects")
        .select(
          `
        *,
        Users (first_name, last_name, profile_picture),
        ProjectRoles (*)
      `
        )
        .eq("status", "pending")
        .eq("proposal_stage", "complete")
        .eq("owner", currentUser.sub)
        .order("created_at", { ascending: false });

      const projectRoles = await supabase
        .from("ProjectRoles")
        .select(
          `
        project_id,
        Projects (
          *,
          Users (first_name, last_name, profile_picture),
          ProjectRoles (*)
        )
      `
        )
        .eq("user", currentUser.sub);

      const projectsWithRoles =
        projectRoles.data
          ?.map((role) => role.Projects)
          .filter(
            (project) =>
              project.status === "pending" &&
              project.proposal_stage === "complete"
          ) || [];

      const combinedProjects = [
        ...(ownedProjects.data || []),
        ...projectsWithRoles,
      ];

      // Remove duplicates based on project id
      const uniqueProjects = Array.from(
        new Map(combinedProjects.map((item) => [item.id, item])).values()
      );
      setActiveProjects(uniqueProjects);
      return uniqueProjects;
    }
  };

  const getProjectsCompleted = async () => {
    if (currentUser.sub === "1910a5d2-ab7d-4fed-b85e-025cbb5f526c") {
      // Admin query - get all projects
      const { data, error } = await supabase
        .from("Projects")
        .select(
          `
        *,
        Users (first_name, last_name, profile_picture),
        ProjectRoles (*)
      `
        )
        .eq("status", "completed")
        .order("created_at", { ascending: false });

      if (data) {
        setInProgress(false);
        setCompletedProjects(data);
        return data;
      }
    } else {
      // Regular user query - get owned projects and projects where user has a role
      const ownedProjects = await supabase
        .from("Projects")
        .select(
          `
        *,
        Users (first_name, last_name, profile_picture),
        ProjectRoles (*)
      `
        )
        .eq("status", "completed")
        .eq("owner", currentUser.sub)
        .order("created_at", { ascending: false });

      const projectRoles = await supabase
        .from("ProjectRoles")
        .select(
          `
        project_id,
        Projects (
          *,
          Users (first_name, last_name, profile_picture),
          ProjectRoles (*)
        )
      `
        )
        .eq("user", currentUser.sub);

      const projectsWithRoles =
        projectRoles.data
          ?.map((role) => role.Projects)
          .filter((project) => project.status === "completed") || [];

      const combinedProjects = [
        ...(ownedProjects.data || []),
        ...projectsWithRoles,
      ];

      // Remove duplicates based on project id
      const uniqueProjects = Array.from(
        new Map(combinedProjects.map((item) => [item.id, item])).values()
      );
      setCompletedProjects(uniqueProjects);

      setInProgress(false);
      return uniqueProjects;
    }
  };

  const getMyFavorites = async () => {
    setInProgress(true);
    const { data, error } = await supabase
      .from("Favorites")
      .select(
        `
  *,
  Users (first_name, last_name),
  Projects (*)
`
      )
      .match(
        currentUser.sub !== "1910a5d2-ab7d-4fed-b85e-025cbb5f526c"
          ? { user: currentUser.sub }
          : {}
      );
    if (data) {
      setInProgress(false);
      if (data.Projects && data.Projects.length > 0) {
        setMyProjects(data.Projects);
      } else {
        setMyProjects([]);
      }
    }
  };

  const navigateToProposalManagementPage = (id) => {
    navigate(`/proposal/management/${id}`);
  };

  const navigateToViewAppPage = (id) => {
    navigate(`/application/${id}`);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setInProgress(true);
    setActiveCategory("");

    const fetchData = async () => {
      try {
        const [active, completed, pending, apps] = await Promise.all([
          getProjectsInProgress(),
          getProjectsCompleted(),
          getProjectsWithProposals(),
          getApplications(),
        ]);

        // Use the returned data directly instead of relying on state
        if (active.length > 0) {
          setActiveCategory("in progress");
          setMyProjects(active);
        } else if (pending.length > 0) {
          setActiveCategory("proposals");
          setMyProjects(pending);
        } else if (completed.length > 0) {
          setActiveCategory("completed");
          setMyProjects(completed);
        } else {
          setActiveCategory("");
          setMyProjects([]);
        }
      } catch (error) {
        console.error("Error loading projects:", error);
      } finally {
        setInProgress(false);
      }
    };

    fetchData();
  }, []);

  return (
    <Box sx={{ flexGrow: 1, bgcolor: "#ffffff" }}>
      <Box
        className="custom-margins"
        sx={{
          paddingBottom: "3rem",
          paddingTop: "0rem",
        }}
      >
        {" "}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            marginBottom: "30px",
            alignItems: {
              xs: "center",
              sm: "flex-start",
            },
          }}
        >
          {/* <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <FolderIcon sx={{ color: "#666666" }} />
            <Typography
              sx={{
                color: "#666666",
                textAlign: "left",
                fontSize: "20px",
                fontWeight: "400",
                animation: "fadeInUp 0.8s ease-out",
                "@keyframes fadeInUp": {
                  "0%": {
                    opacity: 0,
                    transform: "translateY(20px)",
                  },
                  "100%": {
                    opacity: 1,
                    transform: "translateY(0)",
                  },
                },
              }}
            >
              {t("myProjects")}
            </Typography>
          </Box> */}

          <Box
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column",
                sm: "row",
                md: "row",
              },
              gap: "30px",
              justifyContent: "center",
              width: "100%",
              flexWrap: "wrap",
              alignItems: {
                xs: "center",
                sm: "flex-start",
              },
            }}
          >
            {[
              {
                count: activeProjects.length,
                label: t("activeProjects"),
                color: "#9f7fe3",
                category: "in progress",
                index: 0,
              },
              {
                count: pendingProjects.length,
                label: t("pendingProjects"),
                color: "red",
                category: "proposals",
                index: 1,
              },
              {
                count: completedProjects.length,
                label: t("completedProjects"),
                color: "#4CAF50",
                category: "completed",
                index: 2,
              },
              ...(applications.length > 0
                ? [
                    {
                      count: applications.length,
                      label: t("applications"),
                      color: "#FF8B3D",
                      category: "applications",
                      index: 3,
                    },
                  ]
                : []),
            ].map((item) => (
              <Card
                key={item.label}
                sx={{
                  width: "220px",
                  height: "110px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  backgroundColor: "#ffffff",
                  borderRadius: "12px",
                  border: "1px solid rgba(0,0,0,0.08)",
                  padding: "0 20px",
                  boxShadow: "none",
                  transition: "all 0.3s ease",
                  animation: `fadeInUp 0.6s ease-out ${
                    item.index * 0.15
                  }s both`,
                  "@keyframes fadeInUp":
                    fadeInUpAnimation["@keyframes fadeInUp"],
                  "&:hover": {
                    transform: "translateY(-4px)",
                    boxShadow: "0 8px 16px rgba(0,0,0,0.08)",
                    borderColor: "rgba(0,0,0,0.12)",
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "4px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "34px",
                      fontWeight: "600",
                      color: "#333",
                      lineHeight: 1,
                    }}
                  >
                    {item.count}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "11px",
                      color: "#666",
                      fontWeight: "300",
                    }}
                  >
                    {item.label}
                  </Typography>
                  <Box
                    sx={{
                      width: "56px",
                      height: "3px",
                      backgroundColor: item.color,
                      marginTop: "4px",
                      borderRadius: "2px",
                      animation: "expandFromLeft 0.8s ease-out",
                      "@keyframes expandFromLeft": {
                        "0%": {
                          width: "0px",
                        },
                        "100%": {
                          width: "56px",
                        },
                      },
                    }}
                  />
                </Box>
                <Button
                  variant="text"
                  onClick={() => {
                    setActiveCategory(item.category);
                    getProjectsByFilter(item.category);
                  }}
                  sx={{
                    fontSize: "12px",
                    color: "#666",
                    padding: "4px 12px",
                    border: "none",
                    backgroundColor: "#f8f8f8",
                    borderRadius: "8px",
                    "&:hover": {
                      backgroundColor: "rgba(159, 127, 227, 0.04)",
                    },
                  }}
                >
                  {t("view")}
                </Button>
              </Card>
            ))}
          </Box>
          <Box
            sx={{
              marginTop: "3rem",
              display: "flex",
              flexDirection: {
                xs: "column",
                sm: "row",
                md: "row",
              },
              gap: "30px",
              width: "100%",
              flexWrap: "wrap",
              alignItems: {
                xs: "center",
                sm: "flex-start",
              },
            }}
          >
            {!inProgress && myProjects && myProjects.length === 0 && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                  marginTop: "3rem",
                  animation: "fadeIn 0.8s ease-in-out",
                }}
              >
                <div
                  style={{
                    maxWidth: "400px",
                    width: "100%",
                    animation: "slideDown 1s ease-in-out",
                    animationFillMode: "backwards",
                    animationDelay: "0.1s",
                  }}
                >
                  <DotLottieReact
                    src={
                      "https://noozjeclpgnzvbgdtser.supabase.co/storage/v1/object/public/media/no_results.lottie"
                    }
                    loop
                    autoplay
                  />
                </div>

                <Typography
                  sx={{
                    fontWeight: "600",
                    fontSize: "16px",
                    color: "#c7c7c7",
                    animation: "fadeInUp 0.8s ease-in-out",
                    animationFillMode: "backwards",
                    animationDelay: "0.6s",
                    "@keyframes fadeInUp": {
                      "0%": {
                        opacity: 0,
                        transform: "translateY(20px)",
                      },
                      "100%": {
                        opacity: 1,
                        transform: "translateY(0)",
                      },
                    },
                    "@keyframes fadeIn": {
                      "0%": {
                        opacity: 0,
                      },
                      "100%": {
                        opacity: 1,
                      },
                    },
                    "@keyframes slideDown": {
                      "0%": {
                        opacity: 0,
                        transform: "translateY(-20px)",
                      },
                      "100%": {
                        opacity: 1,
                        transform: "translateY(0)",
                      },
                    },
                  }}
                >
                  {t("noProjects")}
                </Typography>
              </div>
            )}
            {myProjects &&
              myProjects.length > 0 &&
              activeCategory !== "applications" &&
              myProjects.map((project, index) => {
                return (
                  <Card
                    key={project.id}
                    className="card-project"
                    sx={{
                      animation: `fadeInUp 0.6s ease-out ${index * 0.15}s both`,
                      "@keyframes fadeInUp":
                        fadeInUpAnimation["@keyframes fadeInUp"],
                    }}
                  >
                    {project.media.length > 0 &&
                      ReactPlayer.canPlay(project.media[0]) && (
                        <CardMedia
                          onClick={() => navigateToProjectPage(project.id)}
                          className="card-media"
                        >
                          <div
                            style={{ position: "relative", height: "234px" }}
                          >
                            <ReactPlayer
                              url={project.media[0]}
                              width="300px"
                              height="288px"
                              playing={true}
                              loop={true}
                              volume={1}
                              muted={true}
                              controls={false}
                              className="react-player"
                            />
                            <div
                              className="gradient-overlay"
                              style={{
                                position: "absolute",
                                height: "288px",
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                background:
                                  "linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%)",
                              }}
                            />

                            <div
                              style={{
                                position: "relative",
                                bottom: "288px",
                              }}
                            >
                              <div className="title">{project.name}</div>
                            </div>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                              marginLeft: "18px",
                              marginBottom: "10px",
                              zIndex: "1",
                              paddingRight: "1rem",
                            }}
                          >
                            <Avatar
                              className="avatar"
                              {...stringAvatar(
                                `${project.Users.first_name} ${project.Users.last_name}`
                              )}
                              src={project.Users.profile_picture}
                            />
                            <div className="owner">
                              {`${project.Users.first_name} ${project.Users.last_name}`}
                            </div>
                          </div>
                        </CardMedia>
                      )}
                    {project.media.length > 0 &&
                      !ReactPlayer.canPlay(project.media[0]) && (
                        <CardMedia
                          onClick={() => navigateToProjectPage(project.id)}
                          className="card-media"
                          sx={{
                            background: `linear-gradient(
                          0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%
                        ), url(${project.media[0]}) lightgray 50% / cover no-repeat`,
                          }}
                          title="photoshoot"
                        >
                          <div>
                            <div className="title">{project.name} </div>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                              marginBottom: "10px",
                              paddingLeft: "1rem",
                              paddingRight: "1rem",
                            }}
                          >
                            <Avatar
                              className="avatar"
                              {...stringAvatar(
                                `${project.Users.first_name} ${project.Users.last_name}`
                              )}
                              src={project.Users.profile_picture}
                            />
                            <div className="owner">
                              {`${project.Users.first_name} ${project.Users.last_name}`}
                            </div>
                          </div>
                        </CardMedia>
                      )}

                    {!project.media.length && (
                      <CardMedia
                        onClick={() => navigateToProjectPage(project.id)}
                        className="card-media"
                        sx={{
                          background: `${colors[getNextColorIndex()]}`,
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                        }}
                        title="photoshoot"
                      >
                        <div>
                          <div className="title">{project.name} </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                            marginBottom: "10px",
                            paddingLeft: "1rem",
                            paddingRight: "1rem",
                          }}
                        >
                          <Avatar
                            className="avatar"
                            {...stringAvatar(
                              `${project.Users.first_name} ${project.Users.last_name}`
                            )}
                          />
                          <div className="owner">{`${project.Users.first_name} ${project.Users.last_name}`}</div>
                        </div>
                      </CardMedia>
                    )}

                    <CardContent
                      sx={{ padding: "12px 12px 12px 12px", height: "45px" }}
                    >
                      <div
                        className="description"
                        dangerouslySetInnerHTML={{
                          __html: project.description,
                        }}
                      ></div>
                    </CardContent>
                    {project.status === "pending" &&
                      project.proposal_stage === "pending" && (
                        <CardActions
                          sx={{
                            display: "flex",
                            justifyContent: "space-around",
                          }}
                        >
                          <Button
                            size="small"
                            variant="outlined"
                            className="project-card-btn"
                            sx={{
                              fontSize: "12px",
                            }}
                            onClick={() =>
                              currentUser.sub ===
                              "1910a5d2-ab7d-4fed-b85e-025cbb5f526c"
                                ? navigateToProposalManagementPage(project.id)
                                : navigateToProjectManagementPage(project.id)
                            }
                          >
                            {t("manage")}
                          </Button>
                          {/* <IconButton aria-label="delete">
                        <DeleteIcon />
                      </IconButton> */}
                        </CardActions>
                      )}
                    {project.status === "pending" &&
                      project.proposal_stage === "complete" && (
                        <CardActions
                          sx={{
                            display: "flex",
                            justifyContent: "space-around",
                          }}
                        >
                          <Chip
                            className="chip"
                            icon={
                              <GroupsIcon
                                sx={{ color: "#929292", width: "19px" }}
                              />
                            }
                            label={
                              project.ProjectRoles &&
                              `${project.ProjectRoles.length} ${t("people")}`
                            }
                          />
                          {project.duration && project.duration_type && (
                            <Chip
                              className="chip"
                              icon={
                                <TimelapseIcon
                                  sx={{ color: "#929292", width: "19px" }}
                                />
                              }
                              label={calculateDuration(
                                project.duration,
                                project.duration_type
                              )}
                            />
                          )}

                          <Button
                            size="small"
                            variant="outlined"
                            className="project-card-btn"
                            sx={{
                              fontSize: "12px",
                            }}
                            onClick={() =>
                              navigateToProjectManagementPage(project.id)
                            }
                          >
                            {t("manage")}
                          </Button>
                          {/* <IconButton aria-label="delete">
                        <DeleteIcon />
                      </IconButton> */}
                        </CardActions>
                      )}
                  </Card>
                );
              })}

            {applications &&
              applications.length > 0 &&
              activeCategory === "applications" &&
              applications.map((application, index) => (
                <Card
                  key={application.id}
                  sx={{
                    width: "220px",
                    height: "120px",
                    boxShadow: "none",
                    display: "flex",
                    flexDirection: "column",
                    padding: "16px",
                    backgroundColor: "#ffffff",
                    borderRadius: "12px",
                    border: "1px solid rgba(0,0,0,0.08)",
                    transition: "all 0.3s ease",
                    animation: `fadeInUp 0.6s ease-out ${index * 0.1}s both`,
                    "@keyframes fadeInUp":
                      fadeInUpAnimation["@keyframes fadeInUp"],
                    "&:hover": {
                      transform: "translateY(-4px)",
                      boxShadow: "0 8px 16px rgba(0,0,0,0.08)",
                      borderColor: "rgba(0,0,0,0.12)",
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                      gap: 1,
                      height: "100%",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "#333",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {application.Projects?.name}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        gap: 1,
                      }}
                    >
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 1 }}
                      >
                        <WorkIcon sx={{ color: "black", width: "16px" }} />
                        <Typography
                          sx={{
                            fontSize: "12px",
                            color: "black",
                            fontWeight: "300",
                          }}
                        >
                          {application.ProjectRoles?.name}
                        </Typography>
                      </Box>

                      <Typography
                        sx={{
                          fontSize: "10px",
                          color: "black",
                          fontWeight: "300",
                        }}
                      >
                        {t("applied")}:{" "}
                        {new Date(application.created_at).toLocaleDateString()}
                      </Typography>

                      {/* {application.contract_amount && (
                        <Typography
                          sx={{
                            fontSize: "12px",
                            color: "#666",
                          }}
                        >
                          Offer: ${application.contract_amount}
                        </Typography>
                      )} */}
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <Chip
                        label={application.status.toUpperCase()}
                        size="small"
                        sx={{
                          padding: "6px 12px",
                          fontSize: "10px",
                          fontWeight: "600",
                          letterSpacing: "0.5px",
                          borderRadius: "16px",
                          backgroundColor:
                            application.status === "pending"
                              ? "rgba(245, 124, 0, 0.12)"
                              : "rgba(46, 125, 50, 0.12)",
                          color:
                            application.status === "pending"
                              ? "rgb(245, 124, 0)"
                              : "rgb(46, 125, 50)",
                          transition: "all 0.2s ease-in-out",
                          "&:hover": {
                            transform: "translateY(-1px)",
                            boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                          },
                        }}
                      />
                      <Button
                        variant="contained"
                        onClick={() => navigateToViewAppPage(application.id)}
                        sx={{
                          backgroundColor: "#ffffff",
                          color: "#000000",
                          textTransform: "none",
                          boxShadow: "none",
                          fontSize: "12px",
                          fontWeight: "400",
                          padding: "2px 2px",
                          "&:hover": {
                            backgroundColor: "#ffffff",
                            boxShadow: "none",
                          },
                          border: "0.2px solid #000000",
                        }}
                      >
                        {t("view")}
                      </Button>
                    </Box>
                  </Box>
                </Card>
              ))}
            <Dialog
              onClose={handleClose}
              open={open}
              PaperProps={{
                style: { width: "50%" }, // Custom width
              }}
            >
              <DialogTitle>{t("removeProject")}</DialogTitle>
              <DialogContent>
                <DialogContentText>{t("pleaseConfirm")}</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={deleteProject}>{t("confirm")}</Button>
                <Button onClick={handleClose}>{t("cancel")}</Button>
              </DialogActions>
            </Dialog>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MyProjects;
