import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { supabase } from "./SupabaseClient";
import { useTranslation } from "react-i18next";
import {
  Box,
  Typography,
  IconButton,
  Chip,
  Card,
  CardContent,
  Skeleton,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Paper,
  Fade,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from "@mui/material";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import WorkIcon from "@mui/icons-material/Work";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Divider from "@mui/material/Divider";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import useExchangeRate from "./useExchangeRate";

const DEFAULT_CURRENCY = {
  USD: {
    symbol: "$",
    locale: "en-US",
  },
  KRW: {
    symbol: "₩",
    locale: "ko-KR",
  },
};

const AnimatedStep = styled(Step)(({ theme }) => ({
  "& .MuiStepLabel-root": {
    transition: "all 0.3s ease-in-out",
    "&:hover": {
      transform: "translateX(5px)",
    },
  },
}));

const OfferBreakdown = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  gap: 12,
  marginTop: theme.spacing(2),
  "& .MuiTypography-root": {
    transition: "all 0.3s ease-in-out",
  },
  "&:hover .MuiTypography-root": {
    transform: "scale(1.02)",
  },
}));

const ColoredStepper = styled(Stepper)(({ theme }) => ({
  "& .MuiStepIcon-root": {
    cursor: "pointer",
    transition: "all 0.3s ease-in-out",
    fontSize: "24px",
    "&:hover": {
      transform: "scale(1.2)",
    },
    "&.Mui-active": {
      color: "#9f7fe3",
      filter: "drop-shadow(0 0 2px rgba(159, 127, 227, 0.3))",
    },
    "&.Mui-completed": {
      color: "#9f7fe3",
    },
  },
  "& .MuiStepConnector-line": {
    borderColor: "#BDBDBD",
  },
  "& .MuiStepLabel-label": {
    marginLeft: "10px",
    color: "#000000",
    fontWeight: 300,
    "&.Mui-active": {
      color: "#000000",
      fontWeight: 300,
    },
    "&.Mui-completed": {
      color: "#000000",
      fontWeight: 300,
    },
  },
}));

const ViewApp = () => {
  const { appId } = useParams();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [application, setApplication] = useState(null);
  const [loading, setLoading] = useState(true);
  const [openAcceptModal, setOpenAcceptModal] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [expandedSteps, setExpandedSteps] = useState([]);
  const [openCounterModal, setOpenCounterModal] = useState(false);
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const [counterAmount, setCounterAmount] = useState("");
  const { rate: exchangeRate, loading: rateLoading } = useExchangeRate();

  useEffect(() => {
    window.scrollTo(0, 0);
    getApplication();
  }, []);

  const formatCurrency = (amount, currency) => {
    const currencyConfig = DEFAULT_CURRENCY[currency];

    return new Intl.NumberFormat(currencyConfig.locale, {
      style: "currency",
      currency: currency,
      minimumFractionDigits: currency === "KRW" ? 0 : 2,
      maximumFractionDigits: currency === "KRW" ? 0 : 2,
    }).format(amount);
  };

  const getApplication = async () => {
    try {
      const { data, error } = await supabase
        .from("Applications")
        .select(
          `
          *,
          Projects (*),
          ProjectRoles (*),
          Users (*)
        `
        )
        .eq("id", appId)
        .single();

      if (error) throw error;
      setApplication(data);
    } catch (error) {
      console.error("Error fetching application:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  const getActiveStep = (status) => {
    switch (status) {
      case "submitted":
        return 0;
      case "pending":
        return 1;
      case "counter":
        return 1;
      case "hired":
        return 2;
      default:
        return 0;
    }
  };

  const handleAcceptOffer = async () => {
    try {
      const { error } = await supabase
        .from("Applications")
        .update({
          status: "hired",
          accepted_date: new Date().toISOString(),
        })
        .eq("id", appId);

      if (error) throw error;
      // Update ProjectRoles status to hired
      const { error: roleError } = await supabase
        .from("ProjectRoles")
        .update({
          user: application.user,
          filled: true,
          budget: application.contract_amount,
        })
        .eq("id", application.ProjectRoles.id);

      if (roleError) throw roleError;
      setOpenAcceptModal(false);
      getApplication(); // Refresh the application data
    } catch (error) {
      console.error("Error accepting offer:", error);
    }
  };

  const handleCloseModal = () => {
    setOpenAcceptModal(false);
  };

  const handleTermsAccept = (event) => {
    setTermsAccepted(event.target.checked);
  };

  const handleCounter = async () => {
    try {
      const { error } = await supabase
        .from("Applications")
        .update({
          status: "counter",
          original_amount: application.contract_amount,
          contract_amount: parseFloat(counterAmount),
        })
        .eq("id", appId);

      if (error) throw error;
      setOpenCounterModal(false);
      getApplication();
    } catch (error) {
      console.error("Error countering offer:", error);
    }
  };

  const handleReject = async () => {
    try {
      const { error } = await supabase
        .from("Applications")
        .update({
          status: "rejected",
        })
        .eq("id", appId);

      if (error) throw error;
      setOpenRejectModal(false);
      getApplication();
    } catch (error) {
      console.error("Error rejecting offer:", error);
    }
  };

  if (loading) {
    return (
      <Box className="custom-margins" sx={{ p: 3 }}>
        <Skeleton variant="text" width={200} height={40} />
        <Skeleton variant="rectangular" height={400} sx={{ mt: 2 }} />
      </Box>
    );
  }

  return (
    <>
      <Box
        className="custom-margins"
        sx={{
          p: {
            xs: 0,
            sm: 0,
            md: 3,
          },
          bgcolor: "#ffffff",
          mb: 3,
          mt: 6,
          boxShadow: "none !important",
          padding: 3,
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "flex-start",
          flexWrap: "wrap",
        }}
      >
        {" "}
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            gap: {
              xs: 1,
              sm: 1,
              md: 4,
            },
          }}
        >
          <IconButton onClick={handleBack}>
            <NavigateBeforeIcon />
          </IconButton>
          <Box
            sx={{ gap: 2, display: "flex", flexDirection: "column", mt: 0.5 }}
          >
            <Typography variant="h5" gutterBottom>
              {application?.Projects?.name}
            </Typography>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                mb: 2,
              }}
            >
              <WorkIcon sx={{ color: "text.secondary" }} />
              <Typography color="text.secondary">
                {application?.ProjectRoles?.name}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                mb: 2,
              }}
            >
              <CalendarTodayIcon sx={{ color: "text.secondary" }} />
              <Typography color="text.secondary">
                {t("applied")}:{" "}
                {new Date(application?.created_at).toLocaleDateString()}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
              }}
            >
              {" "}
              <Chip
                label={application?.status.toUpperCase()}
                sx={{
                  maxWidth: "200px",
                  padding: "5px 10px",
                  width: "fit-content",
                  backgroundColor:
                    application?.status === "pending"
                      ? "rgba(245, 124, 0, 0.12)"
                      : "rgba(46, 125, 50, 0.12)",
                  color:
                    application?.status === "pending"
                      ? "rgb(245, 124, 0)"
                      : "rgb(46, 125, 50)",
                }}
              />
              <Button
                variant="contained"
                sx={{
                  color: "#000000 !important",
                  backgroundColor: "#ffffff !important",
                  borderRadius: "14px",
                  boxShadow: "rgba(0, 0, 0, 0.35) 0px 0px 1px",
                  fontWeight: "300",
                  fontSize: "10px",
                  "&:hover": {
                    boxShadow: "rgba(0, 0, 0, 0.35) 0px 0px 1px",
                  },
                }}
                onClick={() => navigate(`/project/${application?.project_id}`)}
              >
                {t("viewProject")}
              </Button>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            maxWidth: {
              xs: "216px",
              sm: "216px",
              md: "400px",
            },
            width: "100%",
            marginTop: {
              xs: "2rem",
              sm: "2rem",
              md: 0,
            },
          }}
        >
          <Fade in={true} timeout={800}>
            <ColoredStepper
              activeStep={getActiveStep(application?.status)}
              orientation="vertical"
            >
              <AnimatedStep
                completed={
                  getActiveStep(application?.status) > 0 ||
                  application?.status === "submitted"
                }
                expanded={expandedSteps.includes(0)}
              >
                <StepLabel
                  onClick={() => {
                    if (getActiveStep(application?.status) >= 0) {
                      setExpandedSteps((prev) =>
                        prev.includes(0)
                          ? prev.filter((step) => step !== 0)
                          : [...prev, 0]
                      );
                    }
                  }}
                >
                  {t("application")}
                </StepLabel>
                <StepContent>
                  <Box sx={{ padding: "10px" }}>
                    <Typography sx={{ fontSize: "12px", fontWeight: "300" }}>
                      {t("submittedOn")}:{" "}
                      {new Date(application?.created_at).toLocaleDateString()}
                    </Typography>
                  </Box>
                </StepContent>
              </AnimatedStep>

              <AnimatedStep
                completed={
                  getActiveStep(application?.status) > 1 ||
                  application?.status === "pending" ||
                  application?.status === "counter"
                }
                expanded={expandedSteps.includes(1)}
              >
                <StepLabel
                  onClick={() => {
                    if (getActiveStep(application?.status) >= 1) {
                      setExpandedSteps((prev) =>
                        prev.includes(1)
                          ? prev.filter((step) => step !== 1)
                          : [...prev, 1]
                      );
                    }
                  }}
                >
                  {t("offer")}
                </StepLabel>
                <StepContent>
                  {application?.contract_amount && (
                    <OfferBreakdown elevation={0}>
                      <Typography sx={{ fontSize: "18px" }} gutterBottom>
                        {application.status === "counter"
                          ? t("counterOffer")
                          : t("offer")}{" "}
                        {t("breakdown")}
                      </Typography>

                      <Typography
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span>
                          {application.status === "counter"
                            ? t("counterBid")
                            : t("bid")}
                        </span>
                        <span>
                          {formatCurrency(
                            application.contract_amount || 0,
                            i18n.language === "ko" ? "KRW" : "USD"
                          )}
                        </span>
                      </Typography>
                      <Typography
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        color="text.secondary"
                      >
                        <span>{t("togatherServiceFee")}:</span>
                        <span>
                          -{" "}
                          {formatCurrency(
                            application.contract_amount * 0.1,
                            i18n.language === "ko" ? "KRW" : "USD"
                          )}
                        </span>
                      </Typography>
                      <Divider sx={{ my: 1 }} />
                      <Typography
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          fontWeight: "bold",
                        }}
                      >
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "4px",
                          }}
                        >
                          {t("netAmount")}
                          <Tooltip title={t("netAmountTooltip")}>
                            <InfoIcon
                              sx={{ fontSize: "16px", color: "#929292" }}
                            />
                          </Tooltip>
                        </span>
                        <span>
                          {formatCurrency(
                            (application.contract_amount * 0.9).toLocaleString(
                              undefined,
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            ),
                            i18n.language === "ko" ? "KRW" : "USD"
                          )}
                        </span>
                      </Typography>
                    </OfferBreakdown>
                  )}
                  {application?.status === "pending" && (
                    <Box
                      sx={{
                        display: "flex",
                        gap: 2,
                        mt: 4,
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        variant="contained"
                        sx={{
                          backgroundColor: "#9f7fe3",
                          fontWeight: "400",
                          color: "white",
                          borderRadius: "16px",
                          fontSize: "10px",
                          padding: "2px 16px !important",
                          height: "30px",
                        }}
                        onClick={() => {
                          setOpenAcceptModal(true);
                        }}
                      >
                        {t("accept")}
                      </Button>
                      <Button
                        variant="contained"
                        sx={{
                          backgroundColor: "#ffffff",
                          border: "1px solid #9f7fe3",
                          color: "#9f7fe3",
                          fontWeight: "400",
                          borderRadius: "16px",
                          fontSize: "10px",
                          boxShadow: "none",
                          padding: "2px 16px !important",
                          height: "30px",
                          "&:hover": {
                            backgroundColor: "#ffffff",
                            border: "1px solid #9f7fe3",
                            color: "#9f7fe3",
                          },
                        }}
                        onClick={() => setOpenCounterModal(true)}
                      >
                        {t("counter")}
                      </Button>
                      <Button
                        variant="contained"
                        sx={{
                          backgroundColor: "#ffffff",
                          fontWeight: "400",
                          border: "1px solid #ff4444",
                          color: "#ff4444",
                          borderRadius: "16px",
                          fontSize: "10px",
                          boxShadow: "none",
                          padding: "2px 16px !important",
                          height: "30px",
                          "&:hover": {
                            backgroundColor: "#ffffff",
                            border: "1px solid #ff4444",
                            color: "#ff4444",
                          },
                        }}
                        onClick={() => setOpenRejectModal(true)}
                      >
                        {t("reject")}
                      </Button>
                    </Box>
                  )}
                </StepContent>
              </AnimatedStep>

              <AnimatedStep
                completed={
                  getActiveStep(application?.status) > 2 ||
                  application?.status === "hired"
                }
                expanded={expandedSteps.includes(2)}
              >
                <StepLabel
                  onClick={() => {
                    if (getActiveStep(application?.status) >= 2) {
                      setExpandedSteps((prev) =>
                        prev.includes(2)
                          ? prev.filter((step) => step !== 2)
                          : [...prev, 2]
                      );
                    }
                  }}
                >
                  {t("contractAgreement")}
                </StepLabel>
                <StepContent>
                  {application?.status === "hired" && (
                    <Box sx={{ padding: "10px" }}>
                      <Typography sx={{ fontSize: "12px", fontWeight: "300" }}>
                        {t("contractAcceptedOn")}:{" "}
                        {new Date(
                          application?.accepted_date
                        ).toLocaleDateString()}
                      </Typography>
                    </Box>
                  )}
                </StepContent>
              </AnimatedStep>
            </ColoredStepper>
          </Fade>
        </Box>
      </Box>
      <Dialog
        open={openAcceptModal}
        onClose={handleCloseModal}
        PaperProps={{
          sx: {
            borderRadius: "16px",
            padding: "16px",
            maxWidth: "500px",
            width: "90%",
          },
        }}
      >
        <DialogTitle sx={{ pb: 1 }}>{t("acceptOffer")}</DialogTitle>
        <DialogContent>
          <Box
            sx={{ display: "flex", alignItems: "flex-start", gap: 1, mt: 2 }}
          >
            <Checkbox
              required
              checked={termsAccepted}
              onChange={handleTermsAccept}
              sx={{
                color: "#9f7fe3",
                "&.Mui-checked": {
                  color: "#9f7fe3",
                },
                "& .MuiSvgIcon-root": {
                  fontSize: "14px",
                },
                "&:hover": {
                  boxShadow: "none",
                  backgroundColor: "rgba(159, 127, 227, 0.04)",
                },
              }}
            />
            <Typography>
              {t("iAgreeToThe")}{" "}
              <Link
                href="/terms"
                target="_blank"
                sx={{
                  color: "#9f7fe3",
                  textDecoration: "none",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
              >
                {t("togatherTermsOfService")},
              </Link>{" "}
              {t("andWillOnlyAcceptPaymentsForThisContractOn")}{" "}
              <Link
                href="/terms"
                target="_blank"
                sx={{
                  color: "#9f7fe3",
                  textDecoration: "none",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
              >
                {t("togatherConversionFee")}
              </Link>
              .
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions sx={{ padding: "16px" }}>
          <Button
            onClick={handleCloseModal}
            variant="contained"
            sx={{
              backgroundColor: "#ffffff",
              border: "1px solid #9f7fe3",
              color: "#9f7fe3",
              fontWeight: "400",
              borderRadius: "16px",
              fontSize: "10px",
              boxShadow: "none",
              padding: "8px 24px",
              "&:hover": {
                backgroundColor: "#ffffff",
                border: "1px solid #9f7fe3",
                color: "#9f7fe3",
              },
            }}
          >
            {t("cancel")}
          </Button>
          <Button
            onClick={handleAcceptOffer}
            variant="contained"
            disabled={!termsAccepted}
            sx={{
              backgroundColor: "#9f7fe3",
              fontWeight: "400",
              color: "white",
              borderRadius: "16px",
              fontSize: "10px",
              padding: "8px 24px",
              "&:hover": {
                backgroundColor: "#8b6cd1",
              },
              "&.Mui-disabled": {
                backgroundColor: "#e0e0e0",
                color: "#9e9e9e",
              },
            }}
          >
            {t("confirm")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openCounterModal}
        onClose={() => setOpenCounterModal(false)}
        PaperProps={{
          sx: {
            borderRadius: "16px",
            padding: "16px",
            maxWidth: "500px",
            width: "90%",
          },
        }}
      >
        <DialogTitle sx={{ pb: 1 }}>{t("counterOffer")}</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 2, mt: 2 }}>
            {t("currentOffer")}: $
            {application?.contract_amount.toLocaleString()}
          </DialogContentText>
          <TextField
            fullWidth
            value={counterAmount}
            onChange={(e) => setCounterAmount(e.target.value)}
            type="number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            sx={{ mt: 1 }}
          />
        </DialogContent>
        <DialogActions sx={{ padding: "16px" }}>
          <Button
            onClick={() => setOpenCounterModal(false)}
            variant="contained"
            sx={{
              backgroundColor: "#ffffff",
              border: "1px solid #9f7fe3",
              color: "#9f7fe3",
              fontWeight: "400",
              borderRadius: "16px",
              fontSize: "10px",
              boxShadow: "none",
              padding: "8px 24px",
              "&:hover": {
                backgroundColor: "#ffffff",
                border: "1px solid #9f7fe3",
                color: "#9f7fe3",
              },
            }}
          >
            {t("cancel")}
          </Button>
          <Button
            onClick={handleCounter}
            variant="contained"
            disabled={!counterAmount}
            sx={{
              backgroundColor: "#9f7fe3",
              fontWeight: "400",
              color: "white",
              borderRadius: "16px",
              fontSize: "10px",
              padding: "8px 24px",
              "&:hover": {
                backgroundColor: "#8b6cd1",
              },
              "&.Mui-disabled": {
                backgroundColor: "#e0e0e0",
                color: "#9e9e9e",
              },
            }}
          >
            {t("submit")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openRejectModal}
        onClose={() => setOpenRejectModal(false)}
        PaperProps={{
          sx: {
            borderRadius: "16px",
            padding: "16px",
            maxWidth: "500px",
            width: "90%",
          },
        }}
      >
        <DialogTitle sx={{ pb: 1 }}>{t("rejectOffer")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("areYouSureYouWantToRejectThisContractOffer")}?{" "}
            {t("thisActionCannotBeUndone")}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ padding: "16px" }}>
          <Button
            onClick={() => setOpenRejectModal(false)}
            variant="contained"
            sx={{
              backgroundColor: "#ffffff",
              border: "1px solid #9f7fe3",
              color: "#9f7fe3",
              fontWeight: "400",
              borderRadius: "16px",
              fontSize: "10px",
              boxShadow: "none",
              padding: "8px 24px",
              "&:hover": {
                backgroundColor: "#ffffff",
                border: "1px solid #9f7fe3",
                color: "#9f7fe3",
              },
            }}
          >
            {t("cancel")}
          </Button>
          <Button
            onClick={handleReject}
            variant="contained"
            sx={{
              backgroundColor: "#ff4444",
              fontWeight: "400",
              color: "white",
              borderRadius: "16px",
              fontSize: "10px",
              padding: "8px 24px",
              "&:hover": {
                backgroundColor: "#cc0000",
              },
            }}
          >
            {t("reject")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ViewApp;
