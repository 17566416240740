import React from "react";
import { useState, useEffect } from "react";
import { supabase } from "./SupabaseClient";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";

const UpdatePassword = () => {
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showUpdatePassword, setShowUpdatePassword] = useState(true);

  const { t } = useTranslation();

  const isPasswordValid = () => {
    const passwordPattern =
      /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    if (!passwordPattern.test(newPassword)) {
      setErrorMessage(t("passError"));
      setTimeout(() => {
        setErrorMessage("");
      }, 2000);
      return false;
    }
    return true;
  };

  const handleResetPasswordPress = (event) => {
    // Check if the pressed key is "Enter"
    if (event.key === "Enter") {
      // Prevent the default action to avoid submitting the form (if any)
      event.preventDefault();
      // Trigger the button click
      if (newPassword) {
        handlePasswordReset();
      }
    }
  };

  useEffect(() => {
    supabase.auth.onAuthStateChange(async (event, session) => {
      if (event === "PASSWORD_RECOVERY") {
        setShowUpdatePassword(true);
      }
    });
  }, []);

  const handlePasswordReset = async () => {
    if (confirmPassword === "" || newPassword === "") {
      setErrorMessage(t("allFieldsRequired"));
      setTimeout(() => {
        setErrorMessage("");
      }, 2000);
      return;
    }
    if (newPassword !== confirmPassword) {
      setErrorMessage(t("passMatchError"));
      setTimeout(() => {
        setErrorMessage("");
      }, 2000);
      return;
    }

    if (!isPasswordValid()) {
      return;
    }
    const { data, error } = await supabase.auth.updateUser({
      password: newPassword,
    });

    if (error) {
      setErrorMessage(t("passResetError"));
      setTimeout(() => {
        setErrorMessage("");
      }, 2000);
    } else {
      // Sign in the user with their email and new password
      const { data: signInData, error: signInError } =
        await supabase.auth.signInWithPassword({
          email: data.user.email,
          password: newPassword,
        });

      if (signInError) {
        setErrorMessage(t("loginError"));
        setTimeout(() => {
          setErrorMessage("");
        }, 2000);
      } else {
        localStorage.setItem(
          "user",
          JSON.stringify(signInData.user.user_metadata)
        );
        window.dispatchEvent(new Event("userLogin"));

        navigate("/");
        setShowUpdatePassword(false);
      }
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        justifyItems: "center",
        height: "100dvh",
      }}
    >
      {showUpdatePassword && (
        <div className="auth-container">
          <img
            alt="logo"
            src="/images/new_togather_logo.png"
            style={{
              width: 60,
              marginBottom: "30px",
              cursor: "pointer",
            }}
            onClick={() => navigate("/")}
          />

          <React.Fragment>
            <div className="auth-signup-row">
              <div className="auth-label-wrapper">
                <div className="auth-label">{t("newPassword")}</div>
                <TextField
                  fullWidth
                  type="password"
                  size="small"
                  variant="outlined"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </div>
            </div>
            <div className="auth-signup-row">
              <div className="auth-label-wrapper">
                <div className="auth-label">{t("confirmPassword")}</div>
                <TextField
                  onKeyDown={handleResetPasswordPress}
                  fullWidth
                  type="password"
                  size="small"
                  variant="outlined"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </div>
            </div>

            <Button
              onClick={handlePasswordReset}
              className="auth-login-btn"
              variant="contained"
            >
              Reset
            </Button>
            {errorMessage && (
              <div className="auth-error-msg">{errorMessage}</div>
            )}
            <div
              onClick={() => navigate("/signin")}
              className="auth-bottom-nav"
            >
              {t("login")}
            </div>
          </React.Fragment>
        </div>
      )}
    </div>
  );
};

export default UpdatePassword;
