import React from "react";
import { useState, useEffect } from "react";
import { supabase } from "./SupabaseClient";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";

const ConfirmEmail = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        justifyItems: "center",
        height: "100dvh",
      }}
    >
      <div className="auth-container">
        <img
          alt="logo"
          src="/images/new_togather_logo.png"
          style={{
            width: 60,
            marginBottom: "30px",
            cursor: "pointer",
          }}
          onClick={() => navigate("/")}
        />

        <div style={{ textAlign: "center", marginBottom: "2rem" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
              animation: "fadeIn 0.8s ease-in-out",
            }}
          >
            <div
              style={{
                maxWidth: "400px",
                width: "100%",
                animation: "slideDown 1s ease-in-out",
                animationFillMode: "backwards",
                animationDelay: "0.1s",
              }}
            >
              <DotLottieReact
                src={
                  "https://noozjeclpgnzvbgdtser.supabase.co/storage/v1/object/public/media/check-mark.lottie"
                }
                loop
                autoplay
              />
            </div>

            <Typography
              sx={{
                fontWeight: "400",
                fontSize: "16px",
                color: "#000000",
                animation: "fadeInUp 0.8s ease-in-out",
                animationFillMode: "backwards",
                animationDelay: "0.6s",
                "@keyframes fadeInUp": {
                  "0%": {
                    opacity: 0,
                    transform: "translateY(20px)",
                  },
                  "100%": {
                    opacity: 1,
                    transform: "translateY(0)",
                  },
                },
                "@keyframes fadeIn": {
                  "0%": {
                    opacity: 0,
                  },
                  "100%": {
                    opacity: 1,
                  },
                },
                "@keyframes slideDown": {
                  "0%": {
                    opacity: 0,
                    transform: "translateY(-20px)",
                  },
                  "100%": {
                    opacity: 1,
                    transform: "translateY(0)",
                  },
                },
              }}
            >
              {t("emailConfirmed")}
            </Typography>
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: "14px",
                marginTop: "1rem",
                color: "#000000",
                animation: "fadeInUp 0.8s ease-in-out",
                animationFillMode: "backwards",
                animationDelay: "0.6s",
                "@keyframes fadeInUp": {
                  "0%": {
                    opacity: 0,
                    transform: "translateY(20px)",
                  },
                  "100%": {
                    opacity: 1,
                    transform: "translateY(0)",
                  },
                },
                "@keyframes fadeIn": {
                  "0%": {
                    opacity: 0,
                  },
                  "100%": {
                    opacity: 1,
                  },
                },
                "@keyframes slideDown": {
                  "0%": {
                    opacity: 0,
                    transform: "translateY(-20px)",
                  },
                  "100%": {
                    opacity: 1,
                    transform: "translateY(0)",
                  },
                },
              }}
            >
              {t("emailConfirmationSuccess")}
            </Typography>
          </div>
        </div>

        <Button
          onClick={() => navigate("/login")}
          className="auth-login-btn"
          variant="contained"
          sx={{ fontWeight: "600" }}
        >
          {t("proceedToLogin")}
        </Button>
      </div>
    </div>
  );
};

export default ConfirmEmail;
