import React from "react";
import { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Skeleton from "@mui/material/Skeleton";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import { useNavigate, useLocation } from "react-router-dom";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import dayjs from "dayjs";
import NotesIcon from "@mui/icons-material/Notes";
import IconButton from "@mui/material/IconButton";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { supabase } from "./SupabaseClient";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import { styled } from "@mui/material/styles";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";
import Divider from "@mui/material/Divider";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Badge from "@mui/material/Badge";
import VerifiedIcon from "@mui/icons-material/Verified";

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

const ColoredStepper = styled(Stepper)(({ theme }) => ({
  "& .MuiStepIcon-root": {
    cursor: "pointer",
    transition: "all 0.3s ease-in-out",
    fontSize: "24px",
    "&:hover": {
      transform: "scale(1.2)",
    },
    "&.Mui-active": {
      color: "#9f7fe3",
      filter: "drop-shadow(0 0 2px rgba(159, 127, 227, 0.3))",
    },
    "&.Mui-completed": {
      color: "#9f7fe3",
    },
  },
  "& .MuiStepConnector-line": {
    borderColor: "#BDBDBD",
  },
  "& .MuiStepLabel-label": {
    marginLeft: "10px",
    color: "#000000",
    fontWeight: 400,
    "&.Mui-active": {
      color: "#000000",
      fontWeight: 400,
    },
    "&.Mui-completed": {
      color: "#000000",
      fontWeight: 400,
    },
  },
}));

const AnimatedStep = styled(Step)(({ theme }) => ({
  transition: "all 0.3s ease-in-out",
}));

const OfferBreakdown = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.spacing(2),
  backgroundColor: "#f5f5f5",
  marginTop: "10px",
  gap: "12px",
  display: "flex",
  flexDirection: "column",
}));

const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 15,
  height: 15,
  backgroundColor: "#9f7fe3",
  color: "#fff",
}));

const ViewSubmissions = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state;
  const [open, setOpen] = useState(false);
  const [comment, setComment] = useState("");
  const [applications, setApplications] = useState(
    data ? data.applications : []
  );
  const [projectType, setProjectType] = useState(data ? data.type : "");
  const [roleId, setRoleId] = useState(data ? data.id : "");
  const [contractDialog, setContractDialog] = useState(false);
  const [contractAmount, setContractAmount] = useState("");
  const [selectedApplicant, setSelectedApplicant] = useState(null);
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [timelineDialog, setTimelineDialog] = useState(false);
  const [selectedTimeline, setSelectedTimeline] = useState(null);
  const [expandedSteps, setExpandedSteps] = useState([]);
  const [showAccept, setShowAccept] = useState(false);
  const [showCounter, setShowCounter] = useState(false);
  const [showReject, setShowReject] = useState(false);
  const [showCounterButton, setShowCounterButton] = useState(true);
  const [showRejectButton, setShowRejectButton] = useState(true);
  const [showAcceptButton, setShowAcceptButton] = useState(true);
  const [activeTab, setActiveTab] = useState("");
  const [showCounterAmountField, setShowCounterAmountField] = useState(false);
  const [counterAmount, setCounterAmount] = useState("");
  const [sortBy, setSortBy] = useState("newest");
  const [isApplicant, setIsApplicant] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      setComment("");
    }, 200);
  };

  const handleOpen = (comment) => {
    setOpen(true);
    setComment(comment);
  };

  const navigateToProfile = (userId) => {
    navigate(`/profile/${userId}`);
  };

  const handleBack = () => {
    navigate(`/project/management/${data.projectId}`);
  };

  const handleContractDialogOpen = (item) => {
    if (item.status === "pending" || item.status === "counter") {
      setSelectedTimeline(item);
      setTimelineDialog(true);
    } else {
      setSelectedApplicant(item);
      setContractDialog(true);
    }
  };

  const handleContractDialogClose = () => {
    setContractDialog(false);
    setContractAmount("");
    setSelectedApplicant(null);
  };

  const handleContractSubmit = async () => {
    if (!contractAmount || isNaN(contractAmount)) {
      return;
    }

    await handleApproval(parseFloat(contractAmount));
  };

  const handleApproval = async (amount) => {
    const { data, error } = await supabase
      .from("Applications")
      .update({
        status: "pending",
        contract_amount: amount,
      })
      .eq("id", selectedApplicant.id);

    if (error) {
      console.log(error);
    } else {
      setApplications((prevApplications) =>
        prevApplications.map((app) =>
          app.id === selectedApplicant.id ? { ...app, status: "pending" } : app
        )
      );
      handleSendOfferNotifications(amount, selectedApplicant);
    }
  };

  const handleSendOfferNotifications = async (amount, item) => {
    let payload = {
      application_id: item.id,
      amount,
      email: item.Users.email,
      first_name: item.Users.first_name,
      language: i18n.language,
    };
    try {
      const response = await fetch(
        "https://54rr0rbk9h.execute-api.us-east-1.amazonaws.com/prod/send-offer",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            payload,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to send offer notifications");
      }

      const data = await response.json();
      handleContractDialogClose();
    } catch (error) {
      console.error("Error sending offer notifications:", error);
    }
  };

  const handleTimelineDialogClose = () => {
    setTimelineDialog(false);
    setTimeout(() => {
      setSelectedTimeline(null);
    }, 200);
  };

  const getActiveStep = (status) => {
    switch (status) {
      case "submitted":
        return 0;
      case "pending":
        return 1;
      case "counter":
        return 1;
      case "hired":
        return 2;
      default:
        return 0;
    }
  };

  const inProgressCount = applications.filter(
    (app) => app.status === "pending" || app.status === "counter"
  ).length;

  const submittedCount = applications.filter(
    (app) => app.status === "submitted"
  ).length;

  const rejectedCount = applications.filter(
    (app) => app.status === "rejected"
  ).length;

  useEffect(() => {
    if (selectedTimeline) {
      const activeStep = getActiveStep(selectedTimeline.status);
      const completedSteps = [];

      // Add all steps up to and including the active step
      for (let i = 0; i <= activeStep; i++) {
        completedSteps.push(i);
      }

      setExpandedSteps(completedSteps);
    }
  }, [selectedTimeline]);

  const getFilteredApplications = () => {
    switch (activeTab) {
      case "in-progress":
        return applications.filter(
          (app) => app.status === "pending" || app.status === "counter"
        );
      case "submitted":
        return applications.filter((app) => app.status === "submitted");
      case "rejected":
        return applications.filter((app) => app.status === "rejected");
      default:
        return applications;
    }
  };

  useEffect(() => {
    setActiveTab("in-progress"); // Set default tab
  }, []);

  const reviseOffer = async () => {
    if (selectedTimeline) {
      const { data, error } = await supabase
        .from("Applications")
        .update({ status: "pending" })
        .eq("id", selectedTimeline.id);

      if (error) {
        console.error("Error updating application:", error);
        return;
      }

      // Update the local applications state
      setApplications((prevApplications) =>
        prevApplications.map((app) =>
          app.id === selectedTimeline.id ? { ...app, status: "pending" } : app
        )
      );

      await handleSendOfferNotifications(
        selectedTimeline.contract_amount,
        selectedTimeline
      );
    }
  };

  const contentRef = React.useRef();

  const scrollToBottom = () => {
    contentRef.current.scrollTo({
      top: contentRef.current.scrollHeight,
      behavior: "smooth",
    });
  };

  const handleRejectOffer = async () => {
    if (selectedTimeline) {
      const { data, error } = await supabase
        .from("Applications")
        .update({ status: "rejected" })
        .eq("id", selectedTimeline.id);

      if (error) {
        console.error("Error rejecting application:", error);
        return;
      }

      // Update the local applications state
      setApplications((prevApplications) =>
        prevApplications.map((app) =>
          app.id === selectedTimeline.id ? { ...app, status: "rejected" } : app
        )
      );

      // Close the dialog
      setTimelineDialog(false);
    }
  };

  const handleCounterOffer = async () => {
    if (selectedTimeline) {
      const { data, error } = await supabase
        .from("Applications")
        .update({ status: "pending", contract_amount: counterAmount })
        .eq("id", selectedTimeline.id);

      if (error) {
        console.error("Error updating application:", error);
        return;
      }

      setApplications((prevApplications) =>
        prevApplications.map((app) =>
          app.id === selectedTimeline.id
            ? { ...app, status: "pending", contract_amount: counterAmount }
            : app
        )
      );

      await handleSendOfferNotifications(counterAmount, selectedTimeline);

      setShowCounter(false);
      setShowAcceptButton(true);
      setShowRejectButton(true);
      setShowCounterAmountField(false);
      setCounterAmount("");
      // Close the dialog
      setTimelineDialog(false);
    }
  };

  const getSortedApplications = () => {
    const sortedApplications = [...getFilteredApplications()];

    switch (sortBy) {
      case "newest":
        sortedApplications.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
        break;
      case "oldest":
        sortedApplications.sort(
          (a, b) => new Date(a.created_at) - new Date(b.created_at)
        );
        break;
      case "experience_high":
        sortedApplications.sort((a, b) => b.experience - a.experience);
        break;
      case "experience_low":
        sortedApplications.sort((a, b) => a.experience - b.experience);
        break;
      default:
        break;
    }

    return sortedApplications;
  };

  const handleHire = async (item) => {
    // Update selectedApplicant first
    setSelectedApplicant(item);

    const { data, error } = await supabase
      .from("Applications")
      .update({
        status: "pending",
        contract_amount: 0,
      })
      .eq("id", item.id);

    if (error) {
      console.log(error);
    } else {
      // Update local state to reflect the change
      setApplications((prevApplications) =>
        prevApplications.map((app) =>
          app.id === item.id ? { ...app, status: "pending" } : app
        )
      );

      // Send notifications if needed
      await handleSendOfferNotifications(0, item);
    }
  };

  // Add useEffect for initial scroll
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); // Empty dependency array means this runs once on mount

  useEffect(() => {
    const checkIfApplicant = async () => {
      const {
        data: { user },
      } = await supabase.auth.getUser();
      if (selectedTimeline && user) {
        setIsApplicant(selectedTimeline.Users.id === user.id);
      }
    };

    checkIfApplicant();
  }, [selectedTimeline]);

  return (
    <Box sx={{ flexGrow: 1, bgcolor: "#ffffff" }}>
      {data ? (
        <Box
          className="custom-margins"
          sx={{
            display: "flex",
            flexDirection: "column",
            paddingBottom: "3rem",
            paddingTop: "1rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              marginBottom: "30px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Button
                onClick={handleBack}
                startIcon={<ArrowBackIcon />}
                sx={{
                  color: "#000000",
                  height: "30px",
                  backgroundColor: "#ffffff",
                  padding: "2px 12px 2px 12px",
                  borderRadius: "14px",
                  boxShadow: "rgba(0, 0, 0, 0.35) 0px 0px 1px",
                  fontWeight: "400",
                  fontSize: "12px",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "#f5f5f5",
                  },
                  marginRight: "14px",
                }}
              >
                {t("back")}
              </Button>
              <Typography sx={{ fontWeight: "400", fontSize: "20px" }}>
                {t(data.name)}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: 4,
                marginTop: "1rem",
                marginBottom: "1rem",
                justifyContent: "center",
              }}
            >
              <Typography
                onClick={() => {
                  setActiveTab("in-progress");
                }}
                sx={{
                  cursor: "pointer",
                  position: "relative",
                  padding: "6px 8px 6px 8px",
                  fontSize: "14px",
                  fontWeight: activeTab === "in-progress" ? "400" : "300",
                  transition: "all 0.3s ease",
                  opacity: activeTab === "in-progress" ? 1 : 0.7,
                  transform:
                    activeTab === "in-progress" ? "translateY(-2px)" : "none",
                  "&:hover": {
                    opacity: 1,
                    transform: "translateY(-2px)",
                    "&::after": {
                      width: "100%",
                    },
                  },
                  "&::after": {
                    content: '""',
                    position: "absolute",
                    bottom: 0,
                    left: "50%",
                    transform: "translateX(-50%)",
                    height: "2px",
                    width: activeTab === "in-progress" ? "100%" : "0",
                    backgroundColor: "#9F7FE3",
                    transition: "width 0.8s ease",
                  },
                }}
              >
                {t("in-progress")} ({inProgressCount})
              </Typography>

              <Typography
                onClick={() => {
                  setActiveTab("submitted");
                }}
                sx={{
                  cursor: "pointer",
                  position: "relative",
                  padding: "6px 8px 6px 8px",
                  fontSize: "14px",
                  fontWeight: activeTab === "submitted" ? "400" : "300",
                  transition: "all 0.3s ease",
                  opacity: activeTab === "submitted" ? 1 : 0.7,
                  transform:
                    activeTab === "submitted" ? "translateY(-2px)" : "none",
                  "&:hover": {
                    opacity: 1,
                    transform: "translateY(-2px)",
                    "&::after": {
                      width: "100%",
                    },
                  },
                  "&::after": {
                    content: '""',
                    position: "absolute",
                    bottom: 0,
                    left: "50%",
                    transform: "translateX(-50%)",
                    height: "2px",
                    width: activeTab === "submitted" ? "100%" : "0",
                    backgroundColor: "#9F7FE3",
                    transition: "width 0.8s ease",
                  },
                }}
              >
                {t("submitted")} ({submittedCount})
              </Typography>

              <Typography
                onClick={() => {
                  setActiveTab("rejected");
                }}
                sx={{
                  cursor: "pointer",
                  position: "relative",
                  padding: "6px 8px 6px 8px",
                  fontSize: "14px",
                  fontWeight: activeTab === "rejected" ? "400" : "300",
                  transition: "all 0.3s ease",
                  opacity: activeTab === "rejected" ? 1 : 0.7,
                  transform:
                    activeTab === "rejected" ? "translateY(-2px)" : "none",
                  "&:hover": {
                    opacity: 1,
                    transform: "translateY(-2px)",
                    "&::after": {
                      width: "100%",
                    },
                  },
                  "&::after": {
                    content: '""',
                    position: "absolute",
                    bottom: 0,
                    left: "50%",
                    transform: "translateX(-50%)",
                    height: "2px",
                    width: activeTab === "rejected" ? "100%" : "0",
                    backgroundColor: "#9F7FE3",
                    transition: "width 0.8s ease",
                  },
                }}
              >
                {t("rejected")} ({rejectedCount})
              </Typography>
            </Box>
            <Box
              sx={{
                marginBottom: "1rem",
                marginTop: "2rem",
                display: "flex",
                justifyContent: "flex-end",
                paddingRight: "1rem",
              }}
            >
              <FormControl
                size="small"
                sx={{
                  minWidth: 200,
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "12px",
                    "& fieldset": {
                      borderColor: "#e0e0e6",
                    },
                    "&:hover fieldset": {
                      borderColor: "#9f7fe3",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#9f7fe3",
                    },
                  },
                }}
              >
                <InputLabel>Sort By</InputLabel>
                <Select
                  value={sortBy}
                  label="Sort By"
                  onChange={(e) => setSortBy(e.target.value)}
                >
                  <MenuItem value="newest">{t("newest")}</MenuItem>
                  <MenuItem value="oldest">{t("oldest")}</MenuItem>
                  <MenuItem value="experience_high">
                    {t("most-experienced")}
                  </MenuItem>
                  <MenuItem value="experience_low">
                    {t("least-experienced")}
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box>
              <Box
                sx={{
                  display: "flex",
                }}
              >
                {applications && getSortedApplications().length > 0 ? (
                  getSortedApplications().map((item) => (
                    <Box
                      key={item.id}
                      sx={{
                        padding: "32px 24px",
                        display: "flex",
                        alignItems: "center",
                        marginTop: "20px",
                        gap: "16px",
                        background: "linear-gradient(145deg, #ffffff, #f5f5f5)",
                        borderRadius: "16px",
                        height: "auto",
                        width: "100%",
                        maxWidth: "160px",
                        flexDirection: "column",
                        position: "relative",
                        transition: "all 0.3s ease",
                        border: "1px solid #e6e6e6",
                        "&:hover": {
                          transform: "translateY(-5px)",
                          border: "1px solid #9f7fe3",
                        },
                      }}
                    >
                      {(item.status === "pending" ||
                        item.status === "counter") && (
                        <Box
                          sx={{
                            position: "absolute",
                            top: "16px",
                            right: "16px",
                          }}
                        >
                          <Chip
                            label={t("pending")}
                            variant="filled"
                            size="small"
                            sx={{
                              color: "#9f7fe3",
                              backgroundColor: "rgba(159, 127, 227, 0.1)",
                              backdropFilter: "blur(8px)",
                              "& .MuiChip-label": {
                                fontSize: "10px",
                                fontWeight: "500",
                                letterSpacing: "0.5px",
                              },
                            }}
                          />
                        </Box>
                      )}
                      {item.Users.pro ? (
                        <Badge
                          overlap="circular"
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          badgeContent={
                            <SmallAvatar>
                              <VerifiedIcon sx={{ fontSize: 12 }} />
                            </SmallAvatar>
                          }
                        >
                          <Avatar
                            sx={{
                              width: "56px",
                              height: "56px",
                              cursor: "pointer",
                              transition: "transform 0.2s ease",
                              "&:hover": {
                                transform: "scale(1.1)",
                              },
                            }}
                            onClick={() => navigateToProfile(item.Users.id)}
                            alt={`${
                              item.Users.first_name + " " + item.Users.last_name
                            }`}
                            src={item.Users.profile_image}
                          />
                        </Badge>
                      ) : (
                        <Avatar
                          sx={{
                            width: "56px",
                            height: "56px",
                            cursor: "pointer",
                            transition: "transform 0.2s ease",
                            "&:hover": {
                              transform: "scale(1.1)",
                            },
                          }}
                          onClick={() => navigateToProfile(item.Users.id)}
                          alt={`${
                            item.Users.first_name + " " + item.Users.last_name
                          }`}
                          src={item.Users.profile_image}
                        />
                      )}
                      <Typography
                        sx={{
                          fontWeight: "500",
                          fontSize: "14px",
                          color: "#2d2d2d",
                          textAlign: "center",
                        }}
                      >
                        {`${
                          item.Users.first_name + " " + item.Users.last_name
                        }`}
                      </Typography>
                      <Button
                        size="small"
                        sx={{
                          background: "#ffffff",
                          backdropFilter: "blur(8px)",
                          ":hover": {
                            background: "#ffffff",
                          },
                          borderRadius: "12px",
                          color: "#9f7fe3",
                          fontWeight: "500",
                          fontSize: "11px",
                          padding: "6px 16px",
                          textTransform: "none",
                          transition: "all 0.2s ease",
                        }}
                        onClick={() => handleOpen(item.note)}
                        variant="contained"
                        startIcon={<NotesIcon sx={{ height: "14px" }} />}
                      >
                        {t("note")}
                      </Button>
                      <Typography
                        sx={{
                          fontWeight: "400",
                          fontSize: "11px",
                          color: "#666666",
                          letterSpacing: "0.3px",
                        }}
                      >
                        {t("applied")}:{" "}
                        {dayjs(item.created_at).format("M/D/YY")}
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: "400",
                          fontSize: "11px",
                          color: "#666666",
                          letterSpacing: "0.3px",
                        }}
                      >
                        {item.years_experience}{" "}
                        {item.years_experience === 1 ? t("year") : t("years")}{" "}
                        {t("experience")}
                      </Typography>
                      {(item.status === "pending" ||
                        item.status === "counter") &&
                        projectType === "paid" && (
                          <Typography
                            sx={{
                              fontWeight: "500",
                              fontSize: "12px",
                              color: "#9f7fe3",
                            }}
                          >
                            ${item.contract_amount}
                          </Typography>
                        )}
                      <Box
                        sx={{
                          display: "flex",
                          gap: "8px",
                          flexWrap: "wrap",
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          variant="contained"
                          size="small"
                          sx={{
                            background: "#9f7fe3",
                            ":hover": {
                              background: "#8b6dd4",
                            },
                            borderRadius: "12px",
                            color: "white",
                            fontWeight: "500",
                            fontSize: "11px",
                            padding: "6px 16px",
                            textTransform: "none",
                            boxShadow: "none",
                            transition: "all 0.2s ease",
                          }}
                          onClick={() => navigateToProfile(item.Users.id)}
                        >
                          {t("profile")}
                        </Button>
                        {item.status !== "pending" &&
                          item.status !== "counter" && (
                            <Button
                              variant="contained"
                              size="small"
                              sx={{
                                background: "#9f7fe3",
                                ":hover": {
                                  background: "#8b6dd4",
                                },
                                borderRadius: "12px",
                                color: "white",
                                fontWeight: "500",
                                fontSize: "11px",
                                padding: "6px 16px",
                                textTransform: "none",
                                boxShadow: "none",
                                transition: "all 0.2s ease",
                              }}
                              onClick={() => {
                                if (projectType === "collab") {
                                  handleHire(item);
                                } else if (projectType === "paid") {
                                  handleContractDialogOpen(item);
                                }
                              }}
                            >
                              {t("hire")}
                            </Button>
                          )}
                        {(item.status === "pending" ||
                          item.status === "counter") && (
                          <Button
                            variant="contained"
                            size="small"
                            sx={{
                              background: "#9f7fe3",
                              ":hover": {
                                background: "#8b6dd4",
                              },
                              borderRadius: "12px",
                              color: "white",
                              fontWeight: "400",
                              fontSize: "11px",
                              padding: "2px 16px !important",
                              textTransform: "none",
                              boxShadow: "none",
                              transition: "all 0.2s ease",
                            }}
                            onClick={() => handleContractDialogOpen(item)}
                          >
                            {t("manage")}
                          </Button>
                        )}
                      </Box>
                      <Dialog
                        onClose={handleClose}
                        open={open}
                        PaperProps={{
                          sx: {
                            padding: "24px",
                            width: "500px",
                            borderRadius: "16px",
                          },
                        }}
                      >
                        <DialogTitle>Custom Note</DialogTitle>
                        <DialogContent>
                          <DialogContentText>{comment}</DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            sx={{
                              fontSize: "12px",
                              color: "#ffffff !important",
                              textTransform: "none",
                            }}
                            onClick={handleClose}
                          >
                            Close
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </Box>
                  ))
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      width: "100%",
                      marginTop: "3rem",
                      animation: "fadeIn 0.2s ease-in-out",
                    }}
                  >
                    <div
                      style={{
                        maxWidth: "400px",
                        width: "100%",
                        animation: "slideDown 1s ease-in-out",
                        animationFillMode: "backwards",
                        animationDelay: "0.1s",
                      }}
                    >
                      <DotLottieReact
                        src={
                          "https://noozjeclpgnzvbgdtser.supabase.co/storage/v1/object/public/media/no_results.lottie"
                        }
                        loop
                        autoplay
                      />
                    </div>

                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "16px",
                        color: "#c7c7c7",
                        animation: "fadeInUp 0.8s ease-in-out",
                        animationFillMode: "backwards",
                        animationDelay: "0.2s",
                        "@keyframes fadeInUp": {
                          "0%": {
                            opacity: 0,
                            transform: "translateY(20px)",
                          },
                          "100%": {
                            opacity: 1,
                            transform: "translateY(0)",
                          },
                        },
                        "@keyframes fadeIn": {
                          "0%": {
                            opacity: 0,
                          },
                          "100%": {
                            opacity: 1,
                          },
                        },
                        "@keyframes slideDown": {
                          "0%": {
                            opacity: 0,
                            transform: "translateY(-20px)",
                          },
                          "100%": {
                            opacity: 1,
                            transform: "translateY(0)",
                          },
                        },
                      }}
                    >
                      {t("noApplications")}
                    </Typography>
                  </div>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <Stack
          className="custom-margins"
          spacing={4}
          sx={{
            paddingTop: "2rem",
            paddingBottom: "2rem",
          }}
        >
          <Skeleton variant="rounded" width={300} height={70} />
          <Skeleton variant="rounded" width={600} height={40} />
          <Grid sx={{ marginBottom: "40px" }} container spacing={1}>
            <Grid
              sx={{
                width: "560px",
                height: "344px",
                position: "relative",
                overflow: "hidden",
              }}
              item
              xs={6}
            >
              <Skeleton variant="rounded" height={320} />
            </Grid>

            <Grid item xs={6}>
              <Grid container spacing={1}>
                <Grid
                  sx={{
                    width: "272px",
                    height: "172px",
                    position: "relative",
                    overflow: "hidden",
                  }}
                  item
                  xs={6}
                >
                  <Skeleton variant="rounded" height={150} />
                </Grid>

                <Grid
                  sx={{
                    width: "272px",
                    height: "172px",
                    position: "relative",
                    overflow: "hidden",
                  }}
                  item
                  xs={6}
                >
                  <Skeleton variant="rounded" height={150} />
                </Grid>

                <Grid
                  sx={{
                    width: "272px",
                    height: "172px",
                    position: "relative",
                    overflow: "hidden",
                  }}
                  item
                  xs={6}
                >
                  <Skeleton variant="rounded" height={150} />
                </Grid>

                <Grid
                  sx={{
                    width: "272px",
                    height: "172px",
                    position: "relative",
                    overflow: "hidden",
                  }}
                  item
                  xs={6}
                >
                  <Skeleton variant="rounded" height={150} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Skeleton variant="rounded" width={1200} height={300} />
        </Stack>
      )}
      <Dialog
        open={contractDialog}
        onClose={handleContractDialogClose}
        PaperProps={{
          sx: {
            padding: "10px",
            width: "400px",
          },
        }}
      >
        <DialogTitle>Set Contract Amount</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>
            Please enter the contract amount for this role
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Amount"
            type="number"
            fullWidth
            value={contractAmount}
            onChange={(e) => setContractAmount(e.target.value)}
            InputProps={{
              startAdornment: "$",
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button sx={{ fontSize: "10px" }} onClick={handleContractDialogClose}>
            {t("cancel")}
          </Button>
          <Button sx={{ fontSize: "10px" }} onClick={handleContractSubmit}>
            {t("sendOffer")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={timelineDialog}
        onClose={handleTimelineDialogClose}
        PaperProps={{
          sx: {
            borderRadius: "16px",
            padding: "16px",
            maxWidth: "600px",
            width: "100%",
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {t("applicationTimeline")}{" "}
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              color: "#000000",
              borderRadius: "16px",
              boxShadow: "none",
              backgroundColor: "#ffffff",
              border: "1px solid #9f7fe3",
              fontSize: "12px",
              "&:hover": {
                backgroundColor: "#fafafa",
                boxShadow: "none",
              },
            }}
            onClick={() => {
              navigate(`/chat`, {
                state: { recipientId: selectedTimeline?.Users?.id },
              });
            }}
          >
            Send Message
          </Button>
        </DialogTitle>
        <DialogContent ref={contentRef}>
          {selectedTimeline && (
            <ColoredStepper
              activeStep={getActiveStep(selectedTimeline?.status)}
              orientation="vertical"
            >
              <AnimatedStep
                completed={
                  getActiveStep(selectedTimeline?.status) > 0 ||
                  selectedTimeline?.status === "submitted"
                }
                expanded={expandedSteps.includes(0)}
              >
                <StepLabel
                  onClick={() => {
                    if (getActiveStep(selectedTimeline?.status) >= 0) {
                      setExpandedSteps((prev) =>
                        prev.includes(0)
                          ? prev.filter((step) => step !== 0)
                          : [...prev, 0]
                      );
                    }
                  }}
                >
                  Application
                </StepLabel>
                <StepContent>
                  <Box sx={{ padding: "10px" }}>
                    <Typography sx={{ fontSize: "12px", fontWeight: "300" }}>
                      Submitted on{" "}
                      {new Date(
                        selectedTimeline?.created_at
                      ).toLocaleDateString()}
                    </Typography>
                  </Box>
                </StepContent>
              </AnimatedStep>

              <AnimatedStep
                completed={
                  getActiveStep(selectedTimeline?.status) > 1 ||
                  selectedTimeline?.status === "pending" ||
                  selectedTimeline?.status === "counter"
                }
                expanded={expandedSteps.includes(1)}
              >
                <StepLabel
                  onClick={() => {
                    if (getActiveStep(selectedTimeline?.status) >= 1) {
                      setExpandedSteps((prev) =>
                        prev.includes(1)
                          ? prev.filter((step) => step !== 1)
                          : [...prev, 1]
                      );
                    }
                  }}
                >
                  Offer
                </StepLabel>
                <StepContent>
                  {selectedTimeline?.contract_amount &&
                  projectType === "paid" ? (
                    <OfferBreakdown elevation={0}>
                      <Typography sx={{ fontSize: "18px" }} gutterBottom>
                        {selectedTimeline.status === "counter"
                          ? "Counter Offer"
                          : "Offer"}{" "}
                        Breakdown
                      </Typography>

                      <Typography
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span>
                          {selectedTimeline.status === "counter"
                            ? "Counter Bid:"
                            : "Bid:"}
                        </span>
                        <span>
                          $ {selectedTimeline.contract_amount.toLocaleString()}
                        </span>
                      </Typography>
                      <Typography
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        color="text.secondary"
                      >
                        <span>10% Togather Service Fee:</span>
                        <span>
                          - ${" "}
                          {(
                            selectedTimeline.contract_amount * 0.1
                          ).toLocaleString()}
                        </span>
                      </Typography>
                      <Divider sx={{ my: 1 }} />
                      <Typography
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          fontWeight: "bold",
                        }}
                      >
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "4px",
                          }}
                        >
                          Net Amount
                          <Tooltip title="The final amount the freelancer will receive after service fees">
                            <InfoIcon
                              sx={{ fontSize: "16px", color: "#929292" }}
                            />
                          </Tooltip>
                        </span>
                        <span>
                          ${" "}
                          {(
                            selectedTimeline.contract_amount * 0.9
                          ).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </span>
                      </Typography>
                    </OfferBreakdown>
                  ) : (
                    <Box sx={{ padding: "10px" }}>
                      <Typography sx={{ fontSize: "12px", fontWeight: "400" }}>
                        {t("collaborationProject")}
                      </Typography>
                    </Box>
                  )}
                  {(selectedTimeline?.status === "pending" ||
                    selectedTimeline?.status === "counter") &&
                    isApplicant && (
                      <Box
                        sx={{
                          display: "flex",
                          gap: 2,
                          mt: 4,
                          justifyContent: "center",
                        }}
                      >
                        <Box
                          sx={{
                            opacity: showAcceptButton ? 1 : 0,
                            transform: showAcceptButton
                              ? "scale(1)"
                              : "scale(0.8)",
                            maxWidth: showAcceptButton ? "200px" : "0px",
                            transition: "all 0.8s ease-in-out",
                            overflow: "hidden",
                          }}
                        >
                          <Button
                            variant="contained"
                            sx={{
                              backgroundColor: "#9f7fe3",
                              fontWeight: "400",
                              color: "white",
                              borderRadius: "16px",
                              fontSize: "10px",
                              padding: "2px 16px !important",
                              boxShadow: "none",
                              height: "30px",
                              "&:hover": {
                                boxShadow: "none",
                              },
                            }}
                            onClick={() => {
                              setShowAccept(true);
                              setShowCounterButton(false);
                              setShowRejectButton(false);
                            }}
                          >
                            {projectType === "paid"
                              ? t("accept")
                              : t("approve")}
                          </Button>
                        </Box>
                        {projectType === "paid" && (
                          <>
                            <Box
                              sx={{
                                opacity: showCounterButton ? 1 : 0,
                                transform: showCounterButton
                                  ? "scale(1)"
                                  : "scale(0.8)",
                                maxWidth: showCounterButton ? "200px" : "0px",
                                transition: "all 0.8s ease-in-out",
                                overflow: "hidden",
                              }}
                            >
                              <Button
                                variant="contained"
                                sx={{
                                  backgroundColor: "#ffffff",
                                  border: "1px solid #9f7fe3",
                                  color: "#9f7fe3",
                                  fontWeight: "400",
                                  borderRadius: "16px",
                                  fontSize: "10px",
                                  boxShadow: "none",
                                  padding: "2px 16px !important",
                                  height: "30px",
                                  whiteSpace: "nowrap",
                                  "&:hover": {
                                    backgroundColor: "#ffffff",
                                    border: "1px solid #9f7fe3",
                                    color: "#9f7fe3",
                                    boxShadow: "none",
                                  },
                                }}
                                onClick={() => {
                                  setShowCounter(true);
                                  setShowAcceptButton(false);
                                  setShowRejectButton(false);
                                }}
                              >
                                {t("counter")}
                              </Button>
                            </Box>
                          </>
                        )}
                        <Box
                          sx={{
                            opacity: showRejectButton ? 1 : 0,
                            transform: showRejectButton
                              ? "scale(1)"
                              : "scale(0.8)",
                            maxWidth: showRejectButton ? "200px" : "0px",
                            transition: "all 0.8s ease-in-out",
                            overflow: "hidden",
                          }}
                        >
                          <Button
                            variant="contained"
                            sx={{
                              backgroundColor: "#ffffff",
                              fontWeight: "400",
                              border: "1px solid #ff4444",
                              color: "#ff4444",
                              borderRadius: "16px",
                              fontSize: "10px",
                              boxShadow: "none",
                              padding: "2px 16px !important",
                              height: "30px",
                              whiteSpace: "nowrap",
                              "&:hover": {
                                backgroundColor: "#ffffff",
                                border: "1px solid #ff4444",
                                color: "#ff4444",
                                boxShadow: "none",
                              },
                            }}
                            onClick={() => {
                              setShowReject(true);
                              setShowAcceptButton(false);
                              setShowCounterButton(false);
                            }}
                          >
                            {t("reject")}
                          </Button>
                        </Box>
                      </Box>
                    )}
                  {showAccept && isApplicant && (
                    <Box
                      sx={{
                        padding: "16px",
                        display: "flex",
                        gap: 2,
                        alignItems: "center",
                        mt: 2,
                        backgroundColor: "#f5f5f5",
                        borderRadius: "16px",
                      }}
                    >
                      <Typography sx={{ fontSize: "12px", fontWeight: "400" }}>
                        {projectType === "paid"
                          ? t("extendOfferConfirmation")
                          : t("approveCollaborationConfirmation")}
                      </Typography>

                      <Button
                        variant="contained"
                        sx={{
                          backgroundColor: "#000000",
                          fontWeight: "400",
                          color: "#ffffff",
                          boxShadow: "none",
                          borderRadius: "16px",
                          fontSize: "10px",
                          padding: "2px 10px !important",
                          height: "30px",
                          "&:hover": {
                            backgroundColor: "#333333",
                            color: "#ffffff",
                            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                          },
                        }}
                        onClick={() => {
                          setShowAccept(false);
                          setShowCounterButton(true);
                          setShowRejectButton(true);
                          reviseOffer();
                        }}
                      >
                        Yes
                      </Button>
                      <Button
                        variant="contained"
                        sx={{
                          backgroundColor: "#ffffff",
                          color: "black",
                          fontWeight: "400",
                          border: "1px solid #000000",
                          boxShadow: "none",
                          borderRadius: "16px",
                          fontSize: "10px",
                          padding: "2px 10px !important",
                          height: "30px",
                          "&:hover": {
                            backgroundColor: "#ffffff",
                            border: "1px solid #000000",
                            color: "black",
                          },
                        }}
                        onClick={() => {
                          setShowAccept(false);
                          setShowCounterButton(true);
                          setShowRejectButton(true);
                        }}
                      >
                        No
                      </Button>
                    </Box>
                  )}

                  {showCounter && isApplicant && (
                    <Box
                      sx={{
                        padding: "16px",
                        display: "flex",
                        gap: 2,
                        alignItems: "center",
                        mt: 2,
                        backgroundColor: "#f5f5f5",
                        borderRadius: "16px",
                        flexDirection: showCounterAmountField
                          ? "column"
                          : "row",
                      }}
                    >
                      {!showCounterAmountField ? (
                        <>
                          <Typography
                            sx={{ fontSize: "12px", fontWeight: "400" }}
                          >
                            Do you wish to counter this offer?
                          </Typography>

                          <Button
                            variant="contained"
                            sx={{
                              backgroundColor: "#000000",
                              fontWeight: "400",
                              color: "#ffffff",
                              boxShadow: "none",
                              borderRadius: "16px",
                              fontSize: "10px",
                              padding: "2px 10px !important",
                              height: "30px",
                              "&:hover": {
                                backgroundColor: "#333333",
                                color: "#ffffff",
                                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                              },
                            }}
                            onClick={() => {
                              setShowCounterAmountField(true);
                              setTimeout(() => {
                                scrollToBottom();
                              }, 200);
                            }}
                          >
                            Yes
                          </Button>
                          <Button
                            variant="contained"
                            sx={{
                              backgroundColor: "#ffffff",
                              color: "black",
                              fontWeight: "400",
                              border: "1px solid #000000",
                              boxShadow: "none",
                              borderRadius: "16px",
                              fontSize: "10px",
                              padding: "2px 10px !important",
                              height: "30px",
                              "&:hover": {
                                backgroundColor: "#ffffff",
                                border: "1px solid #000000",
                                color: "black",
                              },
                            }}
                            onClick={() => {
                              setShowCounter(false);
                              setShowAcceptButton(true);
                              setShowRejectButton(true);
                              setShowCounterAmountField(false);
                              setCounterAmount("");
                            }}
                          >
                            No
                          </Button>
                        </>
                      ) : (
                        <>
                          <Typography
                            sx={{ fontSize: "14px", fontWeight: "500", mb: 2 }}
                          >
                            Enter counter offer amount:
                          </Typography>
                          <TextField
                            autoFocus
                            fullWidth
                            type="number"
                            value={counterAmount}
                            onChange={(e) => setCounterAmount(e.target.value)}
                            InputProps={{
                              startAdornment: "$",
                              sx: {
                                borderRadius: "12px",
                                fontSize: "14px",
                              },
                            }}
                            sx={{ mb: 2 }}
                          />
                          <Box
                            sx={{
                              display: "flex",
                              gap: 2,
                              width: "100%",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Button
                              variant="contained"
                              sx={{
                                backgroundColor: "#000000",
                                fontWeight: "400",
                                color: "#ffffff",
                                boxShadow: "none",
                                borderRadius: "16px",
                                fontSize: "10px",
                                padding: "2px 10px !important",
                                height: "30px",
                                "&:hover": {
                                  backgroundColor: "#333333",
                                  color: "#ffffff",
                                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                                },
                              }}
                              onClick={() => {
                                handleCounterOffer();
                              }}
                            >
                              Submit
                            </Button>
                            <Button
                              variant="contained"
                              sx={{
                                backgroundColor: "#ffffff",
                                color: "black",
                                fontWeight: "400",
                                border: "1px solid #000000",
                                boxShadow: "none",
                                borderRadius: "16px",
                                fontSize: "10px",
                                padding: "2px 10px !important",
                                height: "30px",
                                "&:hover": {
                                  backgroundColor: "#ffffff",
                                  border: "1px solid #000000",
                                  color: "black",
                                },
                              }}
                              onClick={() => {
                                setShowCounter(false);
                                setShowAcceptButton(true);
                                setShowRejectButton(true);
                                setShowCounterAmountField(false);
                                setCounterAmount("");
                              }}
                            >
                              {showCounterAmountField ? "Cancel" : "No"}
                            </Button>
                          </Box>
                        </>
                      )}
                    </Box>
                  )}
                  {showReject && isApplicant && (
                    <Box
                      sx={{
                        padding: "16px",
                        display: "flex",
                        gap: 2,
                        alignItems: "center",
                        mt: 2,
                        backgroundColor: "#f5f5f5",
                        borderRadius: "16px",
                      }}
                    >
                      <Typography sx={{ fontSize: "12px", fontWeight: "400" }}>
                        Do you wish to reject this offer?
                      </Typography>

                      <Button
                        variant="contained"
                        sx={{
                          backgroundColor: "#000000",
                          fontWeight: "400",
                          color: "#ffffff",
                          boxShadow: "none",
                          borderRadius: "16px",
                          fontSize: "10px",
                          padding: "2px 10px !important",
                          height: "30px",
                          "&:hover": {
                            backgroundColor: "#333333",
                            color: "#ffffff",
                            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                          },
                        }}
                        onClick={() => {
                          setShowReject(false);
                          setShowAcceptButton(true);
                          setShowCounterButton(true);
                          handleRejectOffer();
                        }}
                      >
                        Yes
                      </Button>
                      <Button
                        variant="contained"
                        sx={{
                          backgroundColor: "#ffffff",
                          color: "black",
                          fontWeight: "400",
                          border: "1px solid #000000",
                          boxShadow: "none",
                          borderRadius: "16px",
                          fontSize: "10px",
                          padding: "2px 10px !important",
                          height: "30px",
                          "&:hover": {
                            backgroundColor: "#ffffff",
                            border: "1px solid #000000",
                            color: "black",
                          },
                        }}
                        onClick={() => {
                          setShowReject(false);
                          setShowAcceptButton(true);
                          setShowCounterButton(true);
                        }}
                      >
                        No
                      </Button>
                    </Box>
                  )}
                </StepContent>
              </AnimatedStep>

              <AnimatedStep
                completed={
                  getActiveStep(selectedTimeline?.status) > 2 ||
                  selectedTimeline?.status === "hired"
                }
                expanded={expandedSteps.includes(2)}
              >
                <StepLabel
                  onClick={() => {
                    if (getActiveStep(selectedTimeline?.status) >= 2) {
                      setExpandedSteps((prev) =>
                        prev.includes(2)
                          ? prev.filter((step) => step !== 2)
                          : [...prev, 2]
                      );
                    }
                  }}
                >
                  Contract Agreement
                </StepLabel>
                <StepContent>
                  {selectedTimeline?.status === "hired" && (
                    <Box sx={{ padding: "10px" }}>
                      <Typography sx={{ fontSize: "12px", fontWeight: "300" }}>
                        Contract accepted on{" "}
                        {new Date(
                          selectedTimeline?.accepted_date
                        ).toLocaleDateString()}
                      </Typography>
                    </Box>
                  )}
                </StepContent>
              </AnimatedStep>
            </ColoredStepper>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleTimelineDialogClose}
            variant="contained"
            sx={{
              backgroundColor: "#ffffff",
              border: "1px solid #9f7fe3",
              color: "#9f7fe3",
              fontWeight: "400",
              borderRadius: "16px",
              fontSize: "10px",
              height: "30px",
              boxShadow: "none",
              padding: "8px 24px",
              "&:hover": {
                backgroundColor: "#ffffff",
                border: "1px solid #9f7fe3",
                color: "#9f7fe3",
              },
            }}
          >
            {t("close")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ViewSubmissions;
