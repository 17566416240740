// src/index.js
import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { initI18n } from "./i18n";
import { LicenseInfo } from "@mui/x-license";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY);

// let map;

// window.initMap = function () {
//   console.log("initMap function called");
//   if (window.google && window.google.maps) {
//     console.log("Google Maps library loaded");
//     const { Map } = window.google.maps;

//     map = new Map(document.getElementById("map"), {
//       center: { lat: -34.397, lng: 150.644 },
//       zoom: 8,
//     });

//     console.log("Map initialized:", map);
//   } else {
//     console.error("Google Maps library not loaded.");
//   }
// };

// const loadGoogleMaps = () => {
//   console.log("Creating Google Maps API script element");
//   const script = document.createElement("script");
//   script.src =
//     "https://maps.googleapis.com/maps/api/js?key=AIzaSyCo8gi507rCjvSTOAsPFQbwqhdaYP80Ook&libraries=places&callback=initMap";
//   script.async = true;
//   script.onload = () => console.log("Google Maps script loaded successfully");
//   script.onerror = () => console.error("Google Maps script failed to load");
//   document.head.appendChild(script);
// };

const renderApp = () => {
  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(
    <Suspense fallback={<div>Loading...</div>}>
      <I18nextProvider i18n={i18n}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </I18nextProvider>
    </Suspense>
  );

  // document.addEventListener("DOMContentLoaded", () => {
  //   console.log("DOMContentLoaded event fired");
  //   loadGoogleMaps();
  // });
};

initI18n().then(() => {
  renderApp();
});

reportWebVitals();
