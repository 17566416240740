import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { useTranslation } from "react-i18next";
import { supabase } from "./SupabaseClient";
import { loadConnectAndInitialize } from "@stripe/connect-js";
import {
  ConnectPayments,
  ConnectComponentsProvider,
  ConnectAccountManagement,
  ConnectAccountOnboarding,
  ConnectPayouts,
} from "@stripe/react-connect-js";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

const PaymentSettings = () => {
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const { t } = useTranslation();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [accountId, setAccountId] = useState(false);
  const [stripeConnectInstance, setStripeConnectInstance] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [loginUrl, setLoginUrl] = useState(null);
  const [needsOnboarding, setNeedsOnboarding] = useState(false);

  const initializeConnect = async (accountId = null) => {
    try {
      const connectInstance = await loadConnectAndInitialize({
        publishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
        fetchClientSecret: async () => {
          try {
            const response = await fetch(
              "https://54rr0rbk9h.execute-api.us-east-1.amazonaws.com/prod/stripe/create-account-session",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  accountId,
                }),
              }
            );

            const data = await response.json();

            if (!response.ok) {
              throw new Error(data.error || "Failed to fetch client secret");
            }

            return data.clientSecret;
          } catch (error) {
            console.error("Error fetching client secret:", error);
            throw error;
          }
        },
        appearance: {
          overlays: "dialog",
          variables: {
            colorPrimary: "#9F7FE3",
            buttonPrimaryColorText: "#FFFFFF",
            spacingUnit: "10px",
          },
        },
      });
      setStripeConnectInstance(connectInstance);
    } catch (error) {
      console.error("Error initializing Connect:", error);
      setError(true);
    }
  };

  const getUser = async () => {
    try {
      const { data, error } = await supabase
        .from("Users")
        .select("stripe_id")
        .eq("id", currentUser.sub);

      if (error) throw error;

      if (data && data[0]?.stripe_id) {
        setAccountId(data[0].stripe_id);
        await initializeConnect(data[0].stripe_id);
      } else {
        // Initialize Connect without an account ID for new users
        await initializeConnect();
      }
    } catch (error) {
      console.error("Error fetching user:", error);
      setError(true);
    }
  };

  const fetchLoginUrl = async () => {
    try {
      const response = await fetch(
        "https://54rr0rbk9h.execute-api.us-east-1.amazonaws.com/prod/stripe/create-login-link",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            accountId,
          }),
        }
      );

      const data = await response.json();
      if (!response.ok) {
        if (data.error?.includes("not completed onboarding")) {
          setNeedsOnboarding(true);
          return;
        }
        throw new Error(data.error || "Failed to create login link");
      }

      setLoginUrl(data.url);
    } catch (error) {
      console.error("Error creating login link:", error);
      setError(true);
    }
  };

  useEffect(() => {
    getUser();
    if (accountId) {
      fetchLoginUrl();
    }
  }, [accountId]);

  const updateStripeId = async (id) => {
    const { error } = await supabase
      .from("Users")
      .update({ stripe_id: id })
      .eq("id", currentUser.sub);

    if (error) {
      console.error("Error updating Stripe ID:", error);
      setError(true);
      return false;
    }
    return true;
  };

  const authenticateAccount = () => {
    return loginUrl;
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        maxWidth: "1200px",
        gap: "20px",
        margin: "0 auto",
        padding: "0px 20px 0px 20px",
        minHeight: "100vh",
        alignItems: "center",
      }}
    >
      {loading && <div>Loading...</div>}
      {error && (
        <Typography color="error" align="center">
          Something went wrong. Please try again.
        </Typography>
      )}

      {stripeConnectInstance && (
        <ConnectComponentsProvider
          connectInstance={stripeConnectInstance}
          authenticateAccount={authenticateAccount}
        >
          {!accountId || needsOnboarding ? (
            // Show onboarding for new users or incomplete accounts
            <Box sx={{ width: "100%", py: 4, textAlign: "center" }}>
              <Typography variant="h6" gutterBottom align="center">
                {t("Complete Your Account Setup")}
              </Typography>
              <ConnectAccountOnboarding
                onComplete={async (result) => {
                  if (result.accountId) {
                    await updateStripeId(result.accountId);
                    setAccountId(result.accountId);
                    setNeedsOnboarding(false);
                  }
                }}
              />
            </Box>
          ) : (
            <>
              {/* Navigation Tabs */}
              <Box
                sx={{
                  display: "flex",
                  gap: 4,
                  justifyContent: "center",
                }}
              >
                <Typography
                  onClick={() => setActiveTab(0)}
                  sx={{
                    cursor: "pointer",
                    position: "relative",
                    padding: "6px 8px 6px 8px",
                    fontSize: "14px",
                    fontWeight: activeTab === 0 ? "400" : "300",
                    transition: "all 0.3s ease",
                    opacity: activeTab === 0 ? 1 : 0.7,
                    transform: activeTab === 0 ? "translateY(-2px)" : "none",
                    "&:hover": {
                      opacity: 1,
                      transform: "translateY(-2px)",
                      "&::after": { width: "100%" },
                    },
                    "&::after": {
                      content: '""',
                      position: "absolute",
                      bottom: 0,
                      left: "50%",
                      transform: "translateX(-50%)",
                      height: "2px",
                      width: activeTab === 0 ? "100%" : "0",
                      backgroundColor: "#9F7FE3",
                      transition: "width 0.8s ease",
                    },
                  }}
                >
                  {t("Overview")}
                </Typography>
                {/* ... other tabs ... */}
              </Box>

              {/* Dashboard Grid Layout */}
              <Grid container spacing={3}>
                {/* Payouts Section */}
                <Grid item xs={12}>
                  <Card
                    sx={{
                      height: "100%",
                      boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                      border: "1px solid rgba(0,0,0,0.08)",
                      borderRadius: "10px",
                    }}
                  >
                    <CardContent>
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{ fontWeight: "400", marginBottom: "20px" }}
                      >
                        {t("Payouts Overview")}
                      </Typography>
                      <ConnectPayouts />
                    </CardContent>
                  </Card>
                </Grid>
                {/* Payments Section */}
                <Grid item xs={12} md={6}>
                  <Card
                    sx={{
                      height: "100%",
                      boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                      border: "1px solid rgba(0,0,0,0.08)",
                      borderRadius: "10px",
                    }}
                  >
                    <CardContent>
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{ fontWeight: "400", marginBottom: "20px" }}
                      >
                        {t("Recent Payments")}
                      </Typography>
                      <ConnectPayments />
                    </CardContent>
                  </Card>
                </Grid>

                {/* Account Management Section */}
                <Grid item xs={12} md={6}>
                  <Card
                    sx={{
                      height: "100%",
                      boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                      border: "1px solid rgba(0,0,0,0.08)",
                      borderRadius: "10px",
                    }}
                  >
                    <CardContent>
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{ fontWeight: "400", marginBottom: "20px" }}
                      >
                        {t("Account Management")}
                      </Typography>
                      <ConnectAccountManagement
                        collectionOptions={{
                          fields: "eventually_due",
                          futureRequirements: "include",
                        }}
                      />
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </>
          )}
        </ConnectComponentsProvider>
      )}
    </Box>
  );
};

export default PaymentSettings;
