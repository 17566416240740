import React from "react";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import Divider from "@mui/material/Divider";
const Feedback = () => {
  const { i18n } = useTranslation();
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box sx={{ flexGrow: 1, bgcolor: "#ffffff" }}>
      {i18n.language === "ko" ? (
        <div className="misc-text-container">
          <div
            style={{
              fontSize: "28px",
              fontWeight: "500",
            }}
          >
            피드백
          </div>
          <div style={{ fontSize: "10px", color: "#808080" }}>
            2024년 12월 23일 시행
          </div>
          <Divider sx={{ marginBottom: "1rem" }} />
          <div style={{ fontSize: "12px", lineHeight: "2" }}>
            Togather는 사용자 여러분의 의견을 소중히 여기며, 더 나은 경험을
            제공하기 위해 노력하고 있습니다. 제안, 질문, 또는 우려 사항이
            있으시다면 언제든지{" "}
            <a href="mailto:info@createtogather.com">info@createtogather.com</a>
            으로 연락해 주십시오.{" "}
            <strong>
              피드백을 제출함으로써 귀하는 다음에 동의하는 것으로 간주됩니다:
            </strong>
            <ul style={{ listStyle: "disc", paddingLeft: "20px" }}>
              <li>
                해당 피드백을 공유할 권리가 있으며, 기밀 또는 독점 정보가
                포함되어 있지 않습니다.
              </li>
              <li>피드백은 비기밀적 형태로 제공됩니다.</li>
              <li>
                Togather는 귀하에게 어떠한 의무나 대가도 지불하지 않고 자유롭게
                해당 피드백을 사용, 수정, 또는 서비스에 반영할 수 있습니다.
              </li>
            </ul>
            저희 팀은 모든 피드백을 신중히 검토하며, 이를 바탕으로 커뮤니티
            전체에 도움이 되도록 플랫폼을 개선하고 있습니다. 다만, 보내주신 모든
            의견에 답변을 드리거나 즉시 구현해 드리는 것은 어려울 수 있으므로 이
            점 양해 부탁드립니다.
          </div>
        </div>
      ) : (
        <div className="misc-text-container">
          <div
            style={{
              fontSize: "28px",
              fontWeight: "500",
            }}
          >
            Feedback
          </div>
          <div style={{ fontSize: "10px", color: "#808080" }}>
            Effective December 23, 2024
          </div>
          <Divider sx={{ marginBottom: "1rem" }} />
          <div style={{ fontSize: "12px", lineHeight: "2" }}>
            We value your feedback and strive to improve your experience with
            Togather. Whether you have suggestions, questions, or concerns,
            please reach out to us at{" "}
            <a href="mailto:info@createtogather.com">info@createtogather.com</a>
            . <strong>By submitting any feedback, you agree that:</strong>
            <ul style={{ listStyle: "disc", paddingLeft: "20px" }}>
              <li>
                You have the right to share it, and it contains no confidential
                or proprietary information.
              </li>
              <li>It is provided on a non-confidential basis.</li>
              <li>
                Togather may freely use, modify, or incorporate the feedback
                into our Services without any obligation or compensation to you.
              </li>
            </ul>
            Our team carefully reviews all submissions and uses your insights to
            enhance our platform for the entire community. Please understand
            that while we appreciate your feedback, we may not be able to
            respond to each submission or implement every suggestion.
          </div>
        </div>
      )}
    </Box>
  );
};

export default Feedback;
