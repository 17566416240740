import React, { useEffect, useContext } from "react";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";
import ChatIcon from "@mui/icons-material/Chat";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import Search from "@mui/icons-material/Search";
import { checkUserSignedIn } from "./AuthUtils";
import { supabase } from "./SupabaseClient";
import Grid from "@mui/material/Grid";
import { UserContext } from "./UserContext";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import { Typography, Divider } from "@mui/material";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
const defaultFilterOptions = createFilterOptions();

const TopAppBar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation(); // Get the current location
  const isChatPage = location.pathname === "/chat"; // Check if it's the login page
  const [anchorNotifs, setAnchorNotifs] = useState(null);
  const [unreadCount, setUnreadCount] = useState(0);
  const open = Boolean(anchorNotifs);
  const handleClick = (event) => {
    setAnchorNotifs(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorNotifs(null);
  };
  const [notifications, setNotifications] = useState([]);
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const [inputValue, setInputValue] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const { user, loading } = useContext(UserContext);

  const showOptions = dropdownOpen && inputValue.length > 0;

  const handleOptionSelected = (event, value) => {
    if (value) {
      // Assuming `value` has a property that determines the navigation path
      navigate(`/project/${value.id}`);
    }
  };

  const navigateToHome = () => {
    navigate("/");
  };

  const navigateToChat = () => {
    navigate("/chat");
  };

  const navigateToSettings = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
    navigate("/settings");
  };

  // const navigateToProfile = () => {
  //   setAnchorEl(null);
  //   handleMobileMenuClose();
  //   navigate(`/profile/${currentUser.sub}`);
  // };

  const navigateToMyProjects = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
    navigate("/projects");
  };

  const navigateToMyFavorites = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
    navigate("/favorites");
  };

  const navigateToProposals = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
    navigate("/proposals");
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleSignOut = async () => {
    const { error } = await supabase.auth.signOut();
    localStorage.removeItem("user");
    window.dispatchEvent(new Event("userLogout"));

    navigate("/login");
  };

  const handleSignIn = () => {
    navigate("/login");
  };

  const navigateToCreateProjectPage = () => {
    navigate("/createproject");
  };

  const navigateToPayments = () => {
    navigate("/payments");
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = localStorage.getItem("user") ? (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      autoFocus={false}
      disableAutoFocusItem
      sx={{
        "& .MuiPaper-root": {
          borderRadius: "16px",
          marginTop: "12px",
          minWidth: 320,
          boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.08)",
          padding: "16px",
        },
      }}
    >
      <MenuItem
        onClick={navigateToMyProjects}
        sx={{ py: 2, borderRadius: "16px" }}
      >
        <FolderOutlinedIcon sx={{ mr: 2, color: "text.secondary" }} />
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 500, mb: 0.5 }}>
            {t("projects")}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ fontSize: "13px" }}
          >
            {t("projectsNav")}
          </Typography>
        </Box>
      </MenuItem>

      {user && user.verified_manager && (
        <MenuItem
          onClick={navigateToProposals}
          sx={{ py: 2, borderRadius: "16px" }}
        >
          <DescriptionOutlinedIcon sx={{ mr: 2, color: "text.secondary" }} />
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 500, mb: 0.5 }}>
              {t("proposals")}
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ fontSize: "13px" }}
            >
              {t("proposalsNav")}
            </Typography>
          </Box>
        </MenuItem>
      )}

      <MenuItem
        onClick={navigateToPayments}
        sx={{ py: 2, borderRadius: "16px" }}
      >
        <PaymentsOutlinedIcon sx={{ mr: 2, color: "text.secondary" }} />
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 500, mb: 0.5 }}>
            {t("payments")}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ fontSize: "13px" }}
          >
            {t("paymentsNav")}
          </Typography>
        </Box>
      </MenuItem>

      <MenuItem
        onClick={navigateToSettings}
        sx={{ py: 2, borderRadius: "16px" }}
      >
        <SettingsOutlinedIcon sx={{ mr: 2, color: "text.secondary" }} />
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 500, mb: 0.5 }}>
            {t("account")}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ fontSize: "13px" }}
          >
            {t("accountNav")}
          </Typography>
        </Box>
      </MenuItem>

      <Divider />

      <MenuItem
        onClick={handleSignOut}
        sx={{
          py: 2,
          color: "error.main",
          borderRadius: "16px",
        }}
      >
        <LogoutOutlinedIcon sx={{ mr: 2 }} />
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
            {t("signOut")}
          </Typography>
        </Box>
      </MenuItem>
    </Menu>
  ) : (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleSignIn}>{t("signIn")}</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = localStorage.getItem("user") ? (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="error">
            <ChatIcon onClick={navigateToChat} />
          </Badge>
        </IconButton>
        <p>{t("messages")}</p>
      </MenuItem>
      {/* <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge badgeContent={17} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem> */}
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>{t("profile")}</p>
      </MenuItem>
    </Menu>
  ) : (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>{t("profile")}</p>
      </MenuItem>
    </Menu>
  );

  // Add useEffect to handle user changes
  useEffect(() => {
    const currentUser = JSON.parse(localStorage.getItem("user"));
    if (!currentUser && anchorEl) {
      handleMenuClose(); // Close menu if user logs out
    }
  }, [user]); // Add user as dependency to react to changes

  return (
    <React.Fragment>
      <AppBar
        position="static"
        sx={{
          backgroundColor: "#ffffff",
          boxShadow: "none",
        }}
      >
        <Toolbar
          sx={{ height: "auto", paddingTop: "20px", paddingBottom: "5px" }}
        >
          <Grid
            container
            spacing={2}
            sx={{ ml: "auto", mr: "auto", maxWidth: "1280px" }}
          >
            <Grid
              item
              xs={6}
              md={6}
              sx={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
                display: { xs: "flex", md: "flex" },
                padding: "0px !important",
              }}
            >
              <img
                alt="togather text logo"
                onClick={navigateToHome}
                src="/images/togather_logo.png"
                className="text-logo"
              ></img>
            </Grid>

            <Grid
              item
              xs={6}
              md={6}
              sx={{
                display: "flex",
                justifyContent: "right",
                alignItems: "center",
                padding: "0px !important",
              }}
            >
              {/* <Autocomplete
                disablePortal
                freeSolo
                ListboxProps={{
                  style: {
                    maxHeight: 100,
                    fontSize: "12px",
                  },
                }}
                options={[...projects]}
                filterOptions={(options, params) => {
                  const { inputValue } = params;
                  if (!inputValue) {
                    return [];
                  }
                  return defaultFilterOptions(options, params);
                }}
                onChange={handleOptionSelected} // Add the onChange event handler
                getOptionLabel={(option) => option.projectName || ""}
                onOpen={() => setDropdownOpen(true)}
                onClose={() => setDropdownOpen(false)}
                sx={{
                  width: "200px",
                  marginLeft: "20px",
                  "& .MuiInputBase-root": {
                    fontSize: "12px",
                    height: "40px",
                    backgroundColor: "#F2F2F2",
                    width: "100%",
                    padding: "2px 4px 4px 12px",
                    borderRadius: showOptions ? "20px 20px 0 0" : "40px", // Conditional border-radius
                  },
                  "& .Mui-focused fieldset": {
                    borderWidth: "0px !important", // Adjust border-width when focused
                  },
                  "& fieldset": {
                    borderWidth: "0px !important", // Adjust border-width when focused
                  },
                }}
                renderOption={(props, option) => {
                  return (
                    <Box component="li" {...props}>
                      {option.projectName}
                    </Box>
                  );
                }}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                renderInput={(renderInputParams) => (
                  <div
                    ref={renderInputParams.InputProps.ref}
                    style={{
                      alignItems: "center",
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <TextField
                      style={{ flex: 1 }}
                      InputProps={{
                        ...renderInputParams.InputProps,
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            sx={{ color: "#9F7FE3" }}
                          >
                            {" "}
                            <Search />{" "}
                          </InputAdornment>
                        ),
                      }}
                      placeholder="Search"
                      inputProps={{
                        ...renderInputParams.inputProps,
                      }}
                      InputLabelProps={{ style: { display: "none" } }}
                    />
                  </div>
                )}
              /> */}
              <Box
                sx={{
                  display: {
                    xs: "flex",
                    md: "flex",
                    paddingRight: "5px",
                    alignItems: "center",
                  },
                }}
              >
                <Button
                  onClick={navigateToCreateProjectPage}
                  variant="outlined"
                  className="create-project-btn"
                  startIcon={
                    <AddIcon
                      sx={{
                        color: "#9f7fe3",
                        fontSize: "12px",
                        width: "12px",
                        ".create-project-btn:hover &": {
                          color: "#ffffff",
                        },
                      }}
                    />
                  }
                >
                  {t("create")}{" "}
                  {/* <img
                    alt="add icon"
                    src="/images/add_icon.svg"
                    style={{ height: "14px" }}
                  /> */}
                </Button>
                {localStorage.getItem("user") && (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {" "}
                    <IconButton
                      size="large"
                      aria-label="show new mails"
                      onClick={navigateToChat}
                      id="notif-button"
                      aria-controls={open ? "notif-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                    >
                      <Badge badgeContent={unreadCount} color="error">
                        <ChatIcon sx={{ color: "#333333" }} />
                      </Badge>
                    </IconButton>
                    {/* <div>
                      <IconButton
                        size="large"
                        aria-label="show new notifications"
                        onClick={handleClick}
                      >
                        <Badge badgeContent={notificationsCount} color="error">
                          <NotificationsIcon sx={{ color: "#333333" }} />
                        </Badge>
                      </IconButton>
                      <Menu
                        id="notif-menu"
                        anchorEl={anchorNotifs}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        {notifications &&
                          notifications.map((notification) => {
                            return (
                              <MenuItem onClick={handleClose}>
                                {notification.content}
                              </MenuItem>
                            );
                          })}
                      </Menu>
                    </div> */}
                  </Box>
                )}

                <Box sx={{ display: { xs: "flex", md: "flex" } }}>
                  {localStorage.getItem("user") ? (
                    // Only show avatar and dropdown for logged in users
                    <Box
                      sx={{
                        display: { xs: "flex", md: "flex" },
                        alignItems: "center",
                      }}
                    >
                      <IconButton
                        size="large"
                        edge="end"
                        aria-label="account of current user"
                        aria-controls={menuId}
                        aria-haspopup="true"
                        onClick={handleProfileMenuOpen}
                        color="#ffffff"
                        sx={{
                          display: "flex",
                          gap: "4px",
                          padding: "8px",
                        }}
                      >
                        <Avatar
                          sx={{
                            bgcolor: "#bdbdbd",
                            width: 24,
                            height: 24,
                            border: "2px solid #fff",
                            boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                            transition: "transform 0.2s ease-in-out",
                            "&:hover": {
                              transform: "scale(1.05)",
                              cursor: "pointer",
                            },
                          }}
                          src={user?.profile_picture || null}
                        />
                      </IconButton>
                      <KeyboardArrowDownIcon
                        onClick={handleProfileMenuOpen}
                        sx={{
                          marginLeft: "6px",
                          "&:hover": {
                            cursor: "pointer",
                          },
                          color: "#333333",
                          fontSize: "20px",
                          transition: "transform 0.2s",
                          transform: isMenuOpen
                            ? "rotate(180deg)"
                            : "rotate(0)",
                        }}
                      />
                    </Box>
                  ) : (
                    // Show sign in button for logged out users
                    <Button
                      onClick={handleSignIn}
                      sx={{
                        backgroundColor: "#ffffff",
                        color: "black",
                        "&:hover": {
                          backgroundColor: "#f5f5f5",
                        },
                      }}
                    >
                      {t("signIn")}
                    </Button>
                  )}
                </Box>
              </Box>
              <Box sx={{ display: { xs: "none", md: "none" } }}>
                <IconButton
                  size="large"
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleMobileMenuOpen}
                  color="inherit"
                >
                  <MoreIcon />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </React.Fragment>
  );
};

export default TopAppBar;
