import React from "react";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import Divider from "@mui/material/Divider";

const PrivacyPolicy = () => {
  const { i18n } = useTranslation();
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box sx={{ flexGrow: 1, bgcolor: "#ffffff" }}>
      {i18n.language === "ko" ? (
        <div className="misc-text-container">
          <div
            style={{
              fontSize: "28px",
              fontWeight: "500",
            }}
          >
            개인정보 처리방침
          </div>
          <div style={{ fontSize: "10px", color: "#808080" }}>
            2024년 12월 23일 발효
          </div>
          <Divider />
          <div style={{ fontSize: "12px", lineHeight: 2 }}>
            <p>
              Togather에 오신 것을 환영합니다! 귀하의 개인정보 보호는 저희에게
              매우 중요합니다. 본 개인정보 처리방침(이하 “<strong>방침</strong>
              ”)은 Togather(이하 “<strong>당사</strong>,” “<strong>우리</strong>
              ,” “<strong>우리의</strong>”)가 플랫폼 및 서비스를(이하 “
              <strong>서비스</strong>”) 제공함에 있어 귀하(이하 “
              <strong>사용자</strong>” 또는 “<strong>귀하</strong>”)의 정보를
              어떻게 수집, 이용, 공유, 보호하는지 설명합니다. Togather를
              이용함으로써, 귀하는 본 방침 및 당사의{" "}
              <a href="/terms" target="_blank">
                이용약관
              </a>
              에 명시된 바에 따라 귀하의 정보를 수집·이용하는 데 동의하게
              됩니다.
            </p>

            <section>
              <h2>1. 정의</h2>
              <ul>
                <li>
                  <strong>“개인정보”</strong>란 이름, 이메일 주소, 결제 정보처럼
                  귀하를 식별할 수 있는 정보를 의미합니다.
                </li>
                <li>
                  <strong>“비개인정보”</strong>는 귀하를 직접적으로 식별하지
                  않는 데이터(예: 기기 세부 정보, IP 주소, 사용 패턴 등)를
                  말합니다.
                </li>
                <li>
                  <strong>“서비스”</strong>란 당사가 제공하는 웹사이트 및 모바일
                  애플리케이션 등 모든 플랫폼 또는 서비스를 의미합니다.
                </li>
              </ul>
            </section>

            <section>
              <h2>2. 수집하는 정보</h2>
              <p>
                우리는 서비스 제공, 개선, 맞춤화를 위해 다양한 정보를
                수집합니다.
              </p>
              <ul>
                <li>
                  <strong>개인정보:</strong> 계정 생성 또는 서비스 이용 시
                  제공되는 이름, 이메일 주소, 연락처, 결제 정보 등이 포함됩니다.
                  법률(예: GDPR)에 따라 필요한 경우, 우리는 귀하의 동의, 당사의
                  정당한 이익, 또는 계약 이행을 위해 해당 정보를 처리합니다.
                </li>
                <li>
                  <strong>비개인정보:</strong> 기기 세부 정보, IP 주소, 사용
                  패턴 등 귀하를 직접 식별하지 않는 정보가 포함됩니다.
                </li>
                <li>
                  <strong>자동 수집(쿠키 &amp; 유사 기술):</strong> 쿠키, 웹
                  비콘, 유사 도구를 사용하여 브라우징 행동, 사용 동향 등을
                  자동으로 수집하여 사용자 경험을 최적화합니다.
                </li>
              </ul>
            </section>

            <section>
              <h2>3. 정보 이용 목적</h2>
              <p>
                당사는 다음의 목적으로 귀하의 정보를 이용합니다(각 목적별로,
                해당 지역법이 요구하는 경우 동의, 계약 이행, 정당한 이익 등에
                근거하여 처리).
              </p>
              <ul>
                <li>
                  <strong>서비스 제공:</strong> Togather 운영, 계정 관리, 거래
                  처리, 고객 지원 등.
                </li>
                <li>
                  <strong>개선 &amp; 맞춤화:</strong> 사용자 선호도 파악, 플랫폼
                  개선, 개인화된 사용자 경험 제공.
                </li>
                <li>
                  <strong>커뮤니케이션:</strong> 거래 관련 알림, 문의 대응,
                  업데이트, 프로모션, 정책 변경 등 안내.
                </li>
                <li>
                  <strong>마케팅 &amp; 광고(해당 시):</strong> 관련 법령이
                  허용하는 범위 내에서 귀하의 개인정보를 이용한 타겟 광고나
                  마케팅 커뮤니케이션 제공. 언제든지 수신 거부가 가능합니다.
                </li>
                <li>
                  <strong>보안 &amp; 사기 예방:</strong> 무단 접근 방지, 서비스
                  무결성 유지, 사기 방지 등을 위해 사용.
                </li>
              </ul>
            </section>

            <section>
              <h2>4. 정보 공유 방법</h2>
              <p>
                우리는 귀하의 개인정보를 매우 제한적으로 공유하며, 다음과 같은
                특정 상황에서만 공유합니다.
              </p>
              <ul>
                <li>
                  <strong>제3자 서비스 제공업체:</strong> 당사는 결제 처리,
                  클라우드 호스팅, 분석 등 서비스를 보조하는 신뢰할 수 있는
                  파트너들과 협력합니다. 이들은 본 방침 및 계약에 따라 제한된
                  목적으로만 정보를 사용하고 적절한 보호 조치를 취해야 합니다.
                </li>
                <li>
                  <strong>사업 양도:</strong> 합병, 인수, 자산 매각 등의
                  상황에서 사용자 정보가 양도될 수 있으며, 이 경우 사전에
                  고지하고 신규 주체에게도 유사한 수준의 개인정보 보호를
                  요구합니다.
                </li>
                <li>
                  <strong>법적 요구사항:</strong> 법적 의무 준수(예: 영장,
                  소환장 등)에 의해 정보를 공개해야 하거나, 당사의 권리 보호,
                  사용자 안전 확보를 위해 필요하다고 판단되는 경우.
                </li>
                <li>
                  <strong>사용자 동의:</strong> 귀하가 명시적으로 동의한 경우,
                  특정 목적을 위해 정보를 공유할 수 있습니다.
                </li>
              </ul>
            </section>

            <section>
              <h2>5. 귀하의 권리와 선택권</h2>
              <p>
                거주 국가나 지역에 따라, 귀하는 개인정보에 대한 다양한 권리와
                선택권을 가질 수 있습니다.
              </p>
              <ul>
                <li>
                  <strong>접근 &amp; 업데이트:</strong> 계정 설정을 통해 또는
                  당사에 연락하여 개인정보를 열람하거나 수정할 수 있습니다.
                </li>
                <li>
                  <strong>삭제 &amp; 데이터 이동:</strong> 법적·계약상의
                  요구사항을 제외하고, 귀하는 보유 정보를 삭제하거나 사본을
                  요청할 수 있습니다. 단, 일부 정보를 삭제할 경우 서비스 제공에
                  제한이 있을 수 있습니다.
                </li>
                <li>
                  <strong>수신 거부:</strong> 프로모션 이메일 내 구독 해지 링크,
                  또는 당사에 직접 연락함으로써 마케팅 관련 커뮤니케이션을
                  거부할 수 있습니다. 쿠키와 관련해서는 브라우저 설정을 통해
                  제어할 수 있으나, 이 경우 일부 기능이 제한될 수 있습니다.
                </li>
                <li>
                  <strong>CCPA/CPRA 권리(해당 시):</strong> 캘리포니아 거주자는
                  추가적인 권리(알 권리, 삭제 요청 권리, 특정 데이터 판매/공유
                  거부권 등)를 가질 수 있습니다. 권리 행사 요청은 당사에 문의해
                  주시기 바랍니다.
                </li>
              </ul>
            </section>

            <section>
              <h2>6. 쿠키 및 추적 기술</h2>
              <p>
                Togather는 필수, 분석, 맞춤화 쿠키를 사용합니다. 쿠키 동의가
                요구되는 지역(예: EU/영국)에서는 쿠키 배너 또는 설정 도구를
                제공하여 귀하가 동의를 관리할 수 있도록 합니다.
              </p>
              <ul>
                <li>
                  <strong>쿠키 유형:</strong> 서비스 기능 유지를 위한 필수 쿠키,
                  성능 측정을 위한 분석 쿠키, 개인화된 경험 제공을 위한 맞춤형
                  쿠키를 사용합니다.
                </li>
                <li>
                  <strong>쿠키 관리:</strong> 브라우저 설정을 통해 쿠키 동작을
                  제어할 수 있습니다. 쿠키를 차단할 경우 일부 서비스 기능이
                  제한될 수 있습니다.
                </li>
              </ul>
            </section>

            <section>
              <h2>7. 정보 보안</h2>
              <p>
                당사는 암호화, 안전한 접근 제어 등 업계 표준 보안 조치를
                활용하여 귀하의 데이터를 보호합니다. 그러나 어느 시스템도 100%
                안전하지는 않으며, 절대적 보안을 보장할 수 없습니다.
              </p>
              <p>
                <strong>사고 대응:</strong> 귀하의 개인정보가 침해되는 보안
                사고가 발생할 경우, 관련 법령에 따라 귀하 및/또는 해당 기관에
                지체 없이 통지할 것입니다.
              </p>
            </section>

            <section>
              <h2>8. 데이터 보관</h2>
              <p>
                본 방침에 명시된 목적을 달성하기 위해 필요한 기간 동안, 또는
                법령에서 정한 기간(또는 허용 기간)까지 귀하의 개인 데이터를
                보관합니다.
              </p>
              <p>
                <strong>보관 기준:</strong> 계정이 활성 상태인지 여부, 계약 의무
                이행, 법적 의무 준수 등의 요소에 따라 보관 기간이 결정됩니다.
              </p>
            </section>

            <section>
              <h2>9. 아동 개인정보 보호</h2>
              <p>
                Togather는 만 13세(또는 관련 법에서 정한 나이가 16세인 경우
                16세) 미만 아동의 개인정보를 고의로 수집하지 않습니다. 부모 동의
                없이 아동 정보를 수집한 사실을 인지하면, 신속히 해당 정보를
                삭제하기 위한 조치를 취합니다.
              </p>
            </section>

            <section>
              <h2>10. 국제 데이터 이전</h2>
              <p>
                귀하의 정보는 귀하의 거주 국가 외부로 이전 및 처리될 수
                있습니다. GDPR 등 관련 법령이 적용되는 경우, 당사는
                표준계약조항(Standard Contractual Clauses) 등 적절한 보호 장치를
                활용하여 유사 수준의 개인정보 보호를 보장합니다.
              </p>
            </section>

            <section>
              <h2>11. 본 개인정보 처리방침의 변경</h2>
              <p>
                당사는 업무 관행 또는 적용 법령의 변경사항을 반영하기 위해
                수시로 본 방침을 업데이트할 수 있습니다. 중요한 변경 사항이 있을
                경우, 이메일 또는 플랫폼 내 공지를 통해 알려드립니다. 최신
                정보를 확인하기 위해 본 방침을 정기적으로 검토하시기 바랍니다.
              </p>
            </section>

            <section>
              <h2>12. 문의하기</h2>
              <p>
                본 개인정보 처리방침 또는 당사의 데이터 처리 관행에 대해
                질문이나 우려 사항이 있으신 경우 아래로 문의해 주시기 바랍니다:
              </p>
              <p>
                <strong>이메일:</strong>{" "}
                <a href="mailto:info@createtogather.com">
                  info@createtogather.com
                </a>
              </p>
            </section>
          </div>
        </div>
      ) : (
        <div className="misc-text-container">
          <div
            style={{
              fontSize: "28px",
              fontWeight: "500",
            }}
          >
            Privacy Policy
          </div>
          <div style={{ fontSize: "10px", color: "#808080" }}>
            Effective December 23, 2024
          </div>
          <Divider />
          <div style={{ fontSize: "12px", lineHeight: 2 }}>
            <p>
              Welcome to Togather! Your privacy is important to us. This Privacy
              Policy (“<strong>Policy</strong>”) explains how Togather (“
              <strong>we</strong>,” “<strong>our</strong>,” or “
              <strong>us</strong>”) collects, uses, shares, and protects
              information about you (“<strong>User</strong>” or “
              <strong>you</strong>”) when you use our platform and services (the
              “<strong>Services</strong>”). By using Togather, you agree to the
              collection and use of your information as outlined in this Policy,
              as well as our{" "}
              <a href="/terms" target="_blank">
                Terms of Service
              </a>
              .
            </p>

            <section>
              <h2>1. Definitions</h2>
              <ul>
                <li>
                  <strong>“Personal Information”</strong> means information that
                  can reasonably identify you (e.g., name, email address,
                  payment details).
                </li>
                <li>
                  <strong>“Non-Personal Information”</strong> refers to data
                  that does not directly identify you, such as device details,
                  IP address, or usage patterns.
                </li>
                <li>
                  <strong>“Services”</strong> refers to any platform or service
                  we provide, including our website and mobile applications.
                </li>
              </ul>
            </section>

            <section>
              <h2>2. Information We Collect</h2>
              <p>
                We collect a range of information to provide, improve, and
                personalize our Services.
              </p>
              <ul>
                <li>
                  <strong>Personal Information:</strong> This may include your
                  name, email address, contact details, payment information, and
                  other data you provide when creating an account or using our
                  Services. Where required by law (e.g., GDPR), we rely on your
                  consent, our legitimate interests, or contractual necessity to
                  process this information.
                </li>
                <li>
                  <strong>Non-Personal Information:</strong> We also collect
                  information that does not directly identify you, such as
                  device details, IP addresses, and usage patterns.
                </li>
                <li>
                  <strong>
                    Automatic Collection (Cookies &amp; Similar Technologies):
                  </strong>{" "}
                  We use cookies, web beacons, and similar tools. These
                  technologies collect data about browsing behavior and usage
                  trends, helping us optimize your experience.
                </li>
              </ul>
            </section>

            <section>
              <h2>3. How We Use Your Information</h2>
              <p>
                We use your information for the following purposes (each
                processing is based on a legal basis such as consent,
                contractual necessity, or legitimate interest, as required by
                applicable law):
              </p>
              <ul>
                <li>
                  <strong>Providing Services:</strong> To operate Togather,
                  manage your account, process transactions, and offer customer
                  support.
                </li>
                <li>
                  <strong>Improving &amp; Personalizing:</strong> To understand
                  user preferences, enhance our platform, and tailor user
                  experiences.
                </li>
                <li>
                  <strong>Communication:</strong> To send transactional
                  communications, respond to inquiries, and inform you of
                  updates, promotions, or policy changes.
                </li>
                <li>
                  <strong>Marketing &amp; Advertising (If Applicable):</strong>{" "}
                  We may use Personal Information to provide you with targeted
                  advertising or marketing communications where permitted by
                  law. You can opt out of these communications at any time.
                </li>
                <li>
                  <strong>Security &amp; Fraud Prevention:</strong> To protect
                  our platform from unauthorized access, maintain service
                  integrity, and prevent fraud.
                </li>
              </ul>
            </section>

            <section>
              <h2>4. How We Share Your Information</h2>
              <p>
                We only share your information under specific circumstances and
                always with your privacy in mind:
              </p>
              <ul>
                <li>
                  <strong>Third-Party Service Providers:</strong> We work with
                  trusted partners (e.g., payment processors, cloud hosting,
                  analytics) who assist in providing our Services. These third
                  parties are contractually obligated to use your information
                  only for our specified purposes and to protect it in
                  accordance with this Policy.
                </li>
                <li>
                  <strong>Business Transfers:</strong> In the event of a merger,
                  acquisition, or asset sale, user information may be
                  transferred to the new entity. We will provide notice and
                  ensure the new entity adheres to a comparable level of privacy
                  protection.
                </li>
                <li>
                  <strong>Legal Requirements:</strong> We may disclose
                  information if required to do so by law or in response to
                  lawful requests (e.g., subpoenas), to protect our rights, or
                  to ensure the safety of our users and platform.
                </li>
                <li>
                  <strong>With Your Consent:</strong> Where you have explicitly
                  consented, we may share your information for other purposes.
                </li>
              </ul>
            </section>

            <section>
              <h2>5. Your Rights and Choices</h2>
              <p>
                Depending on your jurisdiction, you may have certain rights and
                choices regarding your personal data:
              </p>
              <ul>
                <li>
                  <strong>Access &amp; Update:</strong> You can review and
                  update your personal information through your account settings
                  or by contacting us.
                </li>
                <li>
                  <strong>Deletion &amp; Portability:</strong> You can request a
                  copy of your data or ask us to delete it, subject to legal or
                  contractual requirements. Please note that deleting certain
                  data may limit our ability to provide some Services.
                </li>
                <li>
                  <strong>Opt-Out:</strong> You can manage marketing preferences
                  via unsubscribe links in promotional emails or by contacting
                  us directly. For cookies, you can adjust your browser settings
                  to refuse certain cookies, but some features may not function
                  properly if you do so.
                </li>
                <li>
                  <strong>CCPA/CPRA Rights (If Applicable):</strong> California
                  residents may have additional rights, including the right to
                  know, delete, and opt out of certain data practices. Please
                  contact us to exercise these rights.
                </li>
              </ul>
            </section>

            <section>
              <h2>6. Cookies and Tracking Technologies</h2>
              <p>
                Togather uses essential, analytics, and personalization cookies.
                If you are in a jurisdiction that requires consent for cookies
                (e.g., EU/UK), we provide a cookie banner or settings tool to
                manage your preferences.
              </p>
              <ul>
                <li>
                  <strong>Types of Cookies:</strong> Essential cookies for
                  platform functionality, analytics cookies to measure
                  performance, and personalization cookies for tailored
                  experiences.
                </li>
                <li>
                  <strong>Managing Cookies:</strong> You can control cookie
                  behavior through your browser settings. Blocking cookies may
                  limit certain features of our Services.
                </li>
              </ul>
            </section>

            <section>
              <h2>7. Security of Your Information</h2>
              <p>
                We employ industry-standard measures (encryption, secure access
                controls) to protect your data. However, no system is 100%
                secure, and we cannot guarantee absolute security.
              </p>
              <p>
                <strong>Incident Response:</strong> In the event of a data
                breach affecting your personal information, we will notify you
                and/or applicable authorities in accordance with legal
                requirements.
              </p>
            </section>

            <section>
              <h2>8. Data Retention</h2>
              <p>
                We retain your personal data for as long as necessary to fulfill
                the purposes outlined in this Policy, unless a longer retention
                period is required or permitted by law.
              </p>
              <p>
                <strong>Retention Criteria:</strong> This may include the
                duration of your account’s active status, our contractual
                obligations, or compliance with legal obligations.
              </p>
            </section>

            <section>
              <h2>9. Children’s Privacy</h2>
              <p>
                Togather does not knowingly collect personal information from
                children under the age of 13 (or 16, as applicable by law). If
                we become aware of such collection without parental consent, we
                will take steps to delete the information promptly.
              </p>
            </section>

            <section>
              <h2>10. International Data Transfers</h2>
              <p>
                Your information may be transferred to and processed in
                countries other than your own. Where required by law (e.g.,
                GDPR), we utilize appropriate safeguards such as Standard
                Contractual Clauses or similar mechanisms to ensure a comparable
                level of data protection.
              </p>
            </section>

            <section>
              <h2>11. Changes to This Privacy Policy</h2>
              <p>
                We may update this Policy periodically to reflect changes in our
                practices or applicable laws. If changes are significant, we
                will provide notice via email or on our platform. You are
                encouraged to review this Policy from time to time for the
                latest information on our data practices.
              </p>
            </section>

            <section>
              <h2>12. Contact Us</h2>
              <p>
                If you have questions or concerns about this Privacy Policy or
                our data practices, please contact us at:
              </p>
              <p>
                <strong>Email:</strong>{" "}
                <a href="mailto:info@createtogather.com">
                  info@createtogather.com
                </a>
              </p>
            </section>
          </div>
        </div>
      )}
    </Box>
  );
};

export default PrivacyPolicy;
