import React from "react";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import Divider from "@mui/material/Divider";

const TermsOfService = () => {
  const { i18n } = useTranslation();
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box sx={{ flexGrow: 1, bgcolor: "#ffffff" }}>
      {i18n.language === "ko" ? (
        <div className="misc-text-container">
          <div
            style={{
              fontSize: "28px",
              fontWeight: "500",
            }}
          >
            Togather - 이용약관
          </div>
          <div style={{ fontSize: "10px", color: "#808080" }}>
            발효일: 2024년 12월 23일
          </div>
          <Divider />
          <div style={{ fontSize: "12px", lineHeight: 2 }}>
            <p>
              <strong>Togather</strong>(이하 “<em>당사</em>”, “<em>우리</em>”
              또는 “<em>우리의</em>”) 플랫폼(이하 “<strong>서비스</strong>”)에
              접속하거나 사용하는 경우, 본 이용약관(이하 “<strong>약관</strong>
              ”)에 동의하는 것으로 간주됩니다. 본 약관은{" "}
              <strong>Togather, Inc.</strong>( 이하 “<strong>Togather</strong>
              ”)와 귀하(이하 “<strong>귀하</strong>” 또는 “
              <strong>사용자</strong>”) 간에 체결되는 법적 구속력이 있는
              합의입니다. 본 약관에 동의하지 않으시면 Togather를 이용할 수
              없습니다.
            </p>

            <section>
              <h2>1. 약관의 수락</h2>
              <p>
                <strong>법적 구속력 있는 합의:</strong> 서비스를 등록, 접속 또는
                기타 방식으로 사용함으로써, 귀하는 본 약관을 읽고 이해했으며,
                이에 구속되는 것에 동의했음을 확인합니다.
              </p>
              <p>
                <strong>개정:</strong> Togather는 단독 재량으로 언제든지
                서비스의 일부를 수정, 중단 또는 폐지할 수 있습니다. 또한 본
                약관을 수시로 개정할 수 있습니다. 중요한 변경 사항이 있을 경우,
                당사는 웹사이트 공지 또는 이메일 등을 통해 안내해 드릴 수
                있습니다. 변경 사항이 발효된 후에도 서비스를 계속 이용하시면,
                변경된 약관에 동의하는 것으로 간주됩니다.
              </p>
            </section>

            <section>
              <h2>2. 서비스 설명</h2>
              <p>
                Togather는 <strong>클라이언트(Client)</strong>가 필요한
                프로젝트나 서비스를 게시하고,{" "}
                <strong>프로바이더(Provider)</strong>가 해당 프로젝트를 완료할
                수 있도록 지원하는 온라인 마켓플레이스 플랫폼을 제공합니다.{" "}
                <strong>
                  Togather는 클라이언트와 프로바이더 간 체결되는 계약의 당사자가
                  아니며
                </strong>
                , 양측이 서로를 발견하고 계약할 수 있도록 돕는{" "}
                <strong>중개 역할</strong>을 수행합니다. Togather는 다음과 같은
                권리를 보유합니다:
              </p>
              <ul>
                <li>
                  프로젝트 게시물 또는 사용자 계정을 거부, 삭제 또는 제한할 수
                  있음
                </li>
                <li>사용자의 서비스 접근을 제한 또는 중단할 수 있음</li>
              </ul>
            </section>

            <section>
              <h2>3. 사용자 계정</h2>
              <ul>
                <li>
                  <strong>이용 자격:</strong> 귀하는 만 18세 이상(또는 귀하
                  관할권에서의 성년 연령 이상)이어야 Togather를 이용할 수
                  있습니다. Togather는 본 약관이나 관련 법을 위반한다고 판단될
                  경우, 단독 재량에 따라 임의로 특정 개인의 서비스 접근을
                  제한하거나 종료할 수 있습니다.
                </li>
                <li>
                  <strong>계정 보안:</strong> 귀하는 계정 자격증명을 기밀로
                  유지할 책임이 있습니다. 계정 무단 사용이나 기타 보안 침해를
                  알게 되는 즉시 Togather에 통지해야 합니다.
                </li>
                <li>
                  <strong>계정 정보:</strong> 귀하는 등록 시 정확하고 최신의
                  정보를 제공하고, 이를 항상 업데이트해야 합니다. 부정확한
                  정보로 인해 발생하는 문제에 대해 Togather는 책임을 지지
                  않습니다.
                </li>
              </ul>
            </section>

            <section>
              <h2>4. 사용자 책임과 제한</h2>
              <p>귀하는 다음과 같은 행위를 하지 않을 것에 동의합니다:</p>
              <ul>
                <li>
                  <strong>법률 또는 권리 침해:</strong> 관련 법규, 규정 또는
                  타인의 권리(지적 재산권 포함)를 위반하는 행위
                </li>
                <li>
                  <strong>허위 정보 제공:</strong> 다른 사람이나 단체를
                  사칭하거나, 본인과의 관계를 허위로 기재하는 행위
                </li>
                <li>
                  <strong>서비스 방해:</strong> 바이러스, 스팸, 기타 악성 코드
                  등을 업로드하여 서비스에 지장을 주거나 손상 또는 방해를
                  야기하는 행위
                </li>
                <li>
                  <strong>리버스 엔지니어링 혹은 복제:</strong> Togather의 사전
                  서면 허가 없이 서비스를 역설계, 복제, 재판매 또는 무단으로
                  이용하려 하는 행위
                </li>
                <li>
                  <strong>보안 우회:</strong> 해킹, 패스워드 무단 추출 등 불법적
                  방법으로 서비스나 다른 계정 또는 시스템에 무단 접근을 시도하는
                  행위
                </li>
              </ul>
              <p>
                Togather는 (의무는 아니지만) 단독 재량으로 본 약관을 위반하는
                사용자에 대해 조사 및 법적 조치를 취할 수 있으며, 이를 위해
                콘텐츠 삭제, 계정 정지 또는 해지, 관련 법 집행 기관에 고발 등을
                실시할 수 있습니다.
              </p>
            </section>

            <section>
              <h2>5. 결제 및 프로젝트 조건</h2>
              <ul>
                <li>
                  <strong>프로젝트 게시 및 결제:</strong> 클라이언트는 정확한
                  프로젝트 설명을 제공하고, 그에 대해 약정된 금액을 지불할
                  의무가 있습니다. Togather를 통해 이루어지는 모든 결제는
                  최종적이며, 본 약관 또는 Togather가 서면으로 별도 동의한
                  경우를 제외하고 환불 대상이 아닙니다. 환불 요청은 Togather의
                  단독 재량에 따릅니다.
                </li>
                <li>
                  <strong>프로바이더 보수:</strong> 프로바이더는 프로젝트 완료
                  후 Togather의 플랫폼 수수료(“플랫폼 수수료”)를 공제한 금액을
                  지급 받습니다. 프로젝트 완료가 성공적으로 확인되고, 추가 승인
                  또는 검증 절차를 거친 뒤에 지급됩니다. Togather는 단독
                  재량으로 지급을 보류하거나 조정할 권리를 보유합니다.
                </li>
                <li>
                  <strong>환불 불가 수수료:</strong> 모든 플랫폼 수수료 및
                  서비스 수수료는 환불 불가입니다. 이는 프로젝트가 취소되거나
                  분쟁이 발생했거나 사용자 만족에 미치지 못한 경우에도
                  마찬가지입니다.
                </li>
              </ul>
            </section>

            <section>
              <h2>6. 수수료 및 결제</h2>
              <ul>
                <li>
                  <strong>플랫폼 수수료:</strong> Togather는 클라이언트와
                  프로바이더 간의 거래를 중개하는 대가로 수수료를 부과합니다.
                  해당 수수료의 금액 및 구조는 사전 고지 없이 언제든지 변경될 수
                  있습니다. 서비스를 이용함으로써, 귀하는 Togather가 플랫폼을
                  통해 처리되는 모든 결제에서 이 수수료를 공제할 수 있음을
                  승인합니다.
                </li>
                <li>
                  <strong>제3자 결제 처리업체:</strong> Togather는 Stripe,
                  PayPal 등의 제3자 결제 처리업체를 이용할 수 있으며, 귀하의
                  결제 이용은 해당 제3자가 정한 약관 및 수수료의 적용을 받을 수
                  있습니다. Togather는 이러한 제3자 서비스를 통해 발생하는 오류,
                  분쟁, 추가 수수료에 대해 책임지지 않습니다.
                </li>
                <li>
                  <strong>결제 승인:</strong> 결제 정보를 제공함으로써, 귀하는
                  해당 결제 수단을 사용할 법적 권리가 있음을 진술하고
                  보증합니다. 또한 서비스 이용과 관련하여 발생하는 모든 해당
                  수수료 또는 요금을 결제 수단으로 청구하는 것에 대해 Togather에
                  승인합니다.
                </li>
              </ul>
            </section>

            <section>
              <h2>7. 지적 재산권</h2>
              <p>
                Togather(상표, 서비스마크, 로고, 소프트웨어, 텍스트, 이미지,
                디자인 포함)와 관련된 모든 지적 재산권은 Togather 또는 라이선스
                허가자의 소유입니다. 본 약관에 명시된 경우를 제외하고,
                Togather의 사전 서면 동의 없이 Togather의 지적 재산권을 복제,
                배포, 2차적 저작물 작성, 그 밖의 용도로 활용할 수 없습니다.
              </p>
            </section>

            <section>
              <h2>8. 사용자 생성 콘텐츠</h2>
              <p>
                사용자는 프로젝트 설명, 이미지, 피드백 등(이하 “사용자
                콘텐츠”)을 서비스에 게시하거나 전송할 수 있습니다. 사용자
                콘텐츠를 제출함으로써, 귀하는 Togather에 대하여 해당 콘텐츠를 전
                세계적으로 사용, 복제, 수정, 배포할 수 있는 비독점적이며,
                로열티가 없고, 취소 불가능하며, 영구적이고, 재라이선스가 가능한
                권리를 부여합니다. Togather는 사용자 콘텐츠에 대해 책임을 지지
                않으며, 단독 재량으로 해당 콘텐츠를 삭제 또는 접근 제한할 수
                있는 권리가 있습니다.
              </p>
            </section>

            <section>
              <h2>9. 해지</h2>
              <ul>
                <li>
                  <strong>Togather에 의한 해지:</strong> Togather는 귀하가 본
                  약관을 위반하거나 사기성 행위나 불법 행위를 저지르거나 다른
                  사용자에게 피해를 준다고 판단되는 경우, 통지 여부와 관계없이
                  귀하의 계정이나 서비스 접근을 정지 또는 해지할 수 있습니다.
                </li>
                <li>
                  <strong>귀하에 의한 해지:</strong> 귀하는 언제든지{" "}
                  <a href="mailto:info@createtogather.com">
                    info@createtogather.com
                  </a>
                  으로 연락하여 계정을 해지할 수 있습니다. 해지 이전에 발생한
                  모든 수수료, 의무, 책임은 계속 유효합니다.
                </li>
                <li>
                  <strong>해지의 효과:</strong> 해지 시, 본 약관에 따라 귀하에게
                  부여된 모든 라이선스와 권리는 즉시 소멸됩니다. 수수료, 보증,
                  책임 제한, 면책, 분쟁 해결 등 존속이 의도된 조항은 계속
                  유효합니다.
                </li>
              </ul>
            </section>

            <section>
              <h2>10. 보증의 면책</h2>
              <p>
                관련 법이 허용하는 최대 한도 내에서, 본 서비스는 어떠한 종류의
                명시적, 묵시적, 법정 보증 없이 “있는 그대로” 및 “제공 가능한
                상태로” 제공됩니다. Togather는 상품성, 특정 목적에의 적합성,
                비침해성, 그리고 거래 관행이나 사용 등에 의해 발생하는 모든
                묵시적 보증을 명시적으로 부인합니다. 또한 서비스가 중단 없이,
                오류 없이, 안전하게 운영될 것이라거나, 프로젝트 또는
                프로바이더의 품질, 적시성, 신뢰도에 대한 어떠한 보증도 하지
                않습니다.
              </p>
            </section>

            <section>
              <h2>11. 책임 제한</h2>
              <p>
                관련 법이 허용하는 최대 한도 내에서, Togather는 간접적, 부수적,
                결과적, 특수, 징벌적 또는 예시적 손해(이익 손실, 데이터 손실,
                영업 손실 등을 포함) 에 대해 책임지지 않으며, 이는 Togather가
                그러한 손해 가능성을 사전에 인지했더라도 마찬가지입니다. 어떠한
                경우에도 Togather의 총 책임액은 (A) 귀하가 본 서비스 이용과
                관련하여 직전 12개월 동안 Togather에 지불한 총 금액 또는 (B)
                미화 100달러(US $100) 중 더 큰 금액을 초과하지 않습니다.
              </p>
            </section>

            <section>
              <h2>12. 면책</h2>
              <p>
                귀하는 다음 사항과 관련하여 발생하는 모든 클레임, 손해, 책임,
                손실, 비용(합리적인 변호사 비용 포함)으로부터 Togather 및 그
                계열사, 임직원, 대리인을 방어하고 면책하며 보호하는 것에
                동의합니다:
              </p>
              <ul>
                <li>귀하의 서비스 이용 또는 접근</li>
                <li>귀하가 본 약관을 위반한 경우</li>
                <li>
                  귀하가 제3자의 지적 재산권, 초상권, 비밀, 재산, 사생활 권리를
                  침해한 경우
                </li>
                <li>
                  귀하의 사용자 콘텐츠로 인해 제3자에게 발생한 손해 또는 손실
                </li>
              </ul>
            </section>

            <section>
              <h2>13. 준거법 및 중재</h2>
              <ul>
                <li>
                  <strong>준거법:</strong> 본 약관 및 서비스와 관련하여 발생하는
                  모든 분쟁은 국제사법 규정을 배제하고 [해당 관할 구역(예:
                  대한민국 또는 다른 관할권)]의 법률에 따라 해석되고 적용됩니다.
                </li>
                <li>
                  <strong>중재:</strong> 본 약관 또는 서비스 이용과 관련된 분쟁,
                  청구, 논쟁은 법원 대신 [중재 장소(예: 서울)]에서의
                  <strong>구속력 있는 중재</strong> 절차에 따라 해결됩니다. 모든
                  사용자는 배심원 재판 권리를 포기하며, 개별적 절차 외에 집단
                  소송이나 대표 소송 형태로 분쟁을 해결할 수 없습니다. 중재는
                  [대한상사중재원(KCAB) 또는 다른 공인 중재 기관]의 규정에 따라
                  진행됩니다. 중재인이 내린 판정은 관할권이 있는 법원에서 승인
                  및 집행될 수 있습니다.
                </li>
                <li>
                  <strong>집단소송 포기:</strong> 귀하는 Togather 또는 그
                  계열사에 대해 집단 소송을 제기하거나 여기에 참여하지 않는 것에
                  동의합니다.
                </li>
              </ul>
            </section>

            <section>
              <h2>14. 약관 변경</h2>
              <p>
                Togather는 언제든지 본 약관을 업데이트할 수 있습니다. 변경된
                약관은 이 페이지에 게시되며, 상단의 “발효일”이 갱신됩니다.
                변경된 약관 발효 이후에도 서비스를 계속 이용하면, 귀하가
                업데이트된 약관에 동의하는 것으로 간주됩니다.
              </p>
            </section>

            <section>
              <h2>15. 기타</h2>
              <ul>
                <li>
                  <strong>완전합의:</strong> 본 약관과 다른 법적 고지 및 참고로
                  통합된 정책들은 서비스에 관한 귀하와 Togather 간의 완전한
                  합의를 구성합니다.
                </li>
                <li>
                  <strong>가분성:</strong> 본 약관의 어떤 조항이 무효이거나
                  집행이 불가능한 것으로 판단되더라도, 해당 조항은 가능한 한도
                  내에서 집행되고, 나머지 조항은 여전히 완전한 효력을
                  유지합니다.
                </li>
                <li>
                  <strong>권리 미행사:</strong> Togather가 본 약관의 어떤 조항을
                  행사하지 않는다고 해서, 향후 그 조항이나 다른 조항의 집행
                  권리를 포기하는 것으로 해석되지 않습니다.
                </li>
                <li>
                  <strong>양도:</strong> 귀하는 Togather의 사전 서면 동의 없이
                  본 약관에서 정한 권리나 의무를 양도할 수 없습니다. Togather는
                  제한 없이 본 약관을 양도하거나 이전할 수 있습니다.
                </li>
                <li>
                  <strong>불가항력:</strong> Togather는 천재지변, 노사분쟁,
                  전쟁, 테러, 폭동, 자연재해, 정부 조치 등 자사의 합리적 통제를
                  벗어난 사유로 인한 지연 또는 불이행에 대해 책임을 지지
                  않습니다.
                </li>
              </ul>
            </section>

            <section>
              <h2>16. 문의하기</h2>
              <p>
                본 약관 또는 서비스와 관련하여 궁금한 점이나 문의 사항이
                있으시면 아래로 연락해 주시기 바랍니다:
              </p>
              <p>
                <strong>이메일:</strong>{" "}
                <a href="mailto:info@createtogather.com">
                  info@createtogather.com
                </a>
              </p>
            </section>
          </div>
        </div>
      ) : (
        <div className="misc-text-container">
          <div
            style={{
              fontSize: "28px",
              fontWeight: "500",
            }}
          >
            Togather - Terms of Service
          </div>
          <div style={{ fontSize: "10px", color: "#808080" }}>
            Effective Date: December 23, 2024
          </div>
          <Divider />
          <div style={{ fontSize: "12px", lineHeight: 2 }}>
            <p>
              Welcome to <strong>Togather</strong> (“<em>we</em>,” “<em>us</em>
              ,” or “<em>our</em>”). By accessing or using the Togather platform
              (the “<strong>Service</strong>”), you agree to these Terms of
              Service (the “<strong>Terms</strong>”). These Terms form a legally
              binding agreement between <strong>Togather, Inc.</strong> (“
              <strong>Togather</strong>”) and you (“<strong>you</strong>” or “
              <strong>User</strong>”). If you do not agree to these Terms, you
              are not authorized to use Togather.
            </p>

            <section>
              <h2>1. Acceptance of Terms</h2>
              <p>
                <strong>Binding Agreement:</strong> By registering for,
                accessing, or otherwise using the Service, you confirm that you
                have read, understood, and agree to be bound by these Terms.
              </p>
              <p>
                <strong>Modifications:</strong> Togather reserves the right, at
                its sole discretion, to modify, suspend, or discontinue any part
                of the Service at any time. We may also revise these Terms from
                time to time. If we make material changes, we will provide
                notice (e.g., by posting a notice on our website or sending an
                email). By continuing to use the Service after changes become
                effective, you accept the revised Terms.
              </p>
            </section>

            <section>
              <h2>2. Description of Services</h2>
              <p>
                Togather provides a marketplace platform where{" "}
                <strong>Clients</strong> can post projects or services they need
                and <strong>Providers</strong> can apply to complete those
                projects.{" "}
                <strong>
                  Togather is not a party to any agreement between Clients and
                  Providers; we act only as a facilitator
                </strong>{" "}
                to enable Clients and Providers to discover and contract with
                each other. Togather reserves the right to:
              </p>
              <ul>
                <li>Reject or remove any project listing or user account;</li>
                <li>Restrict or suspend any user’s access to the Service.</li>
              </ul>
            </section>

            <section>
              <h2>3. User Accounts</h2>
              <ul>
                <li>
                  <strong>Eligibility:</strong> You must be at least 18 years
                  old (or the age of majority in your jurisdiction) to use
                  Togather. Togather may, at its sole discretion, restrict or
                  terminate access for any individual if we believe it violates
                  these Terms or applicable laws.
                </li>
                <li>
                  <strong>Account Security:</strong> You are solely responsible
                  for maintaining the confidentiality of your account
                  credentials. You agree to notify Togather immediately of any
                  unauthorized use of your account or any other breach of
                  security.
                </li>
                <li>
                  <strong>Account Information:</strong> You agree to provide
                  accurate, current, and complete information during
                  registration and keep your information up to date. Togather is
                  not liable for any issues arising from inaccurate information.
                </li>
              </ul>
            </section>

            <section>
              <h2>4. User Responsibilities and Restrictions</h2>
              <p>You agree not to:</p>
              <ul>
                <li>
                  <strong>Violate Laws or Rights:</strong> Engage in any conduct
                  that violates any applicable law, regulation, or third-party
                  rights.
                </li>
                <li>
                  <strong>Provide False Information:</strong> Impersonate any
                  person or entity or fraudulently misrepresent your affiliation
                  with any person or entity.
                </li>
                <li>
                  <strong>Disrupt the Service:</strong> Upload viruses, spam, or
                  any code that may disrupt, damage, or interfere with the
                  Service.
                </li>
                <li>
                  <strong>Reverse Engineer or Copy:</strong> Attempt to reverse
                  engineer, duplicate, reproduce, sell, resell, or exploit any
                  portion of Togather without our express written permission.
                </li>
                <li>
                  <strong>Circumvent Security:</strong> Attempt to gain
                  unauthorized access to the Service, other accounts, or
                  computer systems via hacking, password mining, or other
                  illicit means.
                </li>
              </ul>
              <p>
                Togather reserves the right (but has no obligation) to
                investigate and take legal action against anyone who, in
                Togather’s sole discretion, violates these Terms, including
                removing content, suspending or terminating accounts, and/or
                reporting you to law enforcement.
              </p>
            </section>

            <section>
              <h2>5. Payment and Project Terms</h2>
              <ul>
                <li>
                  <strong>Project Posting and Payment:</strong> Clients are
                  responsible for providing accurate project descriptions and
                  agreeing to pay the specified amounts for the services they
                  request. All payments made through Togather are final, unless
                  otherwise stated in these Terms or agreed upon in writing by
                  Togather. Any refund requests will be subject to Togather’s
                  sole discretion.
                </li>
                <li>
                  <strong>Provider Compensation:</strong> Providers will receive
                  payment for completed projects after Togather deducts its
                  platform fees (“Platform Fees”). Payment to Providers is
                  subject to successful completion of the project and any
                  additional approval or verification steps. Togather reserves
                  the right to withhold or adjust payments at its discretion.
                </li>
                <li>
                  <strong>Non-Refundable Fees:</strong> All Platform Fees and
                  service charges are non-refundable, including if a project is
                  canceled, disputed, or otherwise not completed to a User’s
                  satisfaction.
                </li>
              </ul>
            </section>

            <section>
              <h2>6. Fees and Payments</h2>
              <ul>
                <li>
                  <strong>Platform Fees:</strong> Togather charges a fee for
                  facilitating transactions between Clients and Providers. The
                  amount and structure of these fees may change at any time
                  without prior notice. By using the Service, you authorize
                  Togather to deduct these fees from any payments processed
                  through the platform.
                </li>
                <li>
                  <strong>Third-Party Payment Processors:</strong> Togather uses
                  third-party payment processors. Your use of these payment
                  services is subject to separate agreements and fees governed
                  by those third parties. Togather is not responsible for any
                  errors, disputes, or additional fees that arise from these
                  services.
                </li>
                <li>
                  <strong>Authorization:</strong> By providing payment
                  information, you represent and warrant that you have the legal
                  right to use the designated payment method. You authorize
                  Togather to charge your payment method for any applicable fees
                  or charges incurred in connection with your use of the
                  Service.
                </li>
              </ul>
            </section>

            <section>
              <h2>7. Intellectual Property</h2>
              <p>
                All intellectual property rights in Togather (including
                trademarks, service marks, logos, software, text, images, and
                design) are owned by or licensed to Togather. Except as
                expressly stated in these Terms, you may not copy, reproduce,
                distribute, create derivative works from, or use any Togather
                intellectual property without our prior written consent.
              </p>
            </section>

            <section>
              <h2>8. User-Generated Content</h2>
              <p>
                Users may post or transmit content such as descriptions, images,
                feedback, or other materials (“User Content”). By submitting
                User Content to the Service, you grant Togather a non-exclusive,
                worldwide, royalty-free, irrevocable, perpetual, and
                sub-licensable license to use, reproduce, modify, and distribute
                your content in any media for the purpose of providing and
                promoting the Service. Togather is not liable for any User
                Content and reserves the right to remove or restrict access to
                any User Content at our discretion.
              </p>
            </section>

            <section>
              <h2>9. Termination</h2>
              <ul>
                <li>
                  <strong>Termination by Togather:</strong> We may suspend or
                  terminate your access to the Service or your account, with or
                  without notice, for any reason, including if we believe you
                  have violated these Terms, engaged in fraudulent or unlawful
                  activity, or caused harm to other Users.
                </li>
                <li>
                  <strong>Termination by You:</strong> You may terminate your
                  account at any time by contacting Togather at{" "}
                  <a href="mailto:info@createtogather.com">
                    info@createtogather.com
                  </a>
                  . Any outstanding fees, obligations, or liabilities incurred
                  prior to termination will remain in effect.
                </li>
                <li>
                  <strong>Effect of Termination:</strong> Upon termination, all
                  licenses and other rights granted to you in these Terms
                  immediately cease. Sections relating to fees, warranties,
                  limitations of liability, indemnification, dispute resolution,
                  and any other provisions intended by their nature to survive
                  will remain in full force and effect.
                </li>
              </ul>
            </section>

            <section>
              <h2>10. Disclaimer of Warranties</h2>
              <p>
                TO THE FULLEST EXTENT PERMITTED BY LAW, THE SERVICE IS PROVIDED
                ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT WARRANTIES OF
                ANY KIND, WHETHER EXPRESS, IMPLIED, OR STATUTORY. TOGATHER
                SPECIFICALLY DISCLAIMS ANY IMPLIED WARRANTIES OF
                MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
                NON-INFRINGEMENT, AND ANY WARRANTIES ARISING OUT OF COURSE OF
                DEALING OR USAGE OF TRADE. WE DO NOT WARRANT THAT THE SERVICE
                WILL BE UNINTERRUPTED, ERROR-FREE, OR SECURE; NOR DO WE MAKE ANY
                WARRANTY REGARDING THE QUALITY, TIMELINESS, OR RELIABILITY OF
                ANY PROJECTS OR PROVIDERS.
              </p>
            </section>

            <section>
              <h2>11. Limitation of Liability</h2>
              <p>
                TO THE MAXIMUM EXTENT PERMITTED BY LAW, TOGATHER SHALL NOT BE
                LIABLE FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL,
                PUNITIVE, OR EXEMPLARY DAMAGES, INCLUDING LOSS OF PROFITS, LOSS
                OF DATA, OR LOSS OF BUSINESS, EVEN IF TOGATHER HAS BEEN ADVISED
                OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT WILL TOGATHER’S
                AGGREGATE LIABILITY FOR ALL CLAIMS RELATING TO THE SERVICE
                EXCEED THE GREATER OF (A) THE TOTAL AMOUNT PAID BY YOU TO
                TOGATHER IN THE TWELVE (12) MONTHS PRECEDING THE EVENT GIVING
                RISE TO THE CLAIM OR (B) ONE HUNDRED U.S. DOLLARS (US $100).
              </p>
            </section>

            <section>
              <h2>12. Indemnification</h2>
              <p>
                You agree to defend, indemnify, and hold harmless Togather, its
                affiliates, and their respective officers, directors, employees,
                and agents from and against all claims, damages, liabilities,
                losses, costs, and expenses (including reasonable attorneys’
                fees) arising out of or in any way connected with:
              </p>
              <ul>
                <li>Your use of or access to the Service;</li>
                <li>Any breach by you of these Terms;</li>
                <li>
                  Your violation of any third-party right, including
                  intellectual property, publicity, confidentiality, property,
                  or privacy right;
                </li>
                <li>
                  Any claim that your User Content caused damage or loss to a
                  third party.
                </li>
              </ul>
            </section>

            <section>
              <h2>13. Governing Law and Arbitration</h2>
              <ul>
                <li>
                  <strong>Governing Law:</strong> These Terms and any dispute
                  arising out of or relating to these Terms or the Service shall
                  be governed by and construed in accordance with the laws of
                  [Insert Jurisdiction], without regard to conflict-of-laws
                  principles.
                </li>
                <li>
                  <strong>Arbitration:</strong> Any dispute, claim, or
                  controversy relating to these Terms or your use of the Service
                  will be resolved by binding arbitration in [Insert Location],
                  rather than in court. All users waive any right to a jury
                  trial and agree that any dispute resolution proceeding will be
                  conducted only on an individual basis, and not as a class or
                  representative action. The arbitration shall be administered
                  by [American Arbitration Association or other recognized
                  arbitration body] under its rules. Judgment on the award
                  rendered by the arbitrator may be entered in any court having
                  jurisdiction thereof.
                </li>
                <li>
                  <strong>Class Action Waiver:</strong> You agree that you will
                  not bring or participate in any class action against Togather
                  or its affiliates.
                </li>
              </ul>
            </section>

            <section>
              <h2>14. Changes to Terms</h2>
              <p>
                Togather may update these Terms at any time. Any changes will be
                posted on this page, and the “Effective Date” at the top will be
                updated accordingly. Your continued use of the Service after any
                changes signifies your acceptance of the updated Terms.
              </p>
            </section>

            <section>
              <h2>15. Miscellaneous</h2>
              <ul>
                <li>
                  <strong>Entire Agreement:</strong> These Terms, together with
                  any other legal notices and policies incorporated by
                  reference, constitute the entire agreement between you and
                  Togather regarding the Service.
                </li>
                <li>
                  <strong>Severability:</strong> If any provision of these Terms
                  is found to be invalid or unenforceable, that provision shall
                  be enforced to the maximum extent permissible, and the
                  remaining provisions shall remain in full force and effect.
                </li>
                <li>
                  <strong>No Waiver:</strong> The failure by Togather to enforce
                  any provision of these Terms will not be construed as a waiver
                  of any future enforcement of that or any other provision.
                </li>
                <li>
                  <strong>Assignment:</strong> You may not assign or transfer
                  these Terms or any rights or obligations herein without
                  Togather’s prior written consent. Togather may assign or
                  transfer these Terms without restriction.
                </li>
                <li>
                  <strong>Force Majeure:</strong> Togather is not liable for any
                  delay or failure to perform resulting from causes outside its
                  reasonable control, including acts of God, labor disputes,
                  war, terrorism, riots, natural disasters, or governmental
                  action.
                </li>
              </ul>
            </section>

            <section>
              <h2>16. Contact Us</h2>
              <p>
                If you have questions, concerns, or inquiries regarding these
                Terms or the Service, please contact us at:
              </p>
              <p>
                <strong>Email:</strong>{" "}
                <a href="mailto:info@createtogather.com">
                  info@createtogather.com
                </a>
              </p>
            </section>
          </div>
        </div>
      )}
    </Box>
  );
};

export default TermsOfService;
