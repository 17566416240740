import React from "react";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import Divider from "@mui/material/Divider";

const TrustAndSafety = () => {
  const { i18n } = useTranslation();
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box sx={{ flexGrow: 1, bgcolor: "#ffffff" }}>
      {i18n.language === "ko" ? (
        <div className="misc-text-container">
          <div
            style={{
              fontSize: "28px",
              fontWeight: "500",
            }}
          >
            신뢰, 안전 &amp; 보안
          </div>
          <div style={{ fontSize: "10px", color: "#808080" }}>
            2024년 12월 23일 시행
          </div>
          <Divider sx={{ marginBottom: "1rem" }} />
          <div style={{ fontSize: "12px", lineHeight: "2" }}>
            <p>
              Togather에서는 회원 여러분의 안전과 보안을 최우선으로 생각합니다.
              안심하고 연결하고 협업할 수 있도록 강력한 안전장치를 마련하여,
              의미 있는 교류가 이루어질 수 있도록 지원합니다.
            </p>
            <p>
              저희의 모든 활동의 중심에는 보안이 자리하고 있습니다. 업계 최고
              수준의 암호화 방식을 적용하여 개인정보 및 데이터를 보호하고
              있습니다. 정기적인 시스템 업데이트와 모니터링을 통해 잠재적 위협을
              사전에 발견하고 막기 위해 노력하며, 관련 데이터 보호 규정을
              준수하고 있습니다. 다만, 어떤 온라인 플랫폼도 모든 위협으로부터
              100% 안전을 보장할 수는 없음을 알려드립니다.
            </p>
            <p>
              신뢰는 투명성과 일관된 행동에서 비롯된다고 믿습니다. 당사 팀은
              플랫폼 오용 및 부적절한 활동을 방지하기 위해 적극적으로 모니터링을
              실시하며, 모든 사용자가 준수해야 할 명확한 커뮤니티 가이드라인을
              마련했습니다. 위반 사례가 발견되면 신속하게 조치를 취합니다.
              Togather 이용 중 안전 및 보안과 관련된 우려 사항이 있으시면{" "}
              <a href="mailto:info@createtogather.com">
                info@createtogather.com
              </a>
              으로 즉시 알려주십시오.
            </p>
            <p>
              저희의 안전에 대한 노력은 디지털 보안에만 국한되지 않습니다.
              사용자 정보를 가능한 범위에서 신중히 검증하고, 플랫폼 무결성을
              유지하기 위해 여러 인증 단계를 적용합니다. 정기적인 보안 감사와
              업데이트를 통해 새롭게 대두되는 위협에 대응하며, 가장 안전한
              환경을 제공하기 위해 노력하고 있습니다.
            </p>
            <p>
              Togather에 대한 여러분의 신뢰는 저희가 엄격한 안전 및 보안 기준을
              유지하는 원동력입니다. 저희는 지속적으로 고도화된 보안 대책에
              투자하고, 모범 사례 및 규제 요건에 부합하도록 프로토콜을
              갱신합니다. 함께 만들어가는 이 플랫폼을 더욱 안전하고 신뢰할 수
              있는 공간으로 발전시켜, 협업이 번창하고 커뮤니티가 성장할 수
              있도록 최선을 다하겠습니다.
            </p>
          </div>
        </div>
      ) : (
        <div className="misc-text-container">
          <div
            style={{
              fontSize: "28px",
              fontWeight: "500",
            }}
          >
            Trust, Safety &amp; Security
          </div>
          <div style={{ fontSize: "10px", color: "#808080" }}>
            Effective December 23, 2024
          </div>
          <Divider sx={{ marginBottom: "1rem" }} />
          <div style={{ fontSize: "12px", lineHeight: "2" }}>
            <p>
              At Togather, your safety and security are our top priorities.
              We've built our platform with robust safeguards to ensure you can
              connect and collaborate with confidence. Our comprehensive trust
              and safety measures protect our community members while fostering
              meaningful interactions.
            </p>
            <p>
              Security is at the core of everything we do. We employ
              industry-leading encryption standards to protect your personal
              information and data. Regular system updates and continuous
              monitoring help us detect and deter potential threats, and we
              comply with applicable data protection regulations. While we
              strive to maintain the highest security standards, please note
              that no online platform can be 100% guaranteed against all
              threats.
            </p>
            <p>
              We believe trust is earned through transparency and consistent
              action. Our team proactively monitors platform activity to prevent
              misuse and maintain a respectful environment. Clear community
              guidelines outline acceptable behavior, and we take swift action
              against any violations. If you have any safety or security
              concerns while using Togather, please report them immediately at{" "}
              <a href="mailto:info@createtogather.com">
                info@createtogather.com
              </a>
              .
            </p>
            <p>
              Our commitment to safety goes beyond digital security. We verify
              user information wherever possible and implement multiple layers
              of authentication to uphold the integrity of our platform. Regular
              security audits and updates allow us to stay ahead of emerging
              threats, ensuring we're providing the most secure environment
              possible.
            </p>
            <p>
              Your trust in Togather drives us to maintain these high standards
              of safety and security. We continuously invest in advanced
              security measures and update our protocols to align with evolving
              best practices and regulatory requirements. Together, we’re
              building a secure, trustworthy platform where collaboration can
              flourish and communities can thrive.
            </p>
          </div>
        </div>
      )}
    </Box>
  );
};

export default TrustAndSafety;
