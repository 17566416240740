import React from "react";
import { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import Chip from "@mui/material/Chip";
import GroupsIcon from "@mui/icons-material/Groups";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import PersonIcon from "@mui/icons-material/Person";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import PostAddIcon from "@mui/icons-material/PostAdd";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { TextField } from "@mui/material";
import dayjs from "dayjs";
import EditIcon from "@mui/icons-material/Edit";
import { supabase } from "./SupabaseClient";
import DeleteIcon from "@mui/icons-material/Delete";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import DialogContentText from "@mui/material/DialogContentText";
import { useTranslation } from "react-i18next";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import { loadStripe } from "@stripe/stripe-js";
import Card from "@mui/material/Card";
import ChatIcon from "@mui/icons-material/Chat";
import useExchangeRate from "./useExchangeRate";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Chat from "./Chat";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CancelIcon from "@mui/icons-material/Cancel";
import { UserContext } from "./UserContext";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
import VerifiedIcon from "@mui/icons-material/Verified";

const DEFAULT_CURRENCY = {
  USD: {
    symbol: "$",
    locale: "en-US",
  },
  KRW: {
    symbol: "₩",
    locale: "ko-KR",
  },
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  const nameParts = name.split(" ");
  const initials =
    nameParts.length > 1
      ? `${nameParts[0][0]}${nameParts[1][0]}`
      : `${nameParts[0][0]}`;

  return {
    sx: {
      bgcolor: stringToColor(name),
      cursor: "pointer",
    },
    children: initials.toUpperCase(),
  };
}

const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 15,
  height: 15,
  backgroundColor: "#9f7fe3",
  color: "#fff",
}));

const ProjectManagement = () => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const [project, setProject] = useState("");
  const [team, setTeam] = useState([]);
  const [submissions, setSubmissions] = useState([]);
  const [showCallSheetButton, setShowCallSheetButton] = useState(false);
  const [open, setOpen] = useState(false);
  const [memo, setMemo] = useState("");
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedRoleId, setSelectedRoleId] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [openDeleteProjectDialog, setOpenDeleteProjectDialog] = useState(false);
  const { t } = useTranslation();
  const [showAlert, setShowAlert] = useState(false);
  const [showPaymentDialog, setShowPaymentDialog] = useState(false);
  const [amount, setAmount] = useState(0);
  const [status, setStatus] = useState("");
  const [totalCost, setTotalCost] = useState({ value: 0, formatted: "" });
  const [totalPaid, setTotalPaid] = useState({ value: 0, formatted: "" });
  const [editCallSheet, setEditCallSheet] = useState(false);
  const [callSheet, setCallSheet] = useState(null);
  const [totalRemaining, setTotalRemaining] = useState({
    value: 0,
    formatted: "",
  });
  const { rate: exchangeRate, loading: rateLoading } = useExchangeRate();
  const { i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [callSheetItems, setCallSheetItems] = useState([]);
  const [confirmations, setConfirmations] = useState({});
  const [editingRows, setEditingRows] = useState({});
  const { user } = useContext(UserContext);
  const [editMemo, setEditMemo] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Get the tab from URL query parameters
  const [searchParams] = useSearchParams();
  const defaultTab = searchParams.get("tab") || "team"; // default to 'team' if no tab specified
  const [activeTab, setActiveTab] = useState(defaultTab);

  const handleMemo = (e) => {
    setMemo(e.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const areAllItemsFilled = (items) => {
    return items.every((item) => item.filled);
  };

  const handlePlayClick = async () => {
    if (project.type === "collab") {
      const { data: status, error: statusError } = await supabase
        .from("Projects")
        .update({ status: "live" })
        .eq("id", projectId);

      if (statusError) {
        console.log(statusError);
      } else {
        setStatus("live");
        return;
      }
    }
    if (!areAllItemsFilled(team)) {
      setShowAlert(true);

      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    } else {
      setShowPaymentDialog(true);
    }
  };

  const sumBudgets = (data = []) => {
    return data.reduce((total, item) => {
      return total + (item.budget || 0);
    }, 0);
  };

  const handleCheckout = async () => {
    try {
      // Call backend to create checkout session
      const res = await fetch(
        `https://54rr0rbk9h.execute-api.us-east-1.amazonaws.com/prod/stripe/create-checkout-session`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            projectName: project.name,
            amount,
            // Update success URL to include payment status
            successUrl: `https://createtogather.com/submissions/${projectId}?payment=success`,
            cancelUrl: `https://createtogather.com/submissions/${projectId}`,
            language: i18n.language,
          }),
        }
      );

      const data = await res.json();

      if (data.sessionId) {
        // Redirect to Stripe Checkout
        const stripe = await stripePromise;
        await stripe.redirectToCheckout({ sessionId: data.sessionId });
      }
    } catch (error) {
      console.error("Error creating checkout session:", error);
    }
  };

  // Add new function to check payment status
  const checkPaymentStatus = async () => {
    const searchParams = new URLSearchParams(window.location.search);
    const paymentStatus = searchParams.get("payment");

    if (paymentStatus === "success") {
      try {
        // Update project status in Supabase
        const { error: statusError } = await supabase
          .from("Projects")
          .update({ status: "live" })
          .eq("id", projectId);

        if (statusError) {
          console.error("Error updating project status:", statusError);
        } else {
          setStatus("live");
          // Clear the URL parameter after successful update
          window.history.replaceState({}, "", window.location.pathname);
        }
      } catch (error) {
        console.error("Error checking payment status:", error);
      }
    }
  };

  // Add useEffect to check payment status on component mount
  useEffect(() => {
    if (projectId) {
      checkPaymentStatus();
    }
  }, [projectId]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleTeamChange = (memberId, field, value) => {
    setCallSheetItems((prevItems) => {
      return prevItems.map((item) => {
        if (item.role_id === memberId) {
          if (field === "start_time" || field === "wrap_time") {
            return {
              ...item,
              [field]: value ? value.format("YYYY-MM-DDTHH:mm:ssZ") : null,
            };
          } else {
            return {
              ...item,
              [field]: value,
            };
          }
        }
        return item;
      });
    });
  };

  const saveCallSheet = async () => {
    let callSheetId = callSheet?.id;

    if (!callSheetId) {
      const { data: newCallSheet, error: createError } = await supabase
        .from("CallSheets")
        .insert([
          {
            project_id: projectId,
            memo: memo,
          },
        ])
        .select()
        .single();

      if (createError) {
        console.error("Error creating call sheet:", createError);
        return;
      }

      callSheetId = newCallSheet.id;
    } else {
      const { error: updateError } = await supabase
        .from("CallSheets")
        .update({ memo: memo })
        .eq("id", callSheetId);

      if (updateError) {
        console.error("Error updating call sheet:", updateError);
        return;
      }
    }

    // Update or create call sheet items
    const promises = team.map(async (member) => {
      const existingItem = callSheetItems.find(
        (item) => item.role_id === member.id
      );

      // Prepare the data including address and confirmation status
      const itemData = {
        start_time: member.start_time || null,
        wrap_time: member.wrap_time || null,
        address: member.address || null,
        confirmed: confirmations[member.id] || false,
      };

      if (existingItem) {
        // Update existing item
        return supabase
          .from("CallSheetItems")
          .update(itemData)
          .eq("id", existingItem.id);
      } else {
        // Create new item
        return supabase.from("CallSheetItems").insert([
          {
            call_sheet_id: callSheetId,
            role_id: member.id,
            user_id: member.user,
            ...itemData,
          },
        ]);
      }
    });

    try {
      await Promise.all(promises);
      setMemo("");
      getCallSheet(); // Refresh the data
    } catch (error) {
      console.error("Error saving call sheet items:", error);
    }
  };

  const calculateDuration = (duration, durationType) => {
    if (durationType === "Hours") {
      if (duration === "1") {
        return `${duration} Hour`;
      } else {
        return `${duration} Hours`;
      }
    } else if (durationType === "Days") {
      if (duration === "1") {
        return `${duration} Day`;
      } else {
        return `${duration} Days`;
      }
    } else if (durationType === "Weeks") {
      if (duration === "1") {
        return `${duration} Week`;
      } else {
        return `${duration} Weeks`;
      }
    } else if (durationType === "Months") {
      if (duration === "1") {
        return `${duration} Month`;
      } else {
        return `${duration} Months`;
      }
    }
  };

  const navigateToViewSubmissionsPage = (id, applications, name) => {
    navigate("/submissions/role/" + id, {
      state: { applications, name, id, projectId, type: project.type },
    });
  };

  const formatCurrency = (amount, currency) => {
    const currencyConfig = DEFAULT_CURRENCY[currency];

    return new Intl.NumberFormat(currencyConfig.locale, {
      style: "currency",
      currency: currency,
      minimumFractionDigits: currency === "KRW" ? 0 : 2,
      maximumFractionDigits: currency === "KRW" ? 0 : 2,
    }).format(amount);
  };

  const calculateCosts = (projectData) => {
    if (!projectData?.ProjectRoles) return;

    const isKorean = i18n.language === "ko";

    const totalUSD = (projectData.ProjectRoles || []).reduce(
      (sum, role) => sum + (role.budget || 0),
      0
    );
    const paidUSD = projectData.amount_paid || 0;
    const remainingUSD = totalUSD - paidUSD;

    const currency = isKorean ? "KRW" : "USD";
    const rate = isKorean ? exchangeRate : 1;

    setTotalCost({
      value: totalUSD * rate,
      formatted: formatCurrency(totalUSD * rate, currency),
    });
    setTotalPaid({
      value: paidUSD * rate,
      formatted: formatCurrency(paidUSD * rate, currency),
    });
    setTotalRemaining({
      value: remainingUSD * rate,
      formatted: formatCurrency(remainingUSD * rate, currency),
    });
  };

  const getProject = async () => {
    const { data } = await supabase
      .from("Projects")
      .select(
        `
        *,
        Users (id, first_name, last_name, profile_picture),
        ProjectRoles (
          *,
          Users (id,first_name, last_name, profile_picture, pro)
        )
      `
      )
      .eq("id", projectId);

    if (data && data[0]) {
      setProject(data[0]);
      setTeam(data[0].ProjectRoles || []);
      calculateCosts(data[0]);

      let teamData = data[0].ProjectRoles || [];

      const roleIds = teamData
        .filter((item) => !item.user)
        .map((item) => item.id);

      if (roleIds.length > 0) {
        const { data: applications } = await supabase
          .from("Applications")
          .select(
            `
            *,
            Users (id, first_name, last_name, email, profile_picture, pro)
          `
          )
          .in("role", roleIds);

        if (applications) {
          const submissionsMap = new Map();
          applications.forEach((application) => {
            if (!submissionsMap.has(application.role)) {
              submissionsMap.set(application.role, []);
            }
            submissionsMap.get(application.role).push(application);
          });

          teamData = teamData.map((role) => {
            if (submissionsMap.has(role.id)) {
              return {
                ...role,
                submissions: submissionsMap.get(role.id),
              };
            }
            return role;
          });
        }
      }

      setTeam(teamData);
      setAmount(sumBudgets(data[0].ProjectRoles || []));
    }
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!rateLoading) {
      getProject();
    }
  }, [rateLoading, i18n.language]);

  const handleRemoveTeamMember = async (roleId, userId) => {
    const { data, error } = await supabase
      .from("ProjectRoles")
      .update({ user: null, filled: false })
      .eq("id", roleId);

    if (error) {
      console.log(error);
    } else {
      const { data: applications, error: applicationsError } = await supabase
        .from("Applications")
        .update({ approved: false })
        .eq("role", roleId)
        .eq("user", userId);
      if (applicationsError) {
        console.log(applicationsError);
      } else {
        getProject();
      }
    }
  };

  const handleDelete = async () => {
    const { data, error } = await supabase
      .from("Projects")
      .delete()
      .eq("id", projectId);

    if (error) {
      console.log(error);
    } else {
      navigate("/projects");
    }
  };

  const getCallSheet = async () => {
    const { data: callSheetData, error: callSheetError } = await supabase
      .from("CallSheets")
      .select("*")
      .eq("project_id", projectId)
      .single();

    if (callSheetError && callSheetError.code !== "PGRST116") {
      // Ignore "not found" error
      console.error("Error fetching call sheet:", callSheetError);
      return;
    }

    if (callSheetData) {
      setCallSheet(callSheetData);
      setMemo(callSheetData.memo || ""); // Set memo from CallSheets table

      // Fetch associated call sheet items
      const { data: itemsData, error: itemsError } = await supabase
        .from("CallSheetItems")
        .select(
          `
          *,
          ProjectRoles:role_id (
            id,
            name
          ),
          Users:user_id (
            id,
            first_name,
            last_name,
            profile_picture,
            email
          )
        `
        )
        .eq("call_sheet_id", callSheetData.id);

      if (itemsError) {
        console.error("Error fetching call sheet items:", itemsError);
        return;
      }

      setCallSheetItems(itemsData || []);
    }
  };

  useEffect(() => {
    if (projectId) {
      getCallSheet();
    }
  }, [projectId]);

  useEffect(() => {
    if (callSheetItems.length > 0) {
      const initialConfirmations = {};
      callSheetItems.forEach((item) => {
        initialConfirmations[item.role_id] = item.confirmed || false;
      });
      setConfirmations(initialConfirmations);
    }
  }, [callSheetItems]);

  const handleConfirmationChange = async (roleId, checked) => {
    // Update local state immediately
    setConfirmations((prev) => ({
      ...prev,
      [roleId]: checked,
    }));

    // If you want to persist this to the database immediately
    if (callSheet?.id) {
      const existingItem = callSheetItems.find(
        (item) => item.role_id === roleId
      );

      if (existingItem) {
        const { error } = await supabase
          .from("CallSheetItems")
          .update({ confirmed: checked })
          .eq("id", existingItem.id);

        if (error) {
          console.error("Error updating confirmation:", error);
        }
      }
    }
  };

  const canEditAllFields = (item) => {
    return (
      project.owner === user.id || // Project owner
      user.account_type === "superadmin" || // Superadmin
      project.ProjectRoles?.some(
        // Project manager
        (role) => role.name === "Project Manager" && role.user === user.id
      )
    );
  };

  const handleEditRow = (rowId) => {
    const teamMember = team.find((member) => member.id === rowId);

    // Only allow editing if user has full permissions or it's their own role
    if (!canEditAllFields(teamMember) && teamMember.user !== user.id) {
      return;
    }

    setEditingRows((prev) => ({
      ...prev,
      [rowId]: true,
    }));

    // Check if callSheetItem exists for this row
    const existingItem = callSheetItems.find((item) => item.role_id === rowId);
    if (!existingItem && callSheet?.id) {
      // Create a new callSheetItem
      setCallSheetItems((prev) => [
        ...prev,
        {
          role_id: rowId,
          call_sheet_id: callSheet.id,
          address: "",
          start_time: null,
          wrap_time: null,
          confirmed: false,
        },
      ]);
    }
  };

  const handleSaveRow = async (rowId) => {
    const callSheetItem = callSheetItems.find((item) => item.role_id === rowId);

    try {
      let savedData;
      if (callSheetItem && callSheetItem.id) {
        // Update existing item
        const { data, error } = await supabase
          .from("CallSheetItems")
          .update({
            start_time: callSheetItem.start_time,
            wrap_time: callSheetItem.wrap_time,
            address: callSheetItem.address,
            confirmed: callSheetItem.confirmed,
          })
          .eq("id", callSheetItem.id)
          .select();

        if (error) throw error;
        data[0].language = i18n.language;
        savedData = data[0];
      } else {
        // Create new item
        const { data, error } = await supabase
          .from("CallSheetItems")
          .insert([
            {
              call_sheet_id: callSheet.id,
              user_id:
                team.find((member) => member.id === rowId)?.Users?.id || null,
              role_id: rowId,
              start_time:
                callSheetItems.find((item) => item.role_id === rowId)
                  ?.start_time || null,
              wrap_time:
                callSheetItems.find((item) => item.role_id === rowId)
                  ?.wrap_time || null,
              address:
                callSheetItems.find((item) => item.role_id === rowId)
                  ?.address || "",
              confirmed: false,
            },
          ])
          .select();

        if (error) throw error;
        data[0].language = i18n.language;
        savedData = data[0];
      }

      setEditingRows((prev) => ({
        ...prev,
        [rowId]: false,
      }));

      // Refresh call sheet data
      await getCallSheet();

      // Only send notifications if more than just confirmation status changed
      if (
        Object.keys(savedData).some(
          (key) =>
            key !== "confirmed" &&
            key !== "id" &&
            key !== "language" &&
            savedData[key] !==
              callSheetItems.find((item) => item.id === savedData.id)?.[key]
        )
      ) {
        await handleSendCallSheetNotifications(savedData);
      }
    } catch (error) {
      console.error("Error saving row:", error);
    }
  };

  const generateCallSheetPDF = async () => {
    const callSheetDiv = document.createElement("div");
    callSheetDiv.style.padding = "30px";

    // Function to generate avatar with initials
    const generateInitialsAvatar = (firstName, lastName) => {
      const initials = `${firstName?.[0] || ""}${
        lastName?.[0] || ""
      }`.toUpperCase();
      return `
        <div style="
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background-color: #bdbdbd;
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
          font-weight: 500;
          font-size: 16px;
        ">
          ${initials}
        </div>
      `;
    };

    callSheetDiv.innerHTML = `
      <div style="text-align: center; margin-bottom: 30px;">
        <img src="/images/togather_logo.png" alt="Togather Logo" style="height: 40px; margin-bottom: 20px;" />
      </div>
      <h2 style="font-weight: 400; font-size: 22px; margin-bottom: 30px;">${
        project.name
      } - Call Sheet</h2>
      <p style="margin-bottom: 24px;">${
        project.projectDate
          ? dayjs(project.projectDate).format("dddd, MMMM D, YYYY")
          : ""
      }</p>
      <table style="width: 100%; border-collapse: collapse; min-width: 650px;">
        <thead>
          <tr>
            <th style="border-bottom: 1px solid rgba(224, 224, 224, 1); padding: 16px; text-align: left; font-weight: 500; color: rgba(0, 0, 0, 0.87);">Team Member</th>
            <th style="border-bottom: 1px solid rgba(224, 224, 224, 1); padding: 16px; text-align: left; font-weight: 500; color: rgba(0, 0, 0, 0.87);">Role</th>
            <th style="border-bottom: 1px solid rgba(224, 224, 224, 1); padding: 16px; text-align: left; font-weight: 500; color: rgba(0, 0, 0, 0.87);">Address</th>
            <th style="border-bottom: 1px solid rgba(224, 224, 224, 1); padding: 16px; text-align: left; font-weight: 500; color: rgba(0, 0, 0, 0.87);">Call Time</th>
            <th style="border-bottom: 1px solid rgba(224, 224, 224, 1); padding: 16px; text-align: left; font-weight: 500; color: rgba(0, 0, 0, 0.87);">Wrap Time</th>
          </tr>
        </thead>
        <tbody>
          ${callSheetItems
            .map(
              (item) => `
          <tr>
            <td style="border-bottom: 1px solid rgba(224, 224, 224, 1); padding: 16px;">
              <div style="display: flex; align-items: center; gap: 16px;">
                ${
                  item.Users?.profile_picture
                    ? `<img src="${item.Users.profile_picture}" 
                         style="width: 40px; height: 40px; border-radius: 50%; 
                         border: 2px solid #fff; box-shadow: 0 2px 4px rgba(0,0,0,0.1);" 
                         alt="${item.Users.first_name}">`
                    : generateInitialsAvatar(
                        item.Users?.first_name,
                        item.Users?.last_name
                      )
                }
                <span>${item.Users?.first_name} ${item.Users?.last_name}</span>
              </div>
            </td>
            <td style="border-bottom: 1px solid rgba(224, 224, 224, 1); padding: 16px;">
              <span style="background-color: rgba(0, 0, 0, 0.08); 
                         border-radius: 16px; padding: 4px 10px; 
                         font-size: 0.8125rem;">
                ${item.ProjectRoles?.name}
              </span>
            </td>
            <td style="border-bottom: 1px solid rgba(224, 224, 224, 1); padding: 16px;">
              ${item.address || "-"}
            </td>
            <td style="border-bottom: 1px solid rgba(224, 224, 224, 1); padding: 16px;">
              ${
                item.start_time ? dayjs(item.start_time).format("hh:mm A") : "-"
              }
            </td>
            <td style="border-bottom: 1px solid rgba(224, 224, 224, 1); padding: 16px;">
              ${item.wrap_time ? dayjs(item.wrap_time).format("hh:mm A") : "-"}
            </td>
          </tr>
        `
            )
            .join("")}
        </tbody>
      </table>
      ${
        memo
          ? `
      <div style="margin-top: 48px;">
        <h3 style="font-size: 14px; margin-bottom: 16px;">Memo</h3>
        <p style="font-size: 12px;">${memo}</p>
      </div>
    `
          : ""
      }
    `;

    document.body.appendChild(callSheetDiv);

    try {
      const canvas = await html2canvas(callSheetDiv, {
        scale: 1.5,
        logging: false,
        useCORS: true,
      });
      document.body.removeChild(callSheetDiv);

      const imgData = canvas.toDataURL("image/jpeg", 0.7);
      const pdf = new jsPDF("p", "mm", "a4");
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      pdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, pdfHeight);

      const pdfBase64 = pdf
        .output("datauristring", { compress: true })
        .split(",")[1];
      return pdfBase64;
    } catch (error) {
      console.error("Error generating PDF:", error);
      return null;
    }
  };

  const getTimezoneFromAddress = async (address) => {
    try {
      // First get coordinates from address using Geocoding API
      const geocodeResponse = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          address
        )}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
      );
      const geocodeData = await geocodeResponse.json();

      if (geocodeData.results && geocodeData.results[0]) {
        const { lat, lng } = geocodeData.results[0].geometry.location;

        // Then get timezone from coordinates using Timezone API
        const timestamp = Math.floor(Date.now() / 1000);
        const timezoneResponse = await fetch(
          `https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${lng}&timestamp=${timestamp}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
        );
        const timezoneData = await timezoneResponse.json();

        if (timezoneData.timeZoneId) {
          return timezoneData.timeZoneId;
        }
      }

      // Return user's local timezone as fallback
      return Intl.DateTimeFormat().resolvedOptions().timeZone;
    } catch (error) {
      console.error("Error getting timezone from address:", error);
      // Return user's local timezone as fallback
      return Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
  };

  const handleSendCallSheetNotifications = async (item) => {
    try {
      const pdfBase64 = await generateCallSheetPDF();
      const timezone = await getTimezoneFromAddress(item.address || "");
      const chunkSize = 5 * 1024 * 1024;
      const chunks = [];

      if (pdfBase64 && pdfBase64.length > chunkSize) {
        for (let i = 0; i < pdfBase64.length; i += chunkSize) {
          chunks.push(pdfBase64.slice(i, i + chunkSize));
        }

        // Send metadata first with timezone
        const metadataResponse = await fetch(
          "https://54rr0rbk9h.execute-api.us-east-1.amazonaws.com/prod/send-message",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              item: {
                ...item,
                timezone,
                totalChunks: chunks.length,
                isMetadata: true,
              },
            }),
          }
        );

        if (!metadataResponse.ok) {
          throw new Error("Failed to send metadata");
        }

        // Send each chunk
        for (let i = 0; i < chunks.length; i++) {
          const response = await fetch(
            "https://54rr0rbk9h.execute-api.us-east-1.amazonaws.com/prod/send-message",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                item: {
                  ...item,
                  timezone,
                  callSheetPdf: chunks[i],
                  chunkIndex: i,
                  totalChunks: chunks.length,
                },
              }),
            }
          );

          if (!response.ok) {
            throw new Error(`Failed to send chunk ${i}`);
          }
        }
      } else {
        // Send as single request if small enough
        const response = await fetch(
          "https://54rr0rbk9h.execute-api.us-east-1.amazonaws.com/prod/send-message",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              item: {
                ...item,
                timezone,
                callSheetPdf: pdfBase64,
              },
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to send call sheet notifications");
        }
      }
    } catch (error) {
      console.error("Error sending call sheet notifications:", error);
    }
  };

  const saveMemo = async () => {
    const { data, error } = await supabase
      .from("CallSheets")
      .update({ memo: memo })
      .eq("id", callSheet.id);

    if (error) {
      console.log(error);
    } else {
      getCallSheet();
    }
  };

  const handleCancelRow = (rowId) => {
    setEditingRows((prev) => ({
      ...prev,
      [rowId]: false,
    }));
    getCallSheet(); // Refresh data to revert changes
  };

  const renderTableRow = (item) => {
    const callSheetItem = callSheetItems.find((csi) => csi.role_id === item.id);
    const isEditing = editingRows[item.id];
    const hasFullEditAccess = canEditAllFields(item);
    const isOwnRole = item.user === user.id;

    return (
      <TableRow
        key={item.id}
        sx={{
          "&:last-child td, &:last-child th": {
            border: 0,
          },
        }}
      >
        <TableCell>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Avatar
              alt={item.name}
              className="team-avatar"
              {...stringAvatar(
                `${item.Users.first_name} ${item.Users.last_name}`
              )}
              src={item.Users.profile_picture}
            />
            <Typography>
              {`${item.Users.first_name} ${item.Users.last_name}`}
            </Typography>
          </Box>
        </TableCell>
        <TableCell>
          <Chip label={t(item.name)} />
        </TableCell>
        <TableCell>
          {isEditing && hasFullEditAccess ? (
            <TextField
              size="small"
              label="Address"
              value={callSheetItem?.address || ""}
              onChange={(e) =>
                handleTeamChange(item.id, "address", e.target.value)
              }
              sx={{ width: "200px" }}
            />
          ) : (
            <Typography>{callSheetItem?.address || "-"}</Typography>
          )}
        </TableCell>
        <TableCell>
          {isEditing && hasFullEditAccess ? (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                label="Call Time"
                value={
                  callSheetItem?.start_time
                    ? dayjs(callSheetItem.start_time)
                    : null
                }
                onChange={(newValue) => {
                  handleTeamChange(item.id, "start_time", newValue);
                }}
                slotProps={{
                  textField: {
                    size: "small",
                    onKeyDown: (e) => {
                      e.stopPropagation();
                    },
                  },
                }}
                sx={{ width: "150px" }}
              />
            </LocalizationProvider>
          ) : (
            <Typography>
              {callSheetItem?.start_time
                ? dayjs(callSheetItem.start_time).format("hh:mm A")
                : "-"}
            </Typography>
          )}
        </TableCell>
        <TableCell>
          {isEditing && hasFullEditAccess ? (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                label="Wrap Time"
                value={
                  callSheetItem?.wrap_time
                    ? dayjs(callSheetItem.wrap_time)
                    : null
                }
                onChange={(newValue) => {
                  handleTeamChange(item.id, "wrap_time", newValue);
                }}
                slotProps={{
                  textField: {
                    size: "small",
                    onKeyDown: (e) => {
                      e.stopPropagation();
                    },
                  },
                }}
                sx={{ width: "150px" }}
              />
            </LocalizationProvider>
          ) : (
            <Typography>
              {callSheetItem?.wrap_time
                ? dayjs(callSheetItem.wrap_time).format("hh:mm A")
                : "-"}
            </Typography>
          )}
        </TableCell>
        <TableCell align="center">
          {isEditing && (isOwnRole || hasFullEditAccess) ? (
            <Checkbox
              checked={confirmations[item.id] || false}
              onChange={(e) =>
                handleConfirmationChange(item.id, e.target.checked)
              }
              sx={{
                color: "#9f7fe3",
                "&.Mui-checked": {
                  color: "#9f7fe3",
                },
              }}
            />
          ) : confirmations[item.id] ? (
            <CheckCircleIcon
              sx={{
                color: "#9f7fe3",
                fontSize: "20px",
              }}
            />
          ) : (
            <CancelIcon
              sx={{
                color: "#ff4444",
                fontSize: "20px",
              }}
            />
          )}
        </TableCell>
        <TableCell align="center">
          {isEditing ? (
            <Box sx={{ display: "flex", gap: 1, justifyContent: "center" }}>
              <Button
                onClick={() => handleSaveRow(item.id)}
                sx={{
                  color: "#000000 !important",
                  backgroundColor: "#ffffff !important",
                  borderRadius: "14px",
                  boxShadow: "rgba(0, 0, 0, 0.35) 0px 0px 1px",
                  fontWeight: "300",
                  fontSize: "10px",
                }}
                variant="contained"
              >
                Save
              </Button>
              <Button
                onClick={() => handleCancelRow(item.id)}
                sx={{
                  color: "#000000 !important",
                  backgroundColor: "#ffffff !important",
                  borderRadius: "14px",
                  boxShadow: "rgba(0, 0, 0, 0.35) 0px 0px 1px",
                  fontWeight: "300",
                  fontSize: "10px",
                }}
                variant="contained"
              >
                Cancel
              </Button>
            </Box>
          ) : (
            (hasFullEditAccess || isOwnRole) && (
              <IconButton
                size="small"
                onClick={() => handleEditRow(item.id)}
                sx={{ color: "#9b9b9b" }}
              >
                <EditIcon fontSize="small" />
              </IconButton>
            )
          )}
        </TableCell>
      </TableRow>
    );
  };

  const renderMobileTeamView = () => (
    <Container
      sx={{
        padding: "15px",
        boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
        border: "1px solid rgba(0,0,0,0.08)",
        borderRadius: "10px",
        height: "100%",
        margin: "0px !important",
        width: "100%",
        maxWidth: "none !important",
      }}
    >
      <Typography sx={{ fontWeight: "400", fontSize: "18px", mb: 3 }}>
        {t("team")}
      </Typography>

      {/* Project Owner Card */}
      {project.Users && (
        <Box
          sx={{
            p: 2,
            mb: 2,
            borderRadius: "10px",
            border: "1px solid rgba(0,0,0,0.08)",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 2, mb: 1 }}>
            <Avatar
              alt={`${project.Users.first_name} ${project.Users.last_name}`}
              src={project.Users.profile_picture}
              sx={{ width: 50, height: 50 }}
            />
            <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
              <Typography sx={{ fontWeight: "400" }}>
                {`${project.Users.first_name} ${project.Users.last_name}`}
              </Typography>
              <Chip label="Project Owner" size="small" />
            </Box>
          </Box>
          {project.Users.id !== currentUser.sub && (
            <Button
              startIcon={<ChatIcon />}
              variant="contained"
              size="small"
              sx={{ mt: 1, backgroundColor: "#ffffff", color: "#000000" }}
              onClick={() =>
                navigate(`/chat`, { state: { recipientId: project.Users.id } })
              }
            >
              {t("message")}
            </Button>
          )}
        </Box>
      )}

      {/* Team Members */}
      {team
        ?.filter((item) => item.user)
        .map((item) => (
          <Box
            key={item.id}
            sx={{
              p: 2,
              mb: 2,
              borderRadius: "10px",
              border: "1px solid rgba(0,0,0,0.08)",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 2, mb: 1 }}>
              <Avatar
                alt={item.name}
                src={item.Users.profile_picture}
                sx={{ width: 50, height: 50 }}
              />
              <Box
                sx={{ display: "flex", alignItems: "center", gap: 2, mb: 1 }}
              >
                <Typography sx={{ fontWeight: "400" }}>
                  {`${item.Users.first_name} ${item.Users.last_name}`}
                </Typography>
                <Chip label={item.name} size="small" />
                {project.type === "paid" &&
                  (item.user === currentUser.sub ||
                    project.owner === currentUser.sub ||
                    user.account_type === "superadmin") && (
                    <Chip
                      variant="outlined"
                      size="small"
                      sx={{ mt: 0.5, ml: 0.5 }}
                      label={formatCurrency(
                        item.budget || 0,
                        i18n.language === "ko" ? "KRW" : "USD"
                      )}
                    />
                  )}
              </Box>
            </Box>
            <Box sx={{ display: "flex", gap: 1, mt: 1 }}>
              {item.user !== currentUser.sub && (
                <Button
                  startIcon={<ChatIcon />}
                  variant="contained"
                  size="small"
                  sx={{ mt: 1, backgroundColor: "#ffffff", color: "#000000" }}
                  onClick={() =>
                    navigate(`/chat`, { state: { recipientId: item.Users.id } })
                  }
                >
                  {t("message")}
                </Button>
              )}
              {(project.owner === currentUser.sub ||
                user.account_type === "superadmin") && (
                <Button
                  startIcon={<RemoveCircleIcon />}
                  variant="outlined"
                  size="small"
                  fullWidth
                  color="error"
                  onClick={() => {
                    setSelectedRoleId(item.id);
                    setSelectedUserId(item.Users.id);
                    setOpenConfirmDialog(true);
                  }}
                >
                  {t("remove")}
                </Button>
              )}
            </Box>
          </Box>
        ))}

      {/* Open Roles Section */}
      <Typography sx={{ fontWeight: "400", fontSize: "18px", my: 3 }}>
        {t("openRoles")}
      </Typography>

      {team
        ?.filter((item) => !item.user)
        .map((item) => (
          <Box
            key={item.id}
            sx={{
              p: 2,
              mb: 2,
              borderRadius: "10px",
              border: "1px solid rgba(0,0,0,0.08)",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 2, mb: 1 }}>
              <Avatar>
                <PersonIcon />
              </Avatar>
              <Box>
                <Chip label={item.name} size="small" />
                {(project.owner === currentUser.sub ||
                  user.account_type === "superadmin") && (
                  <Typography sx={{ fontSize: "12px", mt: 0.5 }}>
                    {item.submissions ? item.submissions.length : 0}{" "}
                    {t("applicants")}
                  </Typography>
                )}
              </Box>
            </Box>
            {item.submissions && item.submissions.length > 0 && (
              <Button
                fullWidth
                variant="outlined"
                size="small"
                sx={{ mt: 1 }}
                onClick={() =>
                  navigateToViewSubmissionsPage(
                    item.id,
                    item.submissions,
                    item.name
                  )
                }
              >
                {t("viewSubmissions")}
              </Button>
            )}
          </Box>
        ))}
    </Container>
  );

  const previewCallSheet = async () => {
    try {
      const pdfBase64 = await generateCallSheetPDF();
      if (pdfBase64) {
        // Create a Blob from the base64 data
        const byteCharacters = atob(pdfBase64);
        const byteNumbers = new Array(byteCharacters.length);

        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: "application/pdf" });

        // Create a URL for the blob
        const url = URL.createObjectURL(blob);

        // Open in a new window/tab
        window.open(url, "_blank");

        // Clean up the URL object after the window is loaded
        setTimeout(() => {
          URL.revokeObjectURL(url);
        }, 100);
      }
    } catch (error) {
      console.error("Error previewing PDF:", error);
    }
  };

  return (
    <Box sx={{ flexGrow: 1, bgcolor: "#ffffff" }}>
      {!rateLoading && project ? (
        <Box
          className="custom-margins-submissions"
          sx={{
            display: "flex",
            flexDirection: "column",
            paddingBottom: "3rem",
            paddingTop: "0.5rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              marginBottom: "30px",
            }}
          >
            <Box>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0px",
                }}
              >
                <Button
                  onClick={() => navigate(-1)}
                  startIcon={<ArrowBackIcon />}
                  sx={{
                    color: "#000000",
                    height: "30px",
                    backgroundColor: "#ffffff",
                    padding: "2px 12px 2px 12px",
                    borderRadius: "14px",
                    boxShadow: "rgba(0, 0, 0, 0.35) 0px 0px 1px",
                    fontWeight: "400",
                    fontSize: "12px",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "#f5f5f5",
                    },
                    marginRight: "14px",
                  }}
                >
                  {t("back")}
                </Button>
                <Typography
                  sx={{
                    textAlign: "left",
                    fontWeight: "400",
                    fontSize: "26px",
                    marginRight: "8px",
                  }}
                >
                  {project.name}
                </Typography>
                {(project.owner === user.id ||
                  user.account_type === "superadmin" ||
                  project.ProjectRoles?.some(
                    (role) =>
                      role.name === "Project Manager" && role.user === user.id
                  )) && (
                  <IconButton
                    aria-label="more"
                    onClick={(event) => handleMenuClick(event)}
                  >
                    <MoreVertIcon />
                  </IconButton>
                )}
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={() => setAnchorEl(null)}
                >
                  <MenuItem
                    onClick={() => {
                      navigate(`/editproject/${projectId}`);
                      setAnchorEl(null);
                    }}
                  >
                    <ListItemIcon>
                      <EditRoundedIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Edit</ListItemText>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setOpenDeleteProjectDialog(true);
                      setAnchorEl(null);
                    }}
                  >
                    <ListItemIcon>
                      <DeleteIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Delete</ListItemText>
                  </MenuItem>
                </Menu>
                {(project.owner === user.id ||
                  user.account_type === "superadmin") &&
                  project.status !== "live" && (
                    <Tooltip title="Start Project" placement="right">
                      <IconButton
                        sx={{ color: "#9f7fe3" }}
                        onClick={handlePlayClick}
                      >
                        <PlayCircleFilledIcon />
                      </IconButton>
                    </Tooltip>
                  )}
              </div>

              {showAlert && (
                <div
                  style={{
                    backgroundColor: "#9f7fe3",
                    color: "#ffffff",
                    width: "fit-content",
                    padding: "10px 14px 10px 14px",
                    fontSize: "12px",
                    borderRadius: "20px",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  You must fill all roles before starting a project!
                </div>
              )}

              <Dialog
                open={showPaymentDialog}
                onClose={() => setShowPaymentDialog(false)}
                aria-labelledby="confirm-dialog-title"
                aria-describedby="confirm-dialog-description"
                PaperProps={{
                  sx: {
                    padding: "10px", // Add padding to the entire dialog
                  },
                }}
              >
                <DialogTitle id="confirm-dialog-title">
                  {t("paymentDialog")}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="confirm-dialog-description">
                    {t("paymentDialogPrompt")}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    sx={{ fontSize: "10px !important" }}
                    onClick={() => setShowPaymentDialog(false)}
                  >
                    {t("cancel")}
                  </Button>
                  <Button
                    onClick={() => {
                      handleCheckout();
                      setShowPaymentDialog(false);
                    }}
                    sx={{ fontSize: "10px !important" }}
                    color="primary"
                    autoFocus
                  >
                    {t("proceed")}
                  </Button>
                </DialogActions>
              </Dialog>
              <Box
                sx={{
                  display: "flex",
                  gap: 4,
                  marginTop: "1rem",
                  marginBottom: "2rem",
                }}
              >
                {(project.owner === currentUser.sub ||
                  currentUser.sub === "1910a5d2-ab7d-4fed-b85e-025cbb5f526c" ||
                  team?.some(
                    (role) =>
                      role.name === "Project Manager" &&
                      role.user === currentUser.sub
                  )) && (
                  <Typography
                    onClick={() => {
                      setActiveTab("dashboard");
                    }}
                    sx={{
                      cursor: "pointer",
                      position: "relative",
                      padding: "6px 8px 6px 8px",
                      fontSize: "14px",
                      fontWeight: activeTab === "dashboard" ? "400" : "300",
                      transition: "all 0.3s ease",
                      opacity: activeTab === "dashboard" ? 1 : 0.7,
                      transform:
                        activeTab === "dashboard" ? "translateY(-2px)" : "none",
                      "&:hover": {
                        opacity: 1,
                        transform: "translateY(-2px)",
                        "&::after": {
                          width: "100%", // Expand to full width on hover
                        },
                      },
                      "&::after": {
                        content: '""',
                        position: "absolute",
                        bottom: 0,
                        left: "50%", // Start at the center
                        transform: "translateX(-50%)",
                        height: "2px",
                        width: activeTab === "dashboard" ? "100%" : "0", // Match active state
                        backgroundColor: "#9F7FE3",
                        transition: "width 0.8s ease", // Smooth expansion
                      },
                    }}
                  >
                    {t("dashboard")}
                  </Typography>
                )}
                <Typography
                  onClick={() => {
                    setActiveTab("team");
                  }}
                  sx={{
                    cursor: "pointer",
                    position: "relative",
                    padding: "6px 8px 6px 8px",
                    fontSize: "14px",
                    fontWeight: activeTab === "team" ? "400" : "300",
                    transition: "all 0.3s ease",
                    opacity: activeTab === "team" ? 1 : 0.7,
                    transform:
                      activeTab === "team" ? "translateY(-2px)" : "none",
                    "&:hover": {
                      opacity: 1,
                      transform: "translateY(-2px)",
                      "&::after": {
                        width: "100%", // Expand to full width on hover
                      },
                    },
                    "&::after": {
                      content: '""',
                      position: "absolute",
                      bottom: 0,
                      left: "50%", // Start at the center
                      transform: "translateX(-50%)",
                      height: "2px",
                      width: activeTab === "team" ? "100%" : "0", // Match active state
                      backgroundColor: "#9F7FE3",
                      transition: "width 0.8s ease", // Smooth expansion
                    },
                  }}
                >
                  {t("team")}
                </Typography>

                <Typography
                  onClick={() => {
                    setActiveTab("callsheet");
                  }}
                  sx={{
                    cursor: "pointer",
                    position: "relative",
                    padding: "6px 8px 6px 8px",
                    fontSize: "14px",
                    fontWeight: activeTab === "callsheet" ? "400" : "300",
                    transition: "all 0.3s ease",
                    opacity: activeTab === "callsheet" ? 1 : 0.7,
                    transform:
                      activeTab === "callsheet" ? "translateY(-2px)" : "none",
                    "&:hover": {
                      opacity: 1,
                      transform: "translateY(-2px)",
                      "&::after": {
                        width: "100%", // Expand to full width on hover
                      },
                    },
                    "&::after": {
                      content: '""',
                      position: "absolute",
                      bottom: 0,
                      left: "50%", // Start at the center
                      transform: "translateX(-50%)",
                      height: "2px",
                      width: activeTab === "callsheet" ? "100%" : "0", // Match active state
                      backgroundColor: "#9F7FE3",
                      transition: "width 0.8s ease", // Smooth expansion
                    },
                  }}
                >
                  {t("callsheet")}
                </Typography>
                <Typography
                  onClick={() => {
                    setActiveTab("chat");
                  }}
                  sx={{
                    cursor: "pointer",
                    position: "relative",
                    padding: "6px 8px 6px 8px",
                    fontSize: "14px",
                    fontWeight: activeTab === "chat" ? "400" : "300",
                    transition: "all 0.3s ease",
                    opacity: activeTab === "chat" ? 1 : 0.7,
                    transform:
                      activeTab === "chat" ? "translateY(-2px)" : "none",
                    "&:hover": {
                      opacity: 1,
                      transform: "translateY(-2px)",
                      "&::after": {
                        width: "100%", // Expand to full width on hover
                      },
                    },
                    "&::after": {
                      content: '""',
                      position: "absolute",
                      bottom: 0,
                      left: "50%", // Start at the center
                      transform: "translateX(-50%)",
                      height: "2px",
                      width: activeTab === "chat" ? "100%" : "0", // Match active state
                      backgroundColor: "#9F7FE3",
                      transition: "width 0.8s ease", // Smooth expansion
                    },
                  }}
                >
                  {t("chat")}
                </Typography>
              </Box>

              {activeTab === "dashboard" && (
                <Box
                  sx={{
                    display: "flex",
                    gap: "30px",
                    marginBottom: "2rem",
                    flexWrap: "wrap",
                    justifyContent: {
                      xs: "center",
                      sm: "center",
                      md: "flex-start",
                    },
                  }}
                >
                  {project.type === "paid" && (
                    <Card
                      sx={{
                        width: "210px",
                        height: "202px",
                        display: "flex",
                        boxShadow: "none",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#ffffff",
                        borderRadius: "12px",
                        border: "1px solid rgba(0,0,0,0.08)",
                        transition: "all 0.3s ease",
                        "&:hover": {
                          transform: "translateY(-4px)",
                          boxShadow: "0 8px 16px rgba(0,0,0,0.08)",
                          borderColor: "rgba(0,0,0,0.12)",
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "34px",
                          fontWeight: "600",
                          color: "#333",
                          marginBottom: "6px",
                        }}
                      >
                        {totalCost.formatted}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "11px",
                          color: "#666",
                          fontWeight: "300",
                        }}
                      >
                        {t("estimatedTotalCost")}
                      </Typography>
                      <Box
                        sx={{
                          width: "56px",
                          height: "3px",
                          backgroundColor: "#9f7fe3",
                          marginTop: "11px",
                          marginBottom: "11px",
                          borderRadius: "2px",
                          animation: "expandFromCenter 0.8s ease-out",
                          "@keyframes expandFromCenter": {
                            "0%": {
                              width: "0px",
                              marginLeft: "28px",
                            },
                            "100%": {
                              width: "56px",
                              marginLeft: "0px",
                            },
                          },
                        }}
                      />
                    </Card>
                  )}
                  {project.type === "paid" && (
                    <Card
                      sx={{
                        width: "210px",
                        height: "202px",
                        display: "flex",
                        boxShadow: "none",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#ffffff",
                        borderRadius: "12px",
                        border: "1px solid rgba(0,0,0,0.08)",
                        transition: "all 0.3s ease",
                        "&:hover": {
                          transform: "translateY(-4px)",
                          boxShadow: "0 8px 16px rgba(0,0,0,0.08)",
                          borderColor: "rgba(0,0,0,0.12)",
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "34px",
                          fontWeight: "600",
                          color: "#333",
                          marginBottom: "6px",
                        }}
                      >
                        {totalPaid.formatted}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "11px",
                          color: "#666",
                          fontWeight: "300",
                        }}
                      >
                        {t("amountPaid")}
                      </Typography>
                      <Box
                        sx={{
                          width: "56px",
                          height: "3px",
                          backgroundColor: "#4CAF50",
                          marginTop: "11px",
                          marginBottom: "11px",
                          borderRadius: "2px",
                          animation: "expandFromCenter 0.8s ease-out",
                          "@keyframes expandFromCenter": {
                            "0%": {
                              width: "0px",
                              marginLeft: "28px",
                            },
                            "100%": {
                              width: "56px",
                              marginLeft: "0px",
                            },
                          },
                        }}
                      />
                    </Card>
                  )}
                  <Card
                    sx={{
                      width: "210px",
                      height: "202px",
                      display: "flex",
                      boxShadow: "none",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "#ffffff",
                      borderRadius: "12px",
                      border: "1px solid rgba(0,0,0,0.08)",
                      transition: "all 0.3s ease",
                      "&:hover": {
                        transform: "translateY(-4px)",
                        boxShadow: "0 8px 16px rgba(0,0,0,0.08)",
                        borderColor: "rgba(0,0,0,0.12)",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        height: "56px",
                        display: "flex",
                        alignItems: "flex-end",
                        marginBottom: "6px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "34px",
                          fontWeight: "600",
                          color: "#333",
                          lineHeight: 1,
                        }}
                      >
                        {project?.ProjectRoles?.length || 0}
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        fontSize: "11px",
                        color: "#666",
                        fontWeight: "300",
                      }}
                    >
                      {t("people")}
                    </Typography>
                    <GroupsIcon
                      sx={{
                        fontSize: 30,
                        color: "#c7c7c7",
                        marginBottom: "8px",
                        animation:
                          "bounceIn 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55)",
                        "@keyframes bounceIn": {
                          "0%": {
                            opacity: 0,
                            transform: "scale(0.3)",
                          },
                          "50%": {
                            opacity: 0.9,
                            transform: "scale(1.1)",
                          },
                          "70%": {
                            transform: "scale(0.9)",
                          },
                          "100%": {
                            opacity: 1,
                            transform: "scale(1)",
                          },
                        },
                      }}
                    />
                  </Card>
                  {project.duration && project.duration_type && (
                    <Card
                      sx={{
                        width: "210px",
                        height: "202px",
                        display: "flex",
                        boxShadow: "none",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#ffffff",
                        borderRadius: "12px",
                        border: "1px solid rgba(0,0,0,0.08)",
                        transition: "all 0.3s ease",
                        "&:hover": {
                          transform: "translateY(-4px)",
                          boxShadow: "0 8px 16px rgba(0,0,0,0.08)",
                          borderColor: "rgba(0,0,0,0.12)",
                        },
                      }}
                    >
                      <Box
                        sx={{
                          height: "56px",
                          display: "flex",
                          alignItems: "flex-end",
                          marginBottom: "6px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "34px",
                            fontWeight: "600",
                            color: "#333",
                            lineHeight: 1,
                          }}
                        >
                          {project.duration}
                        </Typography>
                      </Box>
                      <Typography
                        sx={{
                          fontSize: "11px",
                          color: "#666",
                          fontWeight: "300",
                        }}
                      >
                        {project.duration_type}
                      </Typography>
                      <TimelapseIcon
                        sx={{
                          fontSize: 30,
                          color: "#c7c7c7",
                          marginBottom: "8px",
                          animation:
                            "bounceIn 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55)",
                          "@keyframes bounceIn": {
                            "0%": {
                              opacity: 0,
                              transform: "scale(0.3)",
                            },
                            "50%": {
                              opacity: 0.9,
                              transform: "scale(1.1)",
                            },
                            "70%": {
                              transform: "scale(0.9)",
                            },
                            "100%": {
                              opacity: 1,
                              transform: "scale(1)",
                            },
                          },
                        }}
                      />
                    </Card>
                  )}
                </Box>
              )}
            </Box>

            {activeTab === "team" &&
              (isMobile ? (
                renderMobileTeamView()
              ) : (
                <Container
                  sx={{
                    padding: "30px !important",
                    boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                    border: "1px solid rgba(0,0,0,0.08)",
                    borderRadius: "10px",
                    height: "100%",
                    minHeight: "350px",
                    margin: "0px !important",
                    width: "100%",
                    maxWidth: "none !important",
                  }}
                >
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography
                      sx={{
                        textAlign: "left",
                        fontWeight: "400",
                        fontSize: "22px",
                      }}
                    >
                      {t("team")}
                    </Typography>
                  </Box>

                  {project.Users && (
                    <Container
                      sx={{
                        padding: "0px !important",
                        display: "flex",
                        alignItems: "center",
                        marginTop: "20px",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 2,
                          flex: 1,
                        }}
                      >
                        {project.Users.pro ? (
                          <Badge
                            overlap="circular"
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            badgeContent={
                              <SmallAvatar>
                                <VerifiedIcon sx={{ fontSize: 12 }} />
                              </SmallAvatar>
                            }
                          >
                            <Avatar
                              alt={`${project.Users.first_name} ${project.Users.last_name}`}
                              className="team-avatar"
                              sx={{
                                bgcolor: "#bdbdbd",
                                width: 40,
                                height: 40,
                                border: "2px solid #fff",
                                boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                                transition: "transform 0.2s ease-in-out",
                                "&:hover": {
                                  transform: "scale(1.05)",
                                  cursor: "pointer",
                                },
                              }}
                              src={project.Users.profile_picture}
                            />
                          </Badge>
                        ) : (
                          <Avatar
                            alt={`${project.Users.first_name} ${project.Users.last_name}`}
                            className="team-avatar"
                            sx={{
                              bgcolor: "#bdbdbd",
                              width: 40,
                              height: 40,
                              border: "2px solid #fff",
                              boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                              transition: "transform 0.2s ease-in-out",
                              "&:hover": {
                                transform: "scale(1.05)",
                                cursor: "pointer",
                              },
                            }}
                            src={project.Users.profile_picture}
                          />
                        )}
                        <Typography
                          sx={{
                            fontWeight: "300",
                            minWidth: "150px",
                          }}
                        >
                          {`${project.Users.first_name} ${project.Users.last_name}`}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            gap: 1,
                            alignItems: "center",
                            minWidth: "150px",
                            flexWrap: "wrap",
                          }}
                        >
                          <Chip label={t("projectOwner")} />
                          {team
                            ?.filter((role) => role.user === project.Users.id)
                            .map((role) => (
                              <Chip key={role.id} label={t(role.name)} />
                            ))}
                        </Box>
                      </Box>

                      <Box sx={{ display: "flex", gap: 1 }}>
                        {project.Users.id !== currentUser.sub && (
                          <IconButton
                            aria-label="message"
                            size="small"
                            onClick={() => {
                              navigate(`/chat`, {
                                state: { recipientId: project.Users.id },
                              });
                            }}
                          >
                            <ChatIcon fontSize="inherit" />
                          </IconButton>
                        )}
                      </Box>
                    </Container>
                  )}

                  {team &&
                    team
                      .filter(
                        (item) =>
                          // Filter out any roles that belong to the project owner
                          item.user !== project.Users.id
                      )
                      .map((item) =>
                        item.user ? (
                          <Container
                            key={item.id + item.role}
                            sx={{
                              padding: "0px !important",
                              display: "flex",
                              alignItems: "center",
                              marginTop: "20px",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 2,
                                flex: 1,
                              }}
                            >
                              {item.Users.pro ? (
                                <Badge
                                  overlap="circular"
                                  anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                  }}
                                  badgeContent={
                                    <SmallAvatar>
                                      <VerifiedIcon sx={{ fontSize: 12 }} />
                                    </SmallAvatar>
                                  }
                                >
                                  <Avatar
                                    alt={item.name}
                                    className="team-avatar"
                                    sx={{
                                      bgcolor: "#bdbdbd",
                                      width: 40,
                                      height: 40,
                                      border: "2px solid #fff",
                                      boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                                      transition: "transform 0.2s ease-in-out",
                                      "&:hover": {
                                        transform: "scale(1.05)",
                                        cursor: "pointer",
                                      },
                                    }}
                                    src={item.Users.profile_picture}
                                  />
                                </Badge>
                              ) : (
                                <Avatar
                                  alt={item.name}
                                  className="team-avatar"
                                  sx={{
                                    bgcolor: "#bdbdbd",
                                    width: 40,
                                    height: 40,
                                    border: "2px solid #fff",
                                    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                                    transition: "transform 0.2s ease-in-out",
                                    "&:hover": {
                                      transform: "scale(1.05)",
                                      cursor: "pointer",
                                    },
                                  }}
                                  src={item.Users.profile_picture}
                                />
                              )}
                              <Typography
                                sx={{
                                  fontWeight: "300",
                                  minWidth: "150px",
                                }}
                              >
                                {`${item.Users.first_name} ${item.Users.last_name}`}
                              </Typography>
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: 1,
                                  alignItems: "center",
                                  minWidth: "150px",
                                  flexWrap: "wrap",
                                }}
                              >
                                <Chip label={t(item.name)} />
                              </Box>
                              {(item.user === currentUser.sub ||
                                project.owner === currentUser.sub ||
                                user.account_type === "superadmin" ||
                                project.ProjectRoles?.some(
                                  (role) =>
                                    role.name === "Project Manager" &&
                                    role.user === currentUser.sub
                                )) &&
                                project.type === "paid" && (
                                  <React.Fragment>
                                    <div style={{ minWidth: "100px" }}>
                                      <Chip
                                        variant="outlined"
                                        label={formatCurrency(
                                          item.budget || 0,
                                          i18n.language === "ko" ? "KRW" : "USD"
                                        )}
                                      />
                                    </div>
                                  </React.Fragment>
                                )}
                            </Box>

                            <Box sx={{ display: "flex", gap: 1 }}>
                              {item.user !== currentUser.sub && (
                                <>
                                  <Tooltip placement="top" title={t("message")}>
                                    <IconButton
                                      aria-label="message"
                                      size="small"
                                      onClick={() => {
                                        navigate(`/chat`, {
                                          state: { recipientId: item.Users.id },
                                        });
                                      }}
                                    >
                                      <ChatIcon fontSize="inherit" />
                                    </IconButton>
                                  </Tooltip>

                                  {(project.owner === currentUser.sub ||
                                    user.account_type === "superadmin" ||
                                    project.ProjectRoles?.some(
                                      (role) =>
                                        role.name === "Project Manager" &&
                                        role.user === currentUser.sub
                                    )) && (
                                    <Tooltip
                                      placement="top"
                                      title={t("removeTeamMember")}
                                    >
                                      <IconButton
                                        aria-label="delete"
                                        size="small"
                                        onClick={() => {
                                          setSelectedRoleId(item.id);
                                          setSelectedUserId(item.Users.id);
                                          setOpenConfirmDialog(true);
                                        }}
                                      >
                                        <RemoveCircleIcon fontSize="inherit" />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                </>
                              )}
                            </Box>
                          </Container>
                        ) : null
                      )}
                  {/* Confirmation Dialog */}
                  <Dialog
                    open={openConfirmDialog}
                    onClose={() => setOpenConfirmDialog(false)}
                    aria-labelledby="confirm-dialog-title"
                    aria-describedby="confirm-dialog-description"
                    PaperProps={{
                      sx: {
                        padding: "10px", // Add padding to the entire dialog
                      },
                    }}
                  >
                    <DialogTitle id="confirm-dialog-title">
                      {t("removeTeamMember")}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="confirm-dialog-description">
                        {t("removeTeamMemberPrompt")}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        sx={{ fontSize: "10px !important" }}
                        onClick={() => setOpenConfirmDialog(false)}
                      >
                        {t("cancel")}
                      </Button>
                      <Button
                        onClick={() => {
                          handleRemoveTeamMember(
                            selectedRoleId,
                            selectedUserId
                          );
                          setOpenConfirmDialog(false);
                        }}
                        color="primary"
                        autoFocus
                        sx={{ fontSize: "10px !important" }}
                      >
                        {t("confirm")}
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <Divider sx={{ marginTop: "20px" }} variant="middle" />
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{
                      textAlign: "left",
                      fontWeight: "400",
                      marginTop: "20px",
                    }}
                  >
                    {t("openRoles")}
                  </Typography>
                  {team && team.some((item) => !item.user) ? (
                    team.map((item) =>
                      !item.user ? (
                        <Container
                          key={item.id}
                          sx={{
                            padding: "0px !important",
                            display: "flex",
                            alignItems: "center",
                            marginTop: "20px",
                            gap: "20px",
                          }}
                        >
                          <Avatar>
                            <PersonIcon />
                          </Avatar>{" "}
                          <Chip label={t(item.name)} />
                          {(project.owner === currentUser.sub ||
                            currentUser.sub ===
                              "1910a5d2-ab7d-4fed-b85e-025cbb5f526c" ||
                            team?.some(
                              (role) =>
                                role.name === "Project Manager" &&
                                role.user === currentUser.sub
                            )) && (
                            <>
                              <Typography
                                sx={{ fontSize: "10px", fontWeight: "300" }}
                              >
                                {item.submissions ? item.submissions.length : 0}{" "}
                                {t("applicants")}
                              </Typography>
                              {item.submissions &&
                                item.submissions.length > 0 && (
                                  <Button
                                    onClick={() =>
                                      navigateToViewSubmissionsPage(
                                        item.id,
                                        item.submissions,
                                        item.name
                                      )
                                    }
                                    sx={{
                                      color: "#000000 !important",
                                      backgroundColor: "#ffffff !important",
                                      borderRadius: "14px",
                                      boxShadow:
                                        "rgba(0, 0, 0, 0.35) 0px 0px 1px",
                                      fontWeight: "300",
                                      fontSize: "10px",
                                    }}
                                    variant="contained"
                                  >
                                    {t("viewSubmissions")}
                                  </Button>
                                )}
                            </>
                          )}
                        </Container>
                      ) : null
                    )
                  ) : (
                    <Typography
                      sx={{
                        marginTop: "20px",
                        color: "#666",
                        fontSize: "12px",
                      }}
                    >
                      {t("noOpenRoles")}
                    </Typography>
                  )}

                  {/* <Divider sx={{ marginTop: "20px" }} variant="middle" />
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{
                      textAlign: "left",
                      fontWeight: "400",
                      marginTop: "20px",
                    }}
                  >
                    Filled Roles
                  </Typography>
                  {submissions &&
                    submissions
                      .filter((item) => item.filled === true) // Filter items where filled is true
                      .map((item) => (
                        <Container
                          key={item.roleId}
                          sx={{
                            padding: "0px !important",
                            display: "flex",
                            alignItems: "center",
                            marginTop: "20px",
                            gap: "20px",
                          }}
                        >
                          <Avatar>
                            <PersonIcon />
                          </Avatar>{" "}
                          <Chip label={item.roleName} />
                          <Typography variant="overline">
                            {item.count} applicants
                          </Typography>
                          {item.items && item.items.length > 0 && (
                            <Button
                              onClick={() =>
                                navigateToViewSubmissionsPage(
                                  item.roleId,
                                  item.items,
                                  item.roleName
                                )
                              }
                            >
                              View Submissions
                            </Button>
                          )}
                        </Container>
                      ))} */}
                </Container>
              ))}
            {activeTab === "callsheet" && (
              <Container
                sx={{
                  padding: "30px",
                  boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                  border: "1px solid rgba(0,0,0,0.08)",
                  borderRadius: "10px",
                  height: "100%",
                  minHeight: "350px",
                  margin: "0",
                  maxWidth: "none !important",
                  width: "100%",
                  overflow: "hidden",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "30px",
                  }}
                >
                  <Typography
                    sx={{
                      textAlign: "left",
                      fontWeight: "400",
                      fontSize: "22px",
                    }}
                  >
                    {t("callsheet")}
                  </Typography>
                </Box>

                {project.projectDate && (
                  <Box sx={{ mb: 3 }}>
                    <Typography sx={{ mb: 1 }}>
                      {dayjs(project.projectDate).format("dddd, MMMM D, YYYY")}
                    </Typography>
                  </Box>
                )}

                <TableContainer>
                  <Table sx={{ minWidth: 650 }}>
                    <TableHead>
                      <TableRow>
                        <TableCell>{t("teamMember")}</TableCell>
                        <TableCell>{t("role")}</TableCell>
                        <TableCell>{t("address")}</TableCell>
                        <TableCell>{t("callTime")}</TableCell>
                        <TableCell>{t("wrapTime")}</TableCell>
                        <TableCell align="center">{t("confirmed")}</TableCell>
                        <TableCell align="center">{t("edit")}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {team
                        ?.filter((item) => item.Users)
                        .map((item) => renderTableRow(item))}
                    </TableBody>
                  </Table>
                </TableContainer>

                <Box sx={{ mt: 6 }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      mb: 2,
                    }}
                  >
                    <Typography sx={{ fontSize: "14px" }}>
                      {t("memo")}
                    </Typography>
                    {(project.owner === user.id ||
                      user.account_type === "superadmin" ||
                      project.ProjectRoles?.some(
                        (role) =>
                          role.name === "Project Manager" &&
                          role.user === user.id
                      )) &&
                      !editCallSheet && (
                        <IconButton
                          onClick={() => setEditMemo(true)}
                          sx={{ color: "#9b9b9b", padding: "4px" }}
                        >
                          <EditIcon sx={{ fontSize: "16px" }} />
                        </IconButton>
                      )}
                    <Button
                      onClick={previewCallSheet}
                      sx={{
                        color: "#000000 !important",
                        backgroundColor: "#ffffff !important",
                        borderRadius: "14px",
                        boxShadow: "rgba(0, 0, 0, 0.35) 0px 0px 1px",
                        fontWeight: "300",
                        fontSize: "10px",
                      }}
                      variant="contained"
                    >
                      Preview Call Sheet
                    </Button>
                  </Box>
                  {!editMemo ? (
                    <Typography sx={{ fontSize: "12px" }}>
                      {memo || t("noMemoAddedYet")}
                    </Typography>
                  ) : (
                    <>
                      <TextField
                        multiline
                        rows={4}
                        fullWidth
                        placeholder={t("addMemo")}
                        value={memo}
                        onChange={handleMemo}
                      />
                      <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
                        <Button
                          variant="contained"
                          onClick={() => {
                            setEditMemo(false);
                            saveMemo();
                          }}
                          sx={{
                            textTransform: "none",
                            borderRadius: 1,
                            fontSize: "10px",
                          }}
                        >
                          {t("save")}
                        </Button>
                        <Button
                          variant="contained"
                          onClick={() => setEditMemo(false)}
                          sx={{
                            textTransform: "none",
                            borderRadius: 1,
                            fontSize: "10px",
                          }}
                        >
                          {t("cancel")}
                        </Button>
                      </Box>
                    </>
                  )}
                </Box>

                {editCallSheet && (
                  <Box sx={{ display: "flex", gap: 2, mt: 3 }}>
                    <Button
                      onClick={saveCallSheet}
                      variant="contained"
                      sx={{
                        textTransform: "none",
                        borderRadius: 1,
                      }}
                    >
                      {t("saveChanges")}
                    </Button>
                    <Button
                      onClick={() => setEditCallSheet(false)}
                      variant="contained"
                      sx={{
                        textTransform: "none",
                        borderRadius: 1,
                      }}
                    >
                      {t("cancel")}
                    </Button>
                  </Box>
                )}
              </Container>
            )}
            {activeTab === "chat" && (
              <Chat
                key={projectId}
                projectTeam={team.filter((member) => member.Users)}
                projectId={projectId}
                hideThreads={true}
              />
            )}
          </Box>
        </Box>
      ) : (
        <Stack
          className="custom-margins"
          spacing={4}
          sx={{
            paddingTop: "2rem",
            paddingBottom: "2rem",
          }}
        >
          <Skeleton variant="rounded" width={300} height={70} />
          <Skeleton variant="rounded" width={600} height={40} />

          <Skeleton variant="rounded" width={1280} height={300} />
        </Stack>
      )}
    </Box>
  );
};

export default ProjectManagement;
